/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AppInfoResponseDto */
export interface AppInfoResponseDto {
  appLicenseTerms: string
  instructionForUseAndroid: string
  instructionForUseIOs: string
  instructionForUseWeb: string
  legalNotices: string
  privacyPolicy: string
  privacyPolicyWeb: string
  providerIdentification: string
}

/**
 * AuthenticationDetailsResponseDto
 * Authentication
 */
export interface AuthenticationDetailsResponseDto {
  accessToken: string
  jwt: string
  /** User */
  user: UserResponseDto
}

/** BaseUserProfileDto */
export interface BaseUserProfileDto {
  /** @example true */
  clearanceCheck?: boolean
  /** @format int64 */
  clearanceCheckTimestamp?: number
  /** @format int64 */
  countryId?: number
  /** @format int64 */
  currencyId?: number
  hasMobileFirebaseConsentAccepted?: boolean
  hasSeenMobileFirebaseConsent?: boolean
  /** @example true */
  hideWithoutMarketClearance?: boolean
  /** @format int64 */
  hideWithoutMarketClearanceTimestamp?: number
  language: string
  languageShortcut: string
  /** @format int64 */
  mobileFirebaseConsentAcceptedLastChangedAtTimestamp?: number
  organizationRole?:
    | 'SALES_REPRESENTATIVE'
    | 'MARKETING'
    | 'SERVICE'
    | 'BACKOFFICE'
    | 'ACADEMY'
    | 'BU_HCA'
    | 'OTHER'
  /** @format int64 */
  organizationRoleTimestamp?: number
  /** @example true */
  phaseOutCheck?: boolean
  /** @format int64 */
  phaseOutCheckTimestamp?: number
  /** @example "DOT" */
  separator?: 'DOT' | 'COMMA'
  /** @format int64 */
  separatorTimestamp?: number
  sharedProductListNotification?: boolean
  /** @format date-time */
  sharedProductListNotificationLastChangedAt?: string
  /** @example true */
  showAvailability?: boolean
  /** @format int64 */
  showAvailabilityTimestamp?: number
  /** @example true */
  showPrices?: boolean
  /** @format int64 */
  showPricesTimestamp?: number
  updatedCountryShareNotification?: boolean
  /** @format date-time */
  updatedCountryShareNotificationLastChangedAt?: string
}

/**
 * BooleanRequestDto
 * Boolean object
 */
export interface BooleanRequestDto {
  value?: boolean
}

/** CareAreaCountersResponseDto */
export interface CareAreaCountersResponseDto {
  /** @format int64 */
  intensiveCare: number
  /** @format int64 */
  neonatalCare: number
  /** @format int64 */
  operatingRoom: number
}

/** CartDto */
export interface CartDto {
  clearanceCheck: boolean
  /** @format int64 */
  countryId: number
  /** @format int64 */
  currencyId: number
  email: string
  /** @format int32 */
  ggpPercents?: number
  globalAvailabilityCheck: boolean
  message: string
  phaseOutCheck: boolean
  products: ProductStatusDto[]
  subject: string
  /** @format int32 */
  totalGgp: number
}

/** ChangeOriginScpCountryRequestDto */
export interface ChangeOriginScpCountryRequestDto {
  /** @format int64 */
  countryId: number
}

/** CompanyResponseDto */
export interface CompanyResponseDto {
  /**
   * @format int64
   * @example 1
   */
  id: number
  /** @example "Rimpex" */
  name: string
}

/**
 * CopiedProductListResponseDto
 * Contains product list information
 */
export interface CopiedProductListResponseDto {
  /** @format int64 */
  createdAt: number
  currency?: string
  customSort?: boolean
  defaultList: boolean
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string
  /** @format int64 */
  descriptionTimestamp?: number
  elements?: DisplayProductListElementResponseDto[]
  /** @format int64 */
  id: number
  /** @format int64 */
  lastUpdatedAt: number
  /** @format int32 */
  numberOfProducts: number
  pinned: boolean
  /** @format int64 */
  pinnedTimestamp?: number
  sortParameter?: 'PRODUCT_GROUP' | 'PRODUCT_TITLE' | 'CREATED_AT' | 'CUSTOM'
  /** @format int64 */
  sortParameterTimestamp?: number
  /**
   * Unique for user
   * @minLength 1
   * @maxLength 200
   */
  title: string
  /** @format int64 */
  titleTimestamp?: number
}

/** CopyProductListDto */
export interface CopyProductListDto {
  description?: string
  title: string
}

/** CopyShareDto */
export interface CopyShareDto {
  description?: string
  title: string
}

/** CountriesListDto */
export interface CountriesListDto {
  items?: CountryWithSubregionDto[]
}

/** CountryDto */
export interface CountryDto {
  country?: string
  /** @format int64 */
  id?: number
  isAvailableForScp?: boolean
  title?: string
}

/** CountryProductCustomStatusRequestDto */
export interface CountryProductCustomStatusRequestDto {
  countries?: string[]
  /** @example "Lorem ipsum..." */
  description?: string
  /** @example "https://www.draeger.com/" */
  link?: string
  /** @example "AVAILABLE" */
  status: 'AVAILABLE' | 'WARNING' | 'NOT_AVAILABLE'
}

/** CountryScpAvailableRequestDto */
export interface CountryScpAvailableRequestDto {
  /** @format int64 */
  countryId: number
  isAvailableForScp: boolean
}

/** CountryShareDataDto */
export interface CountryShareDataDto {
  countries: number[]
  /**
   * @minLength 2
   * @maxLength 250
   */
  note?: string
}

/** CountryShareDetailsDto */
export interface CountryShareDetailsDto {
  countries: string[]
  /** @format int64 */
  createdAt: number
  currency: string
  description?: string
  drafted: boolean
  editAllowed: boolean
  elements?: DisplayProductListElementWithStatusResponseDto[]
  /** @format int64 */
  id: number
  /** @format int64 */
  lastUpdatedAt?: number
  neverRead?: boolean
  note?: string
  /** @format int32 */
  numberOfProducts: number
  ownerName: string
  pinned: boolean
  read?: boolean
  sortParameter?: 'PRODUCT_GROUP' | 'PRODUCT_TITLE' | 'CREATED_AT' | 'CUSTOM'
  title: string
}

/** CountryShareDto */
export interface CountryShareDto {
  countries: string[]
  /** @format int64 */
  createdAt: number
  currency: string
  description?: string
  drafted: boolean
  editAllowed: boolean
  /** @format int64 */
  id: number
  /** @format int64 */
  lastUpdatedAt?: number
  neverRead?: boolean
  note?: string
  /** @format int32 */
  numberOfProducts: number
  ownerName: string
  pinned: boolean
  read?: boolean
  sortParameter?: 'PRODUCT_GROUP' | 'PRODUCT_TITLE' | 'CREATED_AT' | 'CUSTOM'
  title: string
}

/** CountryShareElementsDetailsDto */
export interface CountryShareElementsDetailsDto {
  elements?: DisplayProductListElementWithStatusResponseDto[]
  sortParameter?: 'PRODUCT_GROUP' | 'PRODUCT_TITLE' | 'CREATED_AT' | 'CUSTOM'
}

/** CountryShareInfoDto */
export interface CountryShareInfoDto {
  countries: string[]
  drafted: boolean
  elements?: ListElementDto[]
  note: string
  /** @format int32 */
  pinsNumber: number
  /** @format int64 */
  shareId: number
  /** @format int64 */
  sharedTimestamp: number
}

/** CountryWithSubregionDto */
export interface CountryWithSubregionDto {
  country?: string
  hasPermission?: boolean
  /** @format int64 */
  id?: number
  isAvailableForScp?: boolean
  subregion?: string
  title?: string
}

/** CurrencyExchangeDto */
export interface CurrencyExchangeDto {
  currencyName?: string
  exchangeRate?: number
  /** @format int64 */
  id?: number
  targEx?: string
  title?: string
}

/** CurrencyExchangesListDto */
export interface CurrencyExchangesListDto {
  items?: CurrencyExchangeDto[]
}

/** DetailsDto */
export interface DetailsDto {
  language?: string
  referenceId?: string
  uxChapter?: string
  uxId?: string
  value?: string
}

/** DeviceDto */
export interface DeviceDto {
  anesthesia?: boolean
  description?: string
  deviceFilter?:
    | 'ANESTHESIA_APOLLO'
    | 'ANESTHESIA_ALL_ATLAN'
    | 'ANESTHESIA_ATLAN_A100'
    | 'ANESTHESIA_ATLAN_A100_XL'
    | 'ANESTHESIA_ATLAN_A350'
    | 'ANESTHESIA_ATLAN_A350_XL'
    | 'ANESTHESIA_ATLAN_A300_XL'
    | 'ANESTHESIA_ATLAN_A300'
    | 'ANESTHESIA_ALL_FABIUS'
    | 'ANESTHESIA_FABIUS_GS'
    | 'ANESTHESIA_FABIUS_GS_PREMIUM'
    | 'ANESTHESIA_FABIUS_TIRO'
    | 'ANESTHESIA_FABIUS_MRI'
    | 'ANESTHESIA_FABIUS_PLUS_XL'
    | 'ANESTHESIA_FABIUS_PLUS'
    | 'ANESTHESIA_PERSEUS_A500'
    | 'ANESTHESIA_ALL_PRIMUS'
    | 'ANESTHESIA_PRIMUS_IE'
    | 'ANESTHESIA_PRIMUS'
    | 'ANESTHESIA_ALL_ZEUS'
    | 'ANESTHESIA_ZEUS_IE'
    | 'ANESTHESIA_ZEUS'
    | 'VENTILATION_ALL_BABYLOG'
    | 'VENTILATION_BABYLOG_8000'
    | 'VENTILATION_BABYLOG_VN800'
    | 'VENTILATION_BABYLOG_VN600'
    | 'VENTILATION_BABYLOG_VN500'
    | 'VENTILATION_ALL_EVITA'
    | 'VENTILATION_EVITA_V500'
    | 'VENTILATION_EVITA_V300'
    | 'VENTILATION_EVITA_V800'
    | 'VENTILATION_EVITA_2_DURA'
    | 'VENTILATION_EVITA_4'
    | 'VENTILATION_EVITA_XL'
    | 'VENTILATION_EVITA_V600'
    | 'VENTILATION_ALL_OXYLOG'
    | 'VENTILATION_OXYLOG_VE_300'
    | 'VENTILATION_OXYLOG_1000'
    | 'VENTILATION_OXYLOG_2000_PLUS'
    | 'VENTILATION_OXYLOG_3000'
    | 'VENTILATION_OXYLOG_3000_PLUS'
    | 'VENTILATION_PULMOVISTA_500'
    | 'VENTILATION_ALL_SAVINA'
    | 'VENTILATION_SAVINA_300'
    | 'VENTILATION_SAVINA_300_SELECT'
    | 'VENTILATION_SAVINA_300_CLASSIC'
    | 'VENTILATION_SAVINA_300_COLOR'
    | 'VENTILATION_SAVINA_300_NIV'
    | 'VENTILATION_CARINA'
    | 'VENTILATION_AQUAPOR'
    | 'THERMOREGULATION_BABYLEO'
    | 'THERMOREGULATION_CALEO'
    | 'THERMOREGULATION_RESUSCITAIRE'
    | 'THERMOREGULATION_ALL_BABYTHERM'
    | 'THERMOREGULATION_BABYTHERM_8004'
    | 'THERMOREGULATION_BABYTHERM_8010'
    | 'THERMOREGULATION_ALL_ISOLETTE'
    | 'THERMOREGULATION_ISOLETTE_8000_PLUS'
    | 'THERMOREGULATION_ISOLETTE_8000'
    | 'THERMOREGULATION_ISOLETTE_C2000'
    | 'THERMOREGULATION_ISOLETTE_TI500'
    | 'THERMOREGULATION_ALL_GLOBE_TROTTER'
    | 'THERMOREGULATION_GLOBE_TROTTER_IC'
    | 'THERMOREGULATION_GLOBE_TROTTER_GT5400'
    | 'THERMOREGULATION_BABYROO_TN300'
    | 'MONITORING_ALL_INFINITY'
    | 'MONITORING_INFINITY_ACUTE_CARE_M540'
    | 'MONITORING_INFINITY_ACUTE_CARE_ACUTE_CARE_SYSTEM'
    | 'MONITORING_INFINITY_DELTA_FAMILY_DELTA'
    | 'MONITORING_INFINITY_DELTA_FAMILY_KAPPA_XLT'
    | 'MONITORING_INFINITY_DELTA_FAMILY_GAMMA_XXL'
    | 'MONITORING_INFINITY_DELTA_FAMILY_GAMMA_XL'
    | 'MONITORING_INFINITY_DELTA_FAMILY_VISTA_XL'
    | 'MONITORING_INFINITY_DELTA_FAMILY_VISTA'
    | 'MONITORING_M300_M300_PLUS'
    | 'MONITORING_ALL_VISTA'
    | 'MONITORING_VISTA_120SC_MODEL_A'
    | 'MONITORING_VISTA_120SC_MODEL_B'
    | 'MONITORING_VISTA_120SC_MODEL_C'
    | 'MONITORING_VISTA_120SC_MODEL_D'
    | 'MONITORING_VISTA_120SC_MODEL_E'
    | 'MONITORING_VISTA_300_MODEL_A'
    | 'MONITORING_VISTA_300_MODEL_C'
    | 'MONITORING_VISTA_120_A'
    | 'MONITORING_VISTA_120_A_PLUS'
    | 'MONITORING_VISTA_120_C'
    | 'MONITORING_VISTA_120_C_PLUS'
    | 'MONITORING_VISTA_120_S_A'
    | 'MONITORING_VISTA_120_S_A_PLUS'
    | 'MONITORING_VISTA_120_S_C'
    | 'MONITORING_VISTA_120_S_C_PLUS'
    | 'MONITORING_TOFSCAN'
    | 'MONITORING_ALL_POLARIS'
    | 'OTHER_POLARIS_100'
    | 'OTHER_POLARIS_200'
    | 'OTHER_POLARIS_100_200'
    | 'OTHER_POLARIS_600'
    | 'OTHER_POLARIS_MULTIMEDIA'
  imageId?: string
  monitoring?: boolean
  others?: boolean
  thermoregulation?: boolean
  title?: string
  ventilation?: boolean
}

/**
 * DisplayProductListElementResponseDto
 * Contains product list element display information
 */
export interface DisplayProductListElementResponseDto {
  /** @format int32 */
  availability?: number
  /** @format int64 */
  createdAt: number
  /** @format int32 */
  criticalQuantity?: number
  /** Price added by user */
  crp?: number
  customStatus?: ProductCustomStatusResponseDto
  ggpUnitPrice?: number
  globalPrice?: number
  /** @format int64 */
  id: number
  imageId?: string
  /** @format int64 */
  lastUpdatedAtTimestamp?: number
  /** @format int64 */
  listId: number
  localPrice?: boolean
  localPriceList?: number
  lowerLimit?: number
  mainTitle: string
  /** @format int32 */
  marketClearance?: number
  /** @format int32 */
  packagePerDelivery?: number
  /** @format int32 */
  phaseOut?: number
  /** @format int32 */
  piecePerPackage?: number
  /** @format int32 */
  productGroup?: number
  /** @format int64 */
  productId: number
  /** @format int32 */
  quantity?: number
  /** @format int32 */
  sortOrder?: number
  subtitle?: string
  totalPrice?: number
  unitPrice?: number
  /** @format int32 */
  unitsPerPackage?: number
  upperLimit?: number
}

/**
 * DisplayProductListElementWithStatusResponseDto
 * Contains product list element display information
 */
export interface DisplayProductListElementWithStatusResponseDto {
  /** @format int32 */
  availability?: number
  /** @format int64 */
  createdAt: number
  /** @format int32 */
  criticalQuantity?: number
  /** Price added by user */
  crp?: number
  customStatus?: ProductCustomStatusResponseDto
  ggpUnitPrice?: number
  globalPrice?: number
  /** @format int64 */
  id: number
  imageId?: string
  /** @format int64 */
  lastUpdatedAtTimestamp?: number
  /** @format int64 */
  listId: number
  localPrice?: boolean
  localPriceList?: number
  lowerLimit?: number
  mainTitle: string
  /** @format int32 */
  marketClearance?: number
  /** @format int32 */
  packagePerDelivery?: number
  /** @format int32 */
  phaseOut?: number
  /** @format int32 */
  piecePerPackage?: number
  /** @format int32 */
  productGroup?: number
  /** @format int64 */
  productId: number
  /** @format int32 */
  quantity?: number
  read?: boolean
  /** @format int32 */
  sortOrder?: number
  subtitle?: string
  totalPrice?: number
  unitPrice?: number
  /** @format int32 */
  unitsPerPackage?: number
  upperLimit?: number
}

/**
 * DisplayUserProfileResponseDto
 * Contains user profile information
 */
export interface DisplayUserProfileResponseDto {
  /** @example true */
  clearanceCheck?: boolean
  /** @format int64 */
  clearanceCheckTimestamp?: number
  country?: string
  /** @format int64 */
  countryId?: number
  countryShortcut?: string
  currency?: string
  /** @format int64 */
  currencyId?: number
  currencyShortcut?: string
  hasMobileFirebaseConsentAccepted?: boolean
  hasSeenMobileFirebaseConsent?: boolean
  /** @example true */
  hideWithoutMarketClearance?: boolean
  /** @format int64 */
  hideWithoutMarketClearanceTimestamp?: number
  /** @format int64 */
  id: number
  language: string
  languageShortcut: string
  /** @format int64 */
  mobileFirebaseConsentAcceptedLastChangedAtTimestamp?: number
  organizationRole?:
    | 'SALES_REPRESENTATIVE'
    | 'MARKETING'
    | 'SERVICE'
    | 'BACKOFFICE'
    | 'ACADEMY'
    | 'BU_HCA'
    | 'OTHER'
  /** @format int64 */
  organizationRoleTimestamp?: number
  /** @example true */
  phaseOutCheck?: boolean
  /** @format int64 */
  phaseOutCheckTimestamp?: number
  role:
    | 'FIREBASE'
    | 'ADMIN'
    | 'ADVANCED_USER'
    | 'ADVANCED_USER_PLUS'
    | 'BASIC_USER'
    | 'BASIC_USER_PLUS'
    | 'KEY_USER'
    | 'SALES_CHANNEL_PARTNER'
  roles?: UserRoleResponseDto[]
  scpCompany?: CompanyResponseDto
  scpStatus?: 'ACCEPTED' | 'REJECTED' | 'PENDING' | 'ONBOARDING_REQUIRED'
  /** @example "DOT" */
  separator?: 'DOT' | 'COMMA'
  /** @format int64 */
  separatorTimestamp?: number
  sharedProductListNotification?: boolean
  /** @format date-time */
  sharedProductListNotificationLastChangedAt?: string
  /** @example true */
  showAvailability?: boolean
  /** @format int64 */
  showAvailabilityTimestamp?: number
  /** @example true */
  showPrices?: boolean
  /** @format int64 */
  showPricesTimestamp?: number
  updatedCountryShareNotification?: boolean
  /** @format date-time */
  updatedCountryShareNotificationLastChangedAt?: string
  userType?: 'SCP' | 'INTERNAL' | 'HCA'
  username: string
}

/** DynamicFilterDto */
export interface DynamicFilterDto {
  /** @format int64 */
  id: number
  usage?: string
}

/** EditLanguageRequestDto */
export interface EditLanguageRequestDto {
  languageShortcut: string
}

/** EditScpUserProfileRequestDto */
export interface EditScpUserProfileRequestDto {
  /** @format int64 */
  countryId: number
  hasMobileFirebaseConsentAccepted?: boolean
  languageShortcut: string
  sharedProductListNotification?: boolean
}

/** EditUserRequestDto */
export interface EditUserRequestDto {
  roles?: UserRoleResponseDto[]
}

/** EmailDataDto */
export interface EmailDataDto {
  /** Separated by ';' */
  emails: string
  message: string
  subject: string
}

/**
 * ErrorMsgDto
 * Error message
 */
export interface ErrorMsgDto {
  /** Message key */
  message: string
  /** Message parameters */
  parameters?: object[]
}

/** ExternalUserDto */
export interface ExternalUserDto {
  company?: CompanyResponseDto
  country: string
  /** @format int64 */
  createdAt: number
  email: string
  /** @format int64 */
  id: number
  language: string
  /** @format int64 */
  lastSeenAt?: number
  organizationRole?:
    | 'SALES_REPRESENTATIVE'
    | 'MARKETING'
    | 'SERVICE'
    | 'BACKOFFICE'
    | 'ACADEMY'
    | 'BU_HCA'
    | 'OTHER'
  scpStatus: 'ACCEPTED' | 'REJECTED' | 'PENDING' | 'ONBOARDING_REQUIRED'
  subregion: string
  username: string
}

/** FavoriteOccurrencesDto */
export interface FavoriteOccurrencesDto {
  favoritesCounter?: Record<string, number>
}

/** FavoriteProductCreatedRequestDto */
export interface FavoriteProductCreatedRequestDto {
  /** @format int64 */
  createdAt: number
  /** @format int64 */
  productId: number
}

/** FavoriteProductDeletedRequestDto */
export interface FavoriteProductDeletedRequestDto {
  /** @format int64 */
  deletedAt: number
  /** @format int64 */
  productId: number
}

/** FavoriteProductResponseDto */
export interface FavoriteProductResponseDto {
  /** @format int32 */
  availability: number
  /** @format int64 */
  createdAt: number
  /** @format int32 */
  criticalQuantity?: number
  customStatus?: ProductCustomStatusResponseDto
  favorite: boolean
  highlightText?: string
  imageId?: string
  isNew: boolean
  longText?: string
  /** @format int32 */
  marketClearance: number
  /** @format int32 */
  packagePerDelivery?: number
  /** @format int32 */
  phaseOut: number
  /** @format int32 */
  piecePerPackage?: number
  price?: number
  /** @format int64 */
  productId: number
  subtitle?: string
  title: string
  /** @format int32 */
  unitsPerPackage?: number
}

/** FavoriteProductsRequestDto */
export interface FavoriteProductsRequestDto {
  created?: FavoriteProductCreatedRequestDto[]
  deleted?: FavoriteProductDeletedRequestDto[]
}

/**
 * FcmRegistrationIdDto
 * New FCM registration ID
 */
export interface FcmRegistrationIdDto {
  fcmRegistrationId: string
}

/** FilterMatchesDto */
export interface FilterMatchesDto {
  careArea?: Record<string, boolean>
  clearance?: Record<string, boolean>
  device?: Record<string, boolean>
  other?: Record<string, boolean>
  patient?: Record<string, boolean>
  product?: Record<string, boolean>
  usage?: Record<string, boolean>
}

/** ImageDto */
export interface ImageDto {
  imageCategory?: string
  imageId?: string
  internal?: boolean
  /** @format int32 */
  sortOrderNumber?: number
}

/**
 * IntegerDto
 * Integer object
 */
export interface IntegerDto {
  /** @format int32 */
  value: number
}

/** LanguageResponseDto */
export interface LanguageResponseDto {
  name: string
  shortcut: string
}

/** LinkDto */
export interface LinkDto {
  documentCluster?:
    | 'ORDERING'
    | 'MARKETING'
    | 'DOCUMENTATION'
    | 'SERVICE'
    | 'CERTIFICATES'
    | 'TRAINING'
    | 'OTHERS'
    | 'NEWS'
  documentFormat?: 'XLS' | 'IMAGE' | 'AUDIO' | 'PDF' | 'PPT' | 'DOC' | 'OTHER'
  documentType?: string
  internal?: boolean
  languageShortcut?: string
  /** @format date */
  lastChange?: string
  master?: boolean
  title?: string
  url?: string
}

/** ListElementDto */
export interface ListElementDto {
  /** @format date-time */
  createdAt?: string
  price?: number
  /** @format int32 */
  quantity?: number
  title: string
}

/** ListElementsDetailsDto */
export interface ListElementsDetailsDto {
  customSort: boolean
  elements?: DisplayProductListElementResponseDto[]
  sortParameter?: 'PRODUCT_GROUP' | 'PRODUCT_TITLE' | 'CREATED_AT' | 'CUSTOM'
}

/** ListSortParameterRequestDto */
export interface ListSortParameterRequestDto {
  /** @example "PRODUCT_TITLE" */
  sortParameter: 'PRODUCT_GROUP' | 'PRODUCT_TITLE' | 'CREATED_AT' | 'CUSTOM'
}

/**
 * LoginRequestDto
 * Login data
 */
export interface LoginRequestDto {
  code: string
  /**
   * Defines if browser has cookies enabled
   * @example true
   */
  hasCookiesEnabled?: boolean
  jwtToken?: string
  /** false */
  mobile?: boolean
  /**
   * Must match with okta configuration
   * @example "http://localhost:8080/"
   */
  redirectUrl?: string
  registrationId?: string
}

/**
 * LongDto
 * Long object
 */
export interface LongDto {
  /** @format int64 */
  value?: number
}

/** MoveDto */
export interface MoveDto {
  crpBehavior: 'OVERRIDE' | 'IGNORE'
  ids: number[]
  quantityBehavior: 'ADD' | 'OVERRIDE' | 'IGNORE'
}

/** MultipleProductsAdditionRequestDto */
export interface MultipleProductsAdditionRequestDto {
  elements: ProductListElementRequestDto[]
  productListIds?: number[]
  shareDraftIds?: number[]
}

/** MultipleProductsAdditionResponseDto */
export interface MultipleProductsAdditionResponseDto {
  areProductDuplicatesOnProductList: boolean
  areProductDuplicatesOnShareDraft: boolean
}

/** NotificationDto */
export interface NotificationDto {
  /** @format int64 */
  createdAt: number
  /** @format int64 */
  expiresAt?: number
  /** @format int64 */
  id: number
  /** @format int64 */
  listId?: number
  listOwnerName?: string
  listSortParameter?:
    | 'PRODUCT_GROUP'
    | 'PRODUCT_TITLE'
    | 'CREATED_AT'
    | 'CUSTOM'
  listTitle?: string
  read: boolean
  type:
    | 'SYNCHRONIZATION_REQUIRED'
    | 'PRIVATE_LIST_SHARED'
    | 'PUBLIC_LIST_UPDATED'
    | 'LANGUAGE_UPDATED'
}

/** OfflineProductsListDto */
export interface OfflineProductsListDto {
  products?: ProductsPropertiesDto[]
  version?: string
}

/** OrganizationRoleListResponseDto */
export interface OrganizationRoleListResponseDto {
  organizationRoles: (
    | 'SALES_REPRESENTATIVE'
    | 'MARKETING'
    | 'SERVICE'
    | 'BACKOFFICE'
    | 'ACADEMY'
    | 'BU_HCA'
    | 'OTHER'
  )[]
}

/**
 * PinnedListResponseDto
 * Contains product list information
 */
export interface PinnedListResponseDto {
  countries?: string[]
  country?: string
  /** @format int64 */
  createdAt: number
  currency?: string
  customSort?: boolean
  defaultList: boolean
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string
  /** @format int64 */
  descriptionTimestamp?: number
  editAllowed?: boolean
  /** @format int64 */
  id: number
  /** @format int64 */
  lastUpdatedAt: number
  matchingCountry?: boolean
  neverRead?: boolean
  note?: string
  /** @format int32 */
  numberOfProducts: number
  ownerName?: string
  pinned: boolean
  /** @format int64 */
  pinnedTimestamp?: number
  read?: boolean
  shared: boolean
  sortParameter?: 'PRODUCT_GROUP' | 'PRODUCT_TITLE' | 'CREATED_AT' | 'CUSTOM'
  /**
   * Unique for user
   * @minLength 1
   * @maxLength 200
   */
  title: string
  /** @format int64 */
  titleTimestamp?: number
}

/** PrivateShareCreatedRequestDto */
export interface PrivateShareCreatedRequestDto {
  email: string
  /** @format int64 */
  listId: number
  note?: string
  /** @format int64 */
  shareTimestamp: number
}

/** PrivateShareDetailsDto */
export interface PrivateShareDetailsDto {
  /** @format int64 */
  createdAt: number
  currency: string
  description?: string
  elements?: DisplayProductListElementResponseDto[]
  /** @format int64 */
  id: number
  note?: string
  ownerName: string
  sortParameter?: 'PRODUCT_GROUP' | 'PRODUCT_TITLE' | 'CREATED_AT' | 'CUSTOM'
  title: string
}

/** PrivateShareElementsDetailsDto */
export interface PrivateShareElementsDetailsDto {
  elements?: DisplayProductListElementResponseDto[]
  sortParameter?: 'PRODUCT_GROUP' | 'PRODUCT_TITLE' | 'CREATED_AT' | 'CUSTOM'
}

/** PrivateShareInfoDto */
export interface PrivateShareInfoDto {
  copiedAsMine?: boolean
  /** @format int64 */
  recipientId: number
  scpRecipient: boolean
  /** @format int64 */
  shareId: number
  /** @format int64 */
  sharedTimestamp: number
}

/** ProductAvailabilityIndicatorDetailsResponseDto */
export interface ProductAvailabilityIndicatorDetailsResponseDto {
  /** @format int32 */
  availability?: number
  /**
   * @format int32
   * @example 100
   */
  criticalQuantity?: number
  customStatus?: ProductCustomStatusResponseDto
  customStatuses: ProductCustomStatusResponseDto[]
  /** @format int64 */
  id: number
  imageId?: string
  /** @format int32 */
  marketClearance?: number
  /** @format int32 */
  phaseOut?: number
  /** @example "AVAILABLE" */
  phaseOutStatus:
    | 'AVAILABLE'
    | 'UPCOMING_PHASE_OUT'
    | 'PHASED_OUT'
    | 'BLOCKED'
    | 'NOT_AVAILABLE'
  /** @example "AVAILABLE" */
  phaseOutTrafficLight: 'AVAILABLE' | 'WARNING' | 'NOT_AVAILABLE'
  /** @example "AVAILABLE" */
  showAvailabilityStatus:
    | 'SHIP_HOLD'
    | 'OUT_OF_STOCK'
    | 'LIMITED_QUANTITY'
    | 'AVAILABLE'
  /** @example "AVAILABLE" */
  showAvailabilityTrafficLight: 'AVAILABLE' | 'WARNING' | 'NOT_AVAILABLE'
  subtitle?: string
  title?: string
}

/** ProductAvailabilityIndicatorDictRowResponseDto */
export interface ProductAvailabilityIndicatorDictRowResponseDto {
  /** @format int32 */
  availability?: number
  /** @format int32 */
  criticalQuantity?: number
  customStatus?: ProductCustomStatusResponseDto
  /** @format int64 */
  id: number
  imageId?: string
  isOnProductAvailabilityIndicatorList: boolean
  /** @format int32 */
  marketClearance?: number
  /** @format int32 */
  phaseOut?: number
  subtitle?: string
  title?: string
}

/** ProductAvailabilityIndicatorRowResponseDto */
export interface ProductAvailabilityIndicatorRowResponseDto {
  /** @format int32 */
  availability?: number
  /**
   * @format int32
   * @example 100
   */
  criticalQuantity?: number
  customStatus?: ProductCustomStatusResponseDto
  /**
   * It should be shown if there is only one custom status set
   * @example "AVAILABLE"
   */
  customStatusTrafficLight?: 'AVAILABLE' | 'WARNING' | 'NOT_AVAILABLE'
  customStatuses: ProductCustomStatusResponseDto[]
  /**
   * @format int32
   * @example 3
   */
  customStatusesNumber: number
  /** @format int64 */
  id: number
  imageId?: string
  /** @example "AVAILABLE" */
  indicator:
    | 'CUSTOM'
    | 'PHASED_OUT'
    | 'NOT_AVAILABLE'
    | 'UPCOMING_PHASE_OUT'
    | 'LIMITED_QUANTITY'
    | 'AVAILABLE'
  /** @example "AVAILABLE" */
  indicatorTrafficLight: 'AVAILABLE' | 'WARNING' | 'NOT_AVAILABLE'
  /** @format int32 */
  marketClearance?: number
  /** @format int32 */
  phaseOut?: number
  /** @example "AVAILABLE" */
  phaseOutStatus:
    | 'AVAILABLE'
    | 'UPCOMING_PHASE_OUT'
    | 'PHASED_OUT'
    | 'BLOCKED'
    | 'NOT_AVAILABLE'
  /** @example "AVAILABLE" */
  phaseOutTrafficLight: 'AVAILABLE' | 'WARNING' | 'NOT_AVAILABLE'
  /** @example "AVAILABLE" */
  showAvailabilityStatus:
    | 'SHIP_HOLD'
    | 'OUT_OF_STOCK'
    | 'LIMITED_QUANTITY'
    | 'AVAILABLE'
  /** @example "AVAILABLE" */
  showAvailabilityTrafficLight: 'AVAILABLE' | 'WARNING' | 'NOT_AVAILABLE'
  subtitle?: string
  title?: string
}

/** ProductAvailabilityIndicatorUpdateRequestDto */
export interface ProductAvailabilityIndicatorUpdateRequestDto {
  countryProductAvailabilityIndicators?: CountryProductCustomStatusRequestDto[]
  globalProductAvailabilityIndicator?: ProductCustomStatusRequestDto
  /** @example "EDIT_MODE" */
  mode: 'EDIT_MODE' | 'GLOBAL_INDICATOR_LIVE' | 'COUNTRY_INDICATOR_LIVE'
}

/** ProductAverageOnListDto */
export interface ProductAverageOnListDto {
  /** @format double */
  globalAverageOfProductsOnList?: number
  globalAverageOfProductsOnListPerCountry?: Record<string, number>
}

/** ProductClusterCountersResponseDto */
export interface ProductClusterCountersResponseDto {
  /** @format int64 */
  monitoringAccessories: number
  /** @format int64 */
  productNeonatalCare: number
  /** @format int64 */
  railAccessories: number
  /** @format int64 */
  sensors: number
  /** @format int64 */
  sodaLime: number
  /** @format int64 */
  ventilationAccessories: number
  /** @format int64 */
  wardEquipment: number
}

/** ProductCountersResponseDto */
export interface ProductCountersResponseDto {
  careAreaCounters: CareAreaCountersResponseDto
  productClusterCounters: ProductClusterCountersResponseDto
}

/** ProductCustomStatusRequestDto */
export interface ProductCustomStatusRequestDto {
  /** @example "Lorem ipsum..." */
  description?: string
  /** @example "https://www.draeger.com/" */
  link?: string
  /** @example "AVAILABLE" */
  status: 'AVAILABLE' | 'WARNING' | 'NOT_AVAILABLE'
}

/** ProductCustomStatusResponseDto */
export interface ProductCustomStatusResponseDto {
  countries?: string[]
  /** @example "Lorem ipsum..." */
  description?: string
  isEditMode?: boolean
  /** @example "https://www.draeger.com/" */
  link?: string
  /** @example "AVAILABLE" */
  status: 'AVAILABLE' | 'WARNING' | 'NOT_AVAILABLE'
}

/** ProductListAmountDto */
export interface ProductListAmountDto {
  /** @format int64 */
  globalNumberOfProductLists?: number
  numberOfProductListsPerCountry?: Record<string, number>
}

/** ProductListElementOfflineDeletedRequestDto */
export interface ProductListElementOfflineDeletedRequestDto {
  /** @format int64 */
  deletedAt: number
  /** @format int64 */
  id: number
}

/**
 * ProductListElementOfflineModifiedRequestDto
 * Contains product list element attributes
 */
export interface ProductListElementOfflineModifiedRequestDto {
  /** @format int64 */
  createdAt: number
  /**
   * Required when user is not BASIC_USER
   * @max 999999999999.99
   * @exclusiveMax false
   */
  crp?: number
  /** @format int64 */
  id: number
  /** @format int64 */
  productId: number
  /**
   * Required when user is not BASIC_USER
   * @format int32
   * @min 1
   * @exclusiveMin false
   * @max 999999
   * @exclusiveMax false
   */
  quantity: number
  /** @format int32 */
  sortOrder?: number
  /** @format int64 */
  updatedAt: number
}

/**
 * ProductListElementRequestDto
 * Contains product list element attributes
 */
export interface ProductListElementRequestDto {
  /**
   * Required when user is not BASIC_USER
   * @max 999999999999.99
   * @exclusiveMax false
   */
  crp?: number
  /** @format int64 */
  productId: number
  /**
   * Required when user is not BASIC_USER
   * @format int32
   * @min 1
   * @exclusiveMin false
   * @max 999999
   * @exclusiveMax false
   */
  quantity: number
}

/** ProductListOfflineDeletedRequestDto */
export interface ProductListOfflineDeletedRequestDto {
  /** @format int64 */
  deletedAt: number
  /** @format int64 */
  id: number
}

/** ProductListOfflineModifiedRequestDto */
export interface ProductListOfflineModifiedRequestDto {
  /** @format int64 */
  createdAt: number
  currency?: string
  deleted?: ProductListElementOfflineDeletedRequestDto[]
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string
  /** @format int64 */
  descriptionTimestamp: number
  /** @format int64 */
  id: number
  modified?: ProductListElementOfflineModifiedRequestDto[]
  pinned: boolean
  /** @format int64 */
  pinnedTimestamp: number
  sortParameter?: 'PRODUCT_GROUP' | 'PRODUCT_TITLE' | 'CREATED_AT' | 'CUSTOM'
  /** @format int64 */
  sortParameterTimestamp: number
  /**
   * Unique for user
   * @minLength 1
   * @maxLength 200
   */
  title: string
  /** @format int64 */
  titleTimestamp: number
}

/** ProductListOverallStatisticsDto */
export interface ProductListOverallStatisticsDto {
  countryShareInfo?: CountryShareInfoDto[]
  /** @format date-time */
  creationDate: string
  currency: string
  description: string
  elements?: ListElementDto[]
  /** @format int64 */
  id: number
  /** @format date-time */
  lastUpdateDate?: string
  /** @format int64 */
  ownerId: number
  pinned: boolean
  privateSendInfo?: PrivateShareInfoDto[]
  sorting?: 'PRODUCT_GROUP' | 'PRODUCT_TITLE' | 'CREATED_AT' | 'CUSTOM'
  title: string
}

/** ProductListRequestDto */
export interface ProductListRequestDto {
  currency?: string
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string
  /** @format int64 */
  descriptionTimestamp?: number
  /** @format int64 */
  pinnedTimestamp?: number
  /**
   * Unique for user
   * @minLength 1
   * @maxLength 200
   */
  title: string
  /** @format int64 */
  titleTimestamp?: number
}

/**
 * ProductListRowResponseDto
 * Contains product list information
 */
export interface ProductListRowResponseDto {
  /** @format int64 */
  createdAt: number
  currency?: string
  customSort?: boolean
  defaultList: boolean
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string
  /** @format int64 */
  descriptionTimestamp?: number
  /** @format int64 */
  id: number
  /** @format int64 */
  lastUpdatedAt: number
  /** @format int32 */
  numberOfProducts: number
  pinned: boolean
  /** @format int64 */
  pinnedTimestamp?: number
  sortParameter?: 'PRODUCT_GROUP' | 'PRODUCT_TITLE' | 'CREATED_AT' | 'CUSTOM'
  /**
   * Unique for user
   * @minLength 1
   * @maxLength 200
   */
  title: string
  /** @format int64 */
  titleTimestamp?: number
}

/**
 * ProductListRowWithElementsResponseDto
 * Contains product list information
 */
export interface ProductListRowWithElementsResponseDto {
  /** @format int64 */
  createdAt: number
  currency?: string
  customSort: boolean
  defaultList: boolean
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string
  /** @format int64 */
  descriptionTimestamp?: number
  elements?: DisplayProductListElementResponseDto[]
  /** @format int64 */
  id: number
  /** @format int64 */
  lastUpdatedAt: number
  /** @format int32 */
  numberOfProducts: number
  pinned: boolean
  /** @format int64 */
  pinnedTimestamp?: number
  sortParameter?: 'PRODUCT_GROUP' | 'PRODUCT_TITLE' | 'CREATED_AT' | 'CUSTOM'
  /**
   * Unique for user
   * @minLength 1
   * @maxLength 200
   */
  title: string
  /** @format int64 */
  titleTimestamp?: number
}

/**
 * ProductListWithElementsResponseDto
 * Contains product list information
 */
export interface ProductListWithElementsResponseDto {
  /** @format int64 */
  createdAt: number
  currency?: string
  customSort?: boolean
  defaultList: boolean
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string
  /** @format int64 */
  descriptionTimestamp?: number
  elements?: DisplayProductListElementResponseDto[]
  /** @format int64 */
  id: number
  /** @format int64 */
  lastUpdatedAt: number
  /** @format int32 */
  numberOfProducts: number
  pinned: boolean
  /** @format int64 */
  pinnedTimestamp?: number
  sortParameter?: 'PRODUCT_GROUP' | 'PRODUCT_TITLE' | 'CREATED_AT' | 'CUSTOM'
  /** @format int64 */
  sortParameterTimestamp?: number
  /**
   * Unique for user
   * @minLength 1
   * @maxLength 200
   */
  title: string
  /** @format int64 */
  titleTimestamp?: number
}

/** ProductListsOfflineRequestDto */
export interface ProductListsOfflineRequestDto {
  deleted?: ProductListOfflineDeletedRequestDto[]
  modified?: ProductListOfflineModifiedRequestDto[]
}

/** ProductOccurrencesDto */
export interface ProductOccurrencesDto {
  productsOnListsCounter?: Record<string, number>
}

/** ProductPriceAverageDto */
export interface ProductPriceAverageDto {
  prices?: Record<string, number>
}

/** ProductQuantityAmountDto */
export interface ProductQuantityAmountDto {
  countriesStatistics?: Record<string, number>
  /** @format int32 */
  globalQuantity?: number
  title?: string
}

/** ProductQuantityAverageDto */
export interface ProductQuantityAverageDto {
  averages?: Record<string, number>
}

/** ProductResponseDto */
export interface ProductResponseDto {
  /** @format int32 */
  availability?: number
  /** @format int32 */
  criticalQuantity?: number
  customStatus?: ProductCustomStatusResponseDto
  /** @format int64 */
  id: number
  imageId?: string
  /** @format int32 */
  marketClearance?: number
  /** @format int32 */
  phaseOut?: number
  subtitle?: string
  title?: string
}

/** ProductRowResponseDto */
export interface ProductRowResponseDto {
  /** @format int32 */
  availability?: number
  /** @format int32 */
  criticalQuantity?: number
  customStatus?: ProductCustomStatusResponseDto
  /** @format int32 */
  defaultSorting?: number
  disclaimer?: string
  favorite: boolean
  highlightText?: string
  imageId?: string
  isNew?: boolean
  localPrice?: number
  longText?: string
  /** @format int32 */
  marketClearance?: number
  /** @format int32 */
  packagePerDelivery?: number
  /** @format int32 */
  phaseOut?: number
  /** @format int32 */
  piecePerPackage?: number
  price?: number
  /** @format int64 */
  productId: number
  subtitle?: string
  title: string
  /** @format int32 */
  unitsPerPackage?: number
}

/** ProductStatusDto */
export interface ProductStatusDto {
  /** @format int32 */
  availability?: number
  /** @format int32 */
  criticalQuantityStatus?: number
  /** @format double */
  crpPerPiece: number
  description: string
  /** @format int32 */
  ggp: number
  /** @format int32 */
  ggpPercents?: number
  /** @format int32 */
  marketClearance?: number
  material: string
  notification?: string
  /** @format int32 */
  phaseOut?: number
  /** @format int64 */
  productId: number
  /** @format int32 */
  qty: number
}

/** ProductsPropertiesDetailsDto */
export interface ProductsPropertiesDetailsDto {
  accessoryForGasSupply?: boolean
  adult?: boolean
  aerosolTherapy?: boolean
  alternativeProducts?: number[]
  /** @format int32 */
  availability: number
  bispectralIndex?: boolean
  breathingCircuits?: boolean
  breathingSystemFiltersAndHMEs?: boolean
  capnography?: boolean
  cardiacOutput?: boolean
  catheterMountsAndAirwayConnectors?: boolean
  co2Cuvettes?: boolean
  continuousNonInvasiveArterialPressure?: boolean
  /** @format int32 */
  criticalQuantity?: number
  customStatus?: ProductCustomStatusResponseDto
  dedicated?: boolean
  /** @format int32 */
  defaultSorting?: number
  details?: DetailsDto[]
  deviceRelatedAccessories?: boolean
  devices?: DeviceDto[]
  disclaimer?: string
  electrocardiography?: boolean
  electroencephalography?: boolean
  examinationLight?: boolean
  favorite: boolean
  ggpUnitPrice?: number
  globalPrice?: number
  highlightText?: string
  hingedArms?: boolean
  humidifier?: boolean
  humidifierAndNebulizer?: boolean
  icu?: boolean
  /** @format int64 */
  id: number
  image360?: boolean
  imageCategory?: string
  imageId?: string
  images?: ImageDto[]
  infinityAcuteCareSystem?: boolean
  infinityM300?: boolean
  infinityMonitoring?: boolean
  intubationKits?: boolean
  invasiveBloodPressure?: boolean
  isNew?: boolean
  keywords?: string[]
  labeledAsContainingLatex?: boolean
  links?: LinkDto[]
  localPrice?: boolean
  localPriceList?: number
  longText?: string
  lowerLimit?: number
  /** @format int32 */
  marketClearance?: number
  medicalAspiration?: boolean
  monitoringAccessories?: boolean
  multiVendorAdapter?: boolean
  nebulizer?: boolean
  neoMonitoringAccessories?: boolean
  neoRailAccessoriesAndExaminationLight?: boolean
  neoSensors?: boolean
  neoVentilationAccessories?: boolean
  neoWardEquipment?: boolean
  neoWarmingTherapy?: boolean
  neonatalCare?: boolean
  neonates?: boolean
  neuromuscularTransm?: boolean
  nicu?: boolean
  nonInvasiveBloodPressure?: boolean
  nonInvasivePatientInterfacesAndAccessories?: boolean
  orValue?: boolean
  oxygenTherapy?: boolean
  oxymixer?: boolean
  pack2Go?: boolean
  /** @format int32 */
  packagePerDelivery?: number
  pediatrics?: boolean
  /** @format int32 */
  phaseOut?: number
  /** @format int32 */
  piecePerPackage?: number
  pulseContCardOutput?: boolean
  pulseOximetry?: boolean
  railAccessories?: boolean
  railAccessoriesPc2?: boolean
  relatedProducts?: number[]
  rfid?: boolean
  scio?: boolean
  sensors?: boolean
  sodaLime?: boolean
  sparePartsForResuscitators?: boolean
  subtitle?: string
  temperature?: boolean
  title: string
  unitPrice?: number
  /** @format int32 */
  unitsPerPackage?: number
  upperLimit?: number
  usage?: 'SINGLEUSE' | 'REUSABLE' | 'LIMITEDREUSABLE' | 'SINGLEPATIENTMULTUSE'
  ventilationAccessories?: boolean
  vista120?: boolean
  wardEquipment?: boolean
}

/** ProductsPropertiesDto */
export interface ProductsPropertiesDto {
  accessoryForGasSupply?: boolean
  adult?: boolean
  aerosolTherapy?: boolean
  alternativeProducts?: number[]
  /** @format int32 */
  availability: number
  bispectralIndex?: boolean
  breathingCircuits?: boolean
  breathingSystemFiltersAndHMEs?: boolean
  capnography?: boolean
  cardiacOutput?: boolean
  catheterMountsAndAirwayConnectors?: boolean
  co2Cuvettes?: boolean
  continuousNonInvasiveArterialPressure?: boolean
  /** @format int32 */
  criticalQuantity?: number
  customStatus?: ProductCustomStatusResponseDto
  dedicated?: boolean
  /** @format int32 */
  defaultSorting?: number
  details?: DetailsDto[]
  deviceRelatedAccessories?: boolean
  devices?: DeviceDto[]
  disclaimer?: string
  electrocardiography?: boolean
  electroencephalography?: boolean
  examinationLight?: boolean
  ggpUnitPrice?: number
  globalPrice?: number
  highlightText?: string
  hingedArms?: boolean
  humidifier?: boolean
  humidifierAndNebulizer?: boolean
  icu?: boolean
  /** @format int64 */
  id: number
  image360?: boolean
  imageCategory?: string
  imageId?: string
  images?: ImageDto[]
  infinityAcuteCareSystem?: boolean
  infinityM300?: boolean
  infinityMonitoring?: boolean
  intubationKits?: boolean
  invasiveBloodPressure?: boolean
  isNew?: boolean
  keywords?: string[]
  labeledAsContainingLatex?: boolean
  links?: LinkDto[]
  localPrice?: boolean
  localPriceList?: number
  longText?: string
  lowerLimit?: number
  /** @format int32 */
  marketClearance?: number
  medicalAspiration?: boolean
  monitoringAccessories?: boolean
  multiVendorAdapter?: boolean
  nebulizer?: boolean
  neoMonitoringAccessories?: boolean
  neoRailAccessoriesAndExaminationLight?: boolean
  neoSensors?: boolean
  neoVentilationAccessories?: boolean
  neoWardEquipment?: boolean
  neoWarmingTherapy?: boolean
  neonatalCare?: boolean
  neonates?: boolean
  neuromuscularTransm?: boolean
  nicu?: boolean
  nonInvasiveBloodPressure?: boolean
  nonInvasivePatientInterfacesAndAccessories?: boolean
  orValue?: boolean
  oxygenTherapy?: boolean
  oxymixer?: boolean
  pack2Go?: boolean
  /** @format int32 */
  packagePerDelivery?: number
  pediatrics?: boolean
  /** @format int32 */
  phaseOut?: number
  /** @format int32 */
  piecePerPackage?: number
  pulseContCardOutput?: boolean
  pulseOximetry?: boolean
  railAccessories?: boolean
  railAccessoriesPc2?: boolean
  relatedProducts?: number[]
  rfid?: boolean
  scio?: boolean
  sensors?: boolean
  sodaLime?: boolean
  sparePartsForResuscitators?: boolean
  subtitle?: string
  temperature?: boolean
  title: string
  unitPrice?: number
  /** @format int32 */
  unitsPerPackage?: number
  upperLimit?: number
  usage?: 'SINGLEUSE' | 'REUSABLE' | 'LIMITEDREUSABLE' | 'SINGLEPATIENTMULTUSE'
  ventilationAccessories?: boolean
  vista120?: boolean
  wardEquipment?: boolean
}

/** ReportDataDto */
export interface ReportDataDto {
  /**
   * @minLength 3
   * @maxLength 2147483647
   */
  description: string
  /** @format int64 */
  productId: number
  /**
   * @minLength 3
   * @maxLength 998
   */
  topic: string
}

/** ResultListDtoOfCompanyResponseDto */
export interface ResultListDtoOfCompanyResponseDto {
  /** List content */
  content: CompanyResponseDto[]
}

/** ResultListDtoOfCountryShareDto */
export interface ResultListDtoOfCountryShareDto {
  /** List content */
  content: CountryShareDto[]
}

/** ResultListDtoOfDisplayProductListElementResponseDto */
export interface ResultListDtoOfDisplayProductListElementResponseDto {
  /** List content */
  content: DisplayProductListElementResponseDto[]
}

/** ResultListDtoOfDisplayProductListElementWithStatusResponseDto */
export interface ResultListDtoOfDisplayProductListElementWithStatusResponseDto {
  /** List content */
  content: DisplayProductListElementWithStatusResponseDto[]
}

/** ResultListDtoOfDynamicFilterDto */
export interface ResultListDtoOfDynamicFilterDto {
  /** List content */
  content: DynamicFilterDto[]
}

/** ResultListDtoOfExternalUserDto */
export interface ResultListDtoOfExternalUserDto {
  /** List content */
  content: ExternalUserDto[]
}

/** ResultListDtoOfFavoriteProductResponseDto */
export interface ResultListDtoOfFavoriteProductResponseDto {
  /** List content */
  content: FavoriteProductResponseDto[]
}

/** ResultListDtoOfPinnedListResponseDto */
export interface ResultListDtoOfPinnedListResponseDto {
  /** List content */
  content: PinnedListResponseDto[]
}

/** ResultListDtoOfProductListOverallStatisticsDto */
export interface ResultListDtoOfProductListOverallStatisticsDto {
  /** List content */
  content: ProductListOverallStatisticsDto[]
}

/** ResultListDtoOfProductListRowResponseDto */
export interface ResultListDtoOfProductListRowResponseDto {
  /** List content */
  content: ProductListRowResponseDto[]
}

/** ResultListDtoOfProductQuantityAmountDto */
export interface ResultListDtoOfProductQuantityAmountDto {
  /** List content */
  content: ProductQuantityAmountDto[]
}

/** ResultListDtoOfProductRowResponseDto */
export interface ResultListDtoOfProductRowResponseDto {
  /** List content */
  content: ProductRowResponseDto[]
}

/** ResultListDtoOfScpUserAutocompleteDto */
export interface ResultListDtoOfScpUserAutocompleteDto {
  /** List content */
  content: ScpUserAutocompleteDto[]
}

/** ResultListDtoOfShareDraftDto */
export interface ResultListDtoOfShareDraftDto {
  /** List content */
  content: ShareDraftDto[]
}

/** ResultListDtoOfSubregionDto */
export interface ResultListDtoOfSubregionDto {
  /** List content */
  content: SubregionDto[]
}

/** ResultListDtoOfTableImportStatusDetailsResponseDto */
export interface ResultListDtoOfTableImportStatusDetailsResponseDto {
  /** List content */
  content: TableImportStatusDetailsResponseDto[]
}

/** ResultListDtoOfTableImportStatusResponseDto */
export interface ResultListDtoOfTableImportStatusResponseDto {
  /** List content */
  content: TableImportStatusResponseDto[]
}

/** ResultListDtoOfUserAutocompleteDto */
export interface ResultListDtoOfUserAutocompleteDto {
  /** List content */
  content: UserAutocompleteDto[]
}

/** ResultListDtoOfUserListRowDto */
export interface ResultListDtoOfUserListRowDto {
  /** List content */
  content: UserListRowDto[]
}

/** ResultListWithTotalCountDtoOfProductAvailabilityIndicatorDictRowResponseDto */
export interface ResultListWithTotalCountDtoOfProductAvailabilityIndicatorDictRowResponseDto {
  /** List content */
  content: ProductAvailabilityIndicatorDictRowResponseDto[]
  /**
   * Total number of possible results
   * @format int64
   */
  totalCount: number
}

/** ResultListWithTotalCountDtoOfProductResponseDto */
export interface ResultListWithTotalCountDtoOfProductResponseDto {
  /** List content */
  content: ProductResponseDto[]
  /**
   * Total number of possible results
   * @format int64
   */
  totalCount: number
}

/** ResultPageWithTotalCountDtoOfNotificationDto */
export interface ResultPageWithTotalCountDtoOfNotificationDto {
  /**
   * Number of this page (zero based)
   * @format int32
   */
  pageNumber: number
  /**
   * Size of this page (length of content)
   * @format int32
   */
  pageSize: number
  /**
   * Total number of results
   * @format int64
   */
  totalCount: number
  /** Page content */
  content: NotificationDto[]
}

/** ResultPageWithTotalCountDtoOfProductAvailabilityIndicatorRowResponseDto */
export interface ResultPageWithTotalCountDtoOfProductAvailabilityIndicatorRowResponseDto {
  /**
   * Number of this page (zero based)
   * @format int32
   */
  pageNumber: number
  /**
   * Size of this page (length of content)
   * @format int32
   */
  pageSize: number
  /**
   * Total number of results
   * @format int64
   */
  totalCount: number
  /** Page content */
  content: ProductAvailabilityIndicatorRowResponseDto[]
}

/** ResultPageWithTotalCountDtoOfProductRowResponseDto */
export interface ResultPageWithTotalCountDtoOfProductRowResponseDto {
  /**
   * Number of this page (zero based)
   * @format int32
   */
  pageNumber: number
  /**
   * Size of this page (length of content)
   * @format int32
   */
  pageSize: number
  /**
   * Total number of results
   * @format int64
   */
  totalCount: number
  /** Page content */
  content: ProductRowResponseDto[]
}

/** ScpShareDataDto */
export interface ScpShareDataDto {
  companyIds?: number[]
  /**
   * @minLength 2
   * @maxLength 250
   */
  note?: string
  userIds?: number[]
}

/** ScpUserAutocompleteDto */
export interface ScpUserAutocompleteDto {
  company?: CompanyResponseDto
  firstName?: string
  /** @format int64 */
  id: number
  lastName?: string
  username: string
}

/** ScpUserConfirmationRequestDto */
export interface ScpUserConfirmationRequestDto {
  /** @format int64 */
  companyId?: number
  countriesIds?: number[]
  organizationRole?:
    | 'SALES_REPRESENTATIVE'
    | 'MARKETING'
    | 'SERVICE'
    | 'BACKOFFICE'
    | 'ACADEMY'
    | 'BU_HCA'
    | 'OTHER'
  scpStatus: 'ACCEPTED' | 'REJECTED' | 'PENDING' | 'ONBOARDING_REQUIRED'
}

/** SearchAndFilterRequestDto */
export interface SearchAndFilterRequestDto {
  careArea?: ('OR' | 'ICU' | 'NICU')[]
  clearance?: ('CLEARANCE' | 'NO_CLEARANCE')[]
  deviceGroup?: (
    | 'ANESTHESIA_APOLLO'
    | 'ANESTHESIA_ALL_ATLAN'
    | 'ANESTHESIA_ATLAN_A100'
    | 'ANESTHESIA_ATLAN_A100_XL'
    | 'ANESTHESIA_ATLAN_A350'
    | 'ANESTHESIA_ATLAN_A350_XL'
    | 'ANESTHESIA_ATLAN_A300_XL'
    | 'ANESTHESIA_ATLAN_A300'
    | 'ANESTHESIA_ALL_FABIUS'
    | 'ANESTHESIA_FABIUS_GS'
    | 'ANESTHESIA_FABIUS_GS_PREMIUM'
    | 'ANESTHESIA_FABIUS_TIRO'
    | 'ANESTHESIA_FABIUS_MRI'
    | 'ANESTHESIA_FABIUS_PLUS_XL'
    | 'ANESTHESIA_FABIUS_PLUS'
    | 'ANESTHESIA_PERSEUS_A500'
    | 'ANESTHESIA_ALL_PRIMUS'
    | 'ANESTHESIA_PRIMUS_IE'
    | 'ANESTHESIA_PRIMUS'
    | 'ANESTHESIA_ALL_ZEUS'
    | 'ANESTHESIA_ZEUS_IE'
    | 'ANESTHESIA_ZEUS'
    | 'VENTILATION_ALL_BABYLOG'
    | 'VENTILATION_BABYLOG_8000'
    | 'VENTILATION_BABYLOG_VN800'
    | 'VENTILATION_BABYLOG_VN600'
    | 'VENTILATION_BABYLOG_VN500'
    | 'VENTILATION_ALL_EVITA'
    | 'VENTILATION_EVITA_V500'
    | 'VENTILATION_EVITA_V300'
    | 'VENTILATION_EVITA_V800'
    | 'VENTILATION_EVITA_2_DURA'
    | 'VENTILATION_EVITA_4'
    | 'VENTILATION_EVITA_XL'
    | 'VENTILATION_EVITA_V600'
    | 'VENTILATION_ALL_OXYLOG'
    | 'VENTILATION_OXYLOG_VE_300'
    | 'VENTILATION_OXYLOG_1000'
    | 'VENTILATION_OXYLOG_2000_PLUS'
    | 'VENTILATION_OXYLOG_3000'
    | 'VENTILATION_OXYLOG_3000_PLUS'
    | 'VENTILATION_PULMOVISTA_500'
    | 'VENTILATION_ALL_SAVINA'
    | 'VENTILATION_SAVINA_300'
    | 'VENTILATION_SAVINA_300_SELECT'
    | 'VENTILATION_SAVINA_300_CLASSIC'
    | 'VENTILATION_SAVINA_300_COLOR'
    | 'VENTILATION_SAVINA_300_NIV'
    | 'VENTILATION_CARINA'
    | 'VENTILATION_AQUAPOR'
    | 'THERMOREGULATION_BABYLEO'
    | 'THERMOREGULATION_CALEO'
    | 'THERMOREGULATION_RESUSCITAIRE'
    | 'THERMOREGULATION_ALL_BABYTHERM'
    | 'THERMOREGULATION_BABYTHERM_8004'
    | 'THERMOREGULATION_BABYTHERM_8010'
    | 'THERMOREGULATION_ALL_ISOLETTE'
    | 'THERMOREGULATION_ISOLETTE_8000_PLUS'
    | 'THERMOREGULATION_ISOLETTE_8000'
    | 'THERMOREGULATION_ISOLETTE_C2000'
    | 'THERMOREGULATION_ISOLETTE_TI500'
    | 'THERMOREGULATION_ALL_GLOBE_TROTTER'
    | 'THERMOREGULATION_GLOBE_TROTTER_IC'
    | 'THERMOREGULATION_GLOBE_TROTTER_GT5400'
    | 'THERMOREGULATION_BABYROO_TN300'
    | 'MONITORING_ALL_INFINITY'
    | 'MONITORING_INFINITY_ACUTE_CARE_M540'
    | 'MONITORING_INFINITY_ACUTE_CARE_ACUTE_CARE_SYSTEM'
    | 'MONITORING_INFINITY_DELTA_FAMILY_DELTA'
    | 'MONITORING_INFINITY_DELTA_FAMILY_KAPPA_XLT'
    | 'MONITORING_INFINITY_DELTA_FAMILY_GAMMA_XXL'
    | 'MONITORING_INFINITY_DELTA_FAMILY_GAMMA_XL'
    | 'MONITORING_INFINITY_DELTA_FAMILY_VISTA_XL'
    | 'MONITORING_INFINITY_DELTA_FAMILY_VISTA'
    | 'MONITORING_M300_M300_PLUS'
    | 'MONITORING_ALL_VISTA'
    | 'MONITORING_VISTA_120SC_MODEL_A'
    | 'MONITORING_VISTA_120SC_MODEL_B'
    | 'MONITORING_VISTA_120SC_MODEL_C'
    | 'MONITORING_VISTA_120SC_MODEL_D'
    | 'MONITORING_VISTA_120SC_MODEL_E'
    | 'MONITORING_VISTA_300_MODEL_A'
    | 'MONITORING_VISTA_300_MODEL_C'
    | 'MONITORING_VISTA_120_A'
    | 'MONITORING_VISTA_120_A_PLUS'
    | 'MONITORING_VISTA_120_C'
    | 'MONITORING_VISTA_120_C_PLUS'
    | 'MONITORING_VISTA_120_S_A'
    | 'MONITORING_VISTA_120_S_A_PLUS'
    | 'MONITORING_VISTA_120_S_C'
    | 'MONITORING_VISTA_120_S_C_PLUS'
    | 'MONITORING_TOFSCAN'
    | 'MONITORING_ALL_POLARIS'
    | 'OTHER_POLARIS_100'
    | 'OTHER_POLARIS_200'
    | 'OTHER_POLARIS_100_200'
    | 'OTHER_POLARIS_600'
    | 'OTHER_POLARIS_MULTIMEDIA'
  )[]
  dynamicFilters?: number[]
  moreFilters?: ('RFID' | 'LATEX_FREE' | 'NEW')[]
  patientGroup?: ('ADULTS' | 'PAEDIATRICS' | 'NEONATES')[]
  productCluster?: (
    | 'SODA_LIME'
    | 'SENSORS'
    | 'VENTILATION_ACCESSORIES_ALL'
    | 'VENTILATION_BREATHING_CIRCUITS'
    | 'VENTILATION_BREATHING_FILTERS'
    | 'VENTILATION_ACCESSORIES_C02_CUVETTES'
    | 'VENTILATION_ACCESSORIES_CATHETER_MOUNTS'
    | 'VENTILATION_ACCESSORIES_OXYMIZER'
    | 'VENTILATION_ACCESSORIES_ACCESSORIES'
    | 'VENTILATION_ACCESSORIES_PACK2GO'
    | 'VENTILATION_NON_INVASIVE_PATIENT_INTERFACES_AND_ACCESSORIES'
    | 'VENTILATION_INTUBATION_KITS'
    | 'VENTILATION_HUMIDIFIER'
    | 'VENTILATION_NEBULIZER'
    | 'VENTILATION_SPARE_PARTS_FOR_RESUSCITATORS'
    | 'MONITORING_ACCESSORIES_ALL'
    | 'MONITORING_ACCESSORIES_ELECTROCARDIOGRAPHY'
    | 'MONITORING_ACCESSORIES_NON_INVASIVE_BLOOD_PRESSURE'
    | 'MONITORING_ACCESSORIES_PULSE_OXIMETRY'
    | 'MONITORING_ACCESSORIES_TEMPERATURE'
    | 'MONITORING_ACCESSORIES_INVASIVE_BLOOD_PRESSURE'
    | 'MONITORING_ACCESSORIES_CAPNOGRAPHY'
    | 'MONITORING_ACCESSORIES_NEUROMUSCULAR_TRANSM'
    | 'MONITORING_ACCESSORIES_BISECTRAL_INDEX'
    | 'MONITORING_ACCESSORIES_CARDIAC_OUTPUT'
    | 'MONITORING_ACCESSORIES_PULSE_CONT_CARD_OUTPUT'
    | 'MONITORING_ACCESSORIES_SCIO'
    | 'MONITORING_ACCESSORIES_ELECTROENCEPHALOGRAPHY'
    | 'MONITORING_ACCESSORIES_CONTINUOUS_NON_INVASIVE_ARTERIAL_PRESSURE'
    | 'WARD_EQUIPMENT_ALL'
    | 'WARD_EQUIPMENT_OXYGEN_THERAPY'
    | 'WARD_EQUIPMENT_MEDICAL_ASPIRATION'
    | 'WARD_EQUIPMENT_AEROSOL_THERAPY'
    | 'WARD_EQUIPMENT_ACCESSORY_FOR_GAS'
    | 'RAIL_ACCESSORIES_ALL'
    | 'RAIL_ACCESSORIES_PC2'
    | 'RAIL_ACCESSORIES_EXAMINATION_LIGHT'
    | 'RAIL_ACCESSORIES_EXAMINATION_LIGHT_DEVICE_RELATED_ACCESSORIES'
    | 'RAIL_ACCESSORIES_HINGED_ARMS'
    | 'NEONATAL_CARE_ALL'
    | 'NEONATAL_CARE_NEO_WARMING_THERAPY'
    | 'NEONATAL_CARE_NEO_VETINLATION_ACCESSORIES'
    | 'NEONATAL_CARE_NEO_SENSORS'
    | 'NEONATAL_CARE_NEO_MONITORING_ACCESSORIES'
    | 'NEONATAL_CARE_NEO_WARD_EQUIPMENT'
    | 'NEONATAL_CARE_NEO_RAIL_ACCESSORIES_AND_EXAMINATION_LIGHT'
  )[]
  search?: string
}

/** ShareDataDto */
export interface ShareDataDto {
  /**
   * @minLength 2
   * @maxLength 250
   */
  note?: string
  userIds: number[]
}

/** ShareDraftDetailsDto */
export interface ShareDraftDetailsDto {
  /** @format int64 */
  createdAt: number
  currency: string
  customSort: boolean
  description?: string
  elements?: DisplayProductListElementResponseDto[]
  /** @format int64 */
  id: number
  /** @format int64 */
  lastUpdatedAt?: number
  note?: string
  /** @format int32 */
  numberOfProducts: number
  shareCountries: number[]
  /** @format int64 */
  shareLastUpdatedAt: number
  shareOwnerName: string
  sortParameter?: 'PRODUCT_GROUP' | 'PRODUCT_TITLE' | 'CREATED_AT' | 'CUSTOM'
  title: string
}

/** ShareDraftDto */
export interface ShareDraftDto {
  /** @format int64 */
  createdAt: number
  currency: string
  description?: string
  /** @format int64 */
  id: number
  /** @format int64 */
  lastUpdatedAt?: number
  note?: string
  /** @format int32 */
  numberOfProducts: number
  shareCountries: number[]
  sortParameter?: 'PRODUCT_GROUP' | 'PRODUCT_TITLE' | 'CREATED_AT' | 'CUSTOM'
  title: string
}

/** ShareDraftElementDto */
export interface ShareDraftElementDto {
  /**
   * Required when user is not BASIC_USER
   * @max 999999999999.99
   * @exclusiveMax false
   */
  crp?: number
  /** @format int64 */
  productId: number
  /**
   * Required when user is not BASIC_USER
   * @format int32
   * @min 1
   * @exclusiveMin false
   * @max 999999
   * @exclusiveMax false
   */
  quantity?: number
}

/** ShareDraftPublishDto */
export interface ShareDraftPublishDto {
  countryIds: number[]
  note: string
}

/** SortOrderRequestDto */
export interface SortOrderRequestDto {
  /**
   * @format int64
   * @example 1
   */
  elementId: number
  /**
   * @format int32
   * @min 1
   * @exclusiveMin false
   * @example 1
   */
  sortOrder: number
}

/** StackTraceElement */
export interface StackTraceElement {
  classLoaderName?: string
  className?: string
  fileName?: string
  /** @format int32 */
  lineNumber?: number
  methodName?: string
  moduleName?: string
  moduleVersion?: string
  nativeMethod?: boolean
}

/**
 * StringDto
 * String object
 */
export interface StringDto {
  value?: string
}

/** SubregionDto */
export interface SubregionDto {
  countries: CountryDto[]
  subregion: string
}

/** SynchronizeRequestDto */
export interface SynchronizeRequestDto {
  favoriteProducts?: FavoriteProductsRequestDto
  productLists?: ProductListsOfflineRequestDto
  profile?: BaseUserProfileDto
  shares?: PrivateShareCreatedRequestDto[]
}

/** SynchronizeResultResponseDto */
export interface SynchronizeResultResponseDto {
  favoriteProducts: FavoriteProductResponseDto[]
  productLists: ProductListWithElementsResponseDto[]
  /** Contains user profile information */
  profile: DisplayUserProfileResponseDto
}

/** TableImportErrorResponseDto */
export interface TableImportErrorResponseDto {
  connectedElements?: string[]
  details?: string
  header: string
  status: 'IMPORTANT' | 'CRITICAL' | 'WARNING'
}

/** TableImportStatusDetailsResponseDto */
export interface TableImportStatusDetailsResponseDto {
  errors?: TableImportErrorResponseDto[]
  /** @format date-time */
  finishDatetime?: string
  /** @format int64 */
  id: number
  /** @format date-time */
  startDatetime?: string
  status:
    | 'QUEUED'
    | 'IN_PROGRESS'
    | 'NOT_FINALIZED'
    | 'WARNING'
    | 'IMPORTANT'
    | 'CRITICAL'
    | 'SUCCESS'
}

/** TableImportStatusResponseDto */
export interface TableImportStatusResponseDto {
  /** @format date-time */
  finishDatetime?: string
  /** @format int64 */
  id: number
  /** @format date-time */
  startDatetime?: string
  status:
    | 'QUEUED'
    | 'IN_PROGRESS'
    | 'NOT_FINALIZED'
    | 'WARNING'
    | 'IMPORTANT'
    | 'CRITICAL'
    | 'SUCCESS'
  tableName: string
}

/** TableImportStatusSynchronizationResponseDto */
export interface TableImportStatusSynchronizationResponseDto {
  synchronizationInProgress: boolean
  synchronizationIsPossible: boolean
}

/** Throwable */
export interface Throwable {
  cause?: Throwable
  localizedMessage?: string
  message?: string
  stackTrace?: StackTraceElement[]
  suppressed?: Throwable[]
}

/** TitleAndDescriptionDto */
export interface TitleAndDescriptionDto {
  description?: string
  title: string
}

/** UserAutocompleteDto */
export interface UserAutocompleteDto {
  firstName?: string
  /** @format int64 */
  id: number
  lastName?: string
  username: string
}

/** UserEditInfoResponseDto */
export interface UserEditInfoResponseDto {
  company?: CompanyResponseDto
  country?: string
  deleted?: boolean
  email: string
  enabled: boolean
  firstName?: string
  /** @format int64 */
  id: number
  lastName?: string
  organizationRole?:
    | 'SALES_REPRESENTATIVE'
    | 'MARKETING'
    | 'SERVICE'
    | 'BACKOFFICE'
    | 'ACADEMY'
    | 'BU_HCA'
    | 'OTHER'
  originScpCountry?: string
  roles?: UserRoleResponseDto[]
  scpStatus?: 'ACCEPTED' | 'REJECTED' | 'PENDING' | 'ONBOARDING_REQUIRED'
}

/**
 * UserListRowDto
 * Single row of users' list
 */
export interface UserListRowDto {
  activeRole:
    | 'FIREBASE'
    | 'ADMIN'
    | 'ADVANCED_USER'
    | 'ADVANCED_USER_PLUS'
    | 'BASIC_USER'
    | 'BASIC_USER_PLUS'
    | 'KEY_USER'
    | 'SALES_CHANNEL_PARTNER'
  country?: string
  /** @format int64 */
  createdAt: number
  deleted?: boolean
  email: string
  enabled: boolean
  /** @format int64 */
  id: number
  language: LanguageResponseDto
  /** @format int64 */
  lastActivity?: number
  organizationRole?:
    | 'SALES_REPRESENTATIVE'
    | 'MARKETING'
    | 'SERVICE'
    | 'BACKOFFICE'
    | 'ACADEMY'
    | 'BU_HCA'
    | 'OTHER'
  subregion: string
  username: string
}

/**
 * UserResponseDto
 * User
 */
export interface UserResponseDto {
  companyName?: string
  country?: CountryDto
  currencyName?: string
  email: string
  firstName: string
  /** @format int64 */
  id: number
  languageName?: string
  lastName: string
  organizationRole?:
    | 'SALES_REPRESENTATIVE'
    | 'MARKETING'
    | 'SERVICE'
    | 'BACKOFFICE'
    | 'ACADEMY'
    | 'BU_HCA'
    | 'OTHER'
  originScpCountry?: CountryDto
  role:
    | 'FIREBASE'
    | 'ADMIN'
    | 'ADVANCED_USER'
    | 'ADVANCED_USER_PLUS'
    | 'BASIC_USER'
    | 'BASIC_USER_PLUS'
    | 'KEY_USER'
    | 'SALES_CHANNEL_PARTNER'
  roles?: UserRoleResponseDto[]
  scpStatus?: 'ACCEPTED' | 'REJECTED' | 'PENDING' | 'ONBOARDING_REQUIRED'
  userType?: 'SCP' | 'INTERNAL' | 'HCA'
  username: string
}

/** UserRoleRequestDto */
export interface UserRoleRequestDto {
  /** @format int64 */
  countryId?: number
  role:
    | 'FIREBASE'
    | 'ADMIN'
    | 'ADVANCED_USER'
    | 'ADVANCED_USER_PLUS'
    | 'BASIC_USER'
    | 'BASIC_USER_PLUS'
    | 'KEY_USER'
    | 'SALES_CHANNEL_PARTNER'
}

/** UserRoleResponseDto */
export interface UserRoleResponseDto {
  /** @format int64 */
  countryId?: number
  role:
    | 'FIREBASE'
    | 'ADMIN'
    | 'ADVANCED_USER'
    | 'ADVANCED_USER_PLUS'
    | 'BASIC_USER'
    | 'BASIC_USER_PLUS'
    | 'KEY_USER'
    | 'SALES_CHANNEL_PARTNER'
}

/** UserUpdateRequestDto */
export interface UserUpdateRequestDto {
  enabled?: boolean
  roles?: UserRoleRequestDto[]
}

/**
 * ValidationErrorMsgDto
 * Validation error message
 */
export interface ValidationErrorMsgDto {
  /** Message key */
  message: string
  /** Message parameters */
  parameters?: object[]
  /** Field name */
  field: string
}

/** ValidationErrors */
export interface ValidationErrors {
  fieldErrors?: ValidationErrorMsgDto[]
  globalErrors?: ErrorMsgDto[]
}

/** ValidationException */
export interface ValidationException {
  cause?: Throwable
  /** @format int32 */
  errorCode?: number
  localizedMessage?: string
  message?: string
  stackTrace?: StackTraceElement[]
  suppressed?: Throwable[]
  validationErrors?: ValidationErrors
}

/**
 * ValidationExceptionResponseDto
 * Bean validation error response
 */
export interface ValidationExceptionResponseDto {
  /**
   * Reflects HTTP Response status code
   * @format int32
   */
  code: number
  /** Errors for specific fields */
  fieldErrors: ValidationErrorMsgDto[]
  /** Global errors */
  globalErrors?: ErrorMsgDto[]
}

export type QueryParamsType = Record<string | number, any>
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat
  /** request body */
  body?: unknown
  /** base url */
  baseUrl?: string
  /** request cancellation token */
  cancelToken?: CancelToken
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<RequestParams | void> | RequestParams | void
  customFetch?: typeof fetch
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D
  error: E
}

type CancelToken = Symbol | string | number

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string =
    '//hca-client-api-v2-itcdev.azurewebsites.net/hca-client-api'
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private abortControllers = new Map<CancelToken, AbortController>()
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams)

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  }

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig)
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key)
    return `${encodedKey}=${encodeURIComponent(
      typeof value === 'number' ? value : `${value}`,
    )}`
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key])
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key]
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&')
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {}
    const keys = Object.keys(query).filter(
      (key) => 'undefined' !== typeof query[key],
    )
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key),
      )
      .join('&')
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery)
    return queryString ? `?${queryString}` : ''
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== 'string'
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key]
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        )
        return formData
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  }

  protected mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams,
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected createAbortSignal = (
    cancelToken: CancelToken,
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken)
      if (abortController) {
        return abortController.signal
      }
      return void 0
    }

    const abortController = new AbortController()
    this.abortControllers.set(cancelToken, abortController)
    return abortController.signal
  }

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken)

    if (abortController) {
      abortController.abort()
      this.abortControllers.delete(cancelToken)
    }
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const queryString = query && this.toQueryString(query)
    const payloadFormatter = this.contentFormatters[type || ContentType.Json]
    const responseFormat = format || requestParams.format

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${
        queryString ? `?${queryString}` : ''
      }`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { 'Content-Type': type }
            : {}),
        },
        signal: cancelToken
          ? this.createAbortSignal(cancelToken)
          : requestParams.signal,
        body:
          typeof body === 'undefined' || body === null
            ? null
            : payloadFormatter(body),
      },
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>
      r.data = null as unknown as T
      r.error = null as unknown as E

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data
              } else {
                r.error = data
              }
              return r
            })
            .catch((e) => {
              r.error = e
              return r
            })

      if (cancelToken) {
        this.abortControllers.delete(cancelToken)
      }

      if (!response.ok) throw data
      return data
    })
  }
}

/**
 * @title App: HCA
 * @version 0.0.2
 * @baseUrl //hca-client-api-v2-itcdev.azurewebsites.net/hca-client-api
 *
 * ## Client API
 *
 * | [HTTP statuses](https://hca-client-api-v2-itcdev.azurewebsites.net/hca-client-api/swagger/result-codes-info) | [Validation errors](https://hca-client-api-v2-itcdev.azurewebsites.net/hca-client-api/swagger/validation-messages-info) | [Business errors](https://hca-client-api-v2-itcdev.azurewebsites.net/hca-client-api/swagger/business-errors-codes-info) | [Push notifications](https://hca-client-api-v2-itcdev.azurewebsites.net/hca-client-api/swagger/push-messages-info) | [Resource not found errors](https://hca-client-api-v2-itcdev.azurewebsites.net/hca-client-api/swagger/resource-not-found-error-codes-info) |
 */
export class Api<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  appInfo = {
    /**
     * No description
     *
     * @tags appInfo
     * @name GetAppInfoUsingGet
     * @summary Get app info
     * @request GET:/app-info
     * @secure
     * @response `200` `AppInfoResponseDto` Success
     */
    getAppInfoUsingGet: (params: RequestParams = {}) =>
      this.request<AppInfoResponseDto, any>({
        path: `/app-info`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  companies = {
    /**
     * No description
     *
     * @tags company
     * @name GetCompaniesUsingGet
     * @summary Get companies
     * @request GET:/companies/list
     * @secure
     * @response `200` `ResultListDtoOfCompanyResponseDto` Success
     */
    getCompaniesUsingGet: (params: RequestParams = {}) =>
      this.request<ResultListDtoOfCompanyResponseDto, any>({
        path: `/companies/list`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  countries = {
    /**
     * No description
     *
     * @tags countries
     * @name GetCountriesListUsingGet
     * @summary Get all countries list based on role
     * @request GET:/countries/list
     * @secure
     * @response `200` `CountriesListDto` Success
     */
    getCountriesListUsingGet: (params: RequestParams = {}) =>
      this.request<CountriesListDto, any>({
        path: `/countries/list`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags countries
     * @name UpdateIsAvailableForScpUsingPatch
     * @summary updateIsAvailableForScp
     * @request PATCH:/countries/scp-available
     * @response `200` `void` OK
     */
    updateIsAvailableForScpUsingPatch: (
      requestDto: CountryScpAvailableRequestDto[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/countries/scp-available`,
        method: 'PATCH',
        body: requestDto,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags countries
     * @name GetSubregionsUsingGet
     * @summary Get countries list grouped by subregion based on role
     * @request GET:/countries/subregion-list
     * @secure
     * @response `200` `ResultListDtoOfSubregionDto` Success
     */
    getSubregionsUsingGet: (params: RequestParams = {}) =>
      this.request<ResultListDtoOfSubregionDto, any>({
        path: `/countries/subregion-list`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  countrySharing = {
    /**
     * @description ## Default sorting: - lastUpdatedAt DESC ## Sorting fields: - title - createdAt - lastUpdatedAt - numberOfProducts (in case of the same number, last updatedAt DESC) - description (in case of the same description, last updatedAt DESC)
     *
     * @tags countrySharing
     * @name GetCountrySharesUsingGet
     * @summary Get product lists
     * @request GET:/country-sharing/list
     * @secure
     * @response `200` `ResultListDtoOfCountryShareDto` Success
     */
    getCountrySharesUsingGet: (
      query?: {
        asc?: boolean
        /**
         * Column name
         * @default "name"
         */
        sortBy?: string
        /**
         * Column direction
         * @default "asc"
         */
        sortOrder?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResultListDtoOfCountryShareDto, any>({
        path: `/country-sharing/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags countrySharing
     * @name DownloadCountrySharePdfUsingGet
     * @summary Download product list as pdf
     * @request GET:/country-sharing/list/{listId}/download-pdf
     * @secure
     * @response `200` `string` Success
     * @response `464` `void` When share does not exist or is unpinned and country does not match (COUNTRY_SHARE_COUNTRY_NOT_FOUND)
     */
    downloadCountrySharePdfUsingGet: (
      listId: number,
      params: RequestParams = {},
    ) =>
      this.request<string, void>({
        path: `/country-sharing/list/${listId}/download-pdf`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags countrySharing
     * @name DownloadCountryShareXlsxUsingGet
     * @summary Download country share as xlsx
     * @request GET:/country-sharing/list/{listId}/download-xlsx
     * @secure
     * @response `200` `string` Success
     * @response `464` `void` When share does not exist or is unpinned and country does not match (COUNTRY_SHARE_COUNTRY_NOT_FOUND)
     */
    downloadCountryShareXlsxUsingGet: (
      listId: number,
      query?: {
        /**
         * includeFinancials
         * @default false
         */
        includeFinancials?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<string, void>({
        path: `/country-sharing/list/${listId}/download-xlsx`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags countrySharing
     * @name GetCountrySharePdfLinkUsingGet
     * @summary Get link to country share in pdf
     * @request GET:/country-sharing/list/{listId}/pdf-link
     * @secure
     * @response `200` `StringDto` Success
     * @response `464` `void` When share does not exist or is unpinned and country does not match (COUNTRY_SHARE_COUNTRY_NOT_FOUND)
     */
    getCountrySharePdfLinkUsingGet: (
      listId: number,
      params: RequestParams = {},
    ) =>
      this.request<StringDto, void>({
        path: `/country-sharing/list/${listId}/pdf-link`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags countrySharing
     * @name GetXlsxLinkUsingGet
     * @summary Get link to country share in xlsx
     * @request GET:/country-sharing/list/{listId}/xlsx-link
     * @secure
     * @response `200` `StringDto` Success
     * @response `464` `void` When share does not exist or is unpinned and country does not match (COUNTRY_SHARE_COUNTRY_NOT_FOUND)
     */
    getXlsxLinkUsingGet: (
      listId: number,
      query?: {
        /**
         * includeFinancials
         * @default false
         */
        includeFinancials?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<StringDto, void>({
        path: `/country-sharing/list/${listId}/xlsx-link`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags countrySharing
     * @name RemoveCountryShareUsingDelete
     * @summary Remove share
     * @request DELETE:/country-sharing/{listId}
     * @secure
     * @response `200` `void` Success
     * @response `409` `void` When key user has no permission in one of the sharing countries
     * @response `464` `void` When share does not exist or is unpinned and country does not match (COUNTRY_SHARE_COUNTRY_NOT_FOUND)
     */
    removeCountryShareUsingDelete: (
      listId: number,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/country-sharing/${listId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags countrySharing
     * @name CopyUsingPost
     * @summary Copy share
     * @request POST:/country-sharing/{listId}/copy
     * @secure
     * @response `200` `CopiedProductListResponseDto` Success
     * @response `460` `void`
     * @response `464` `void` When share does not exist or is unpinned and country does not match (COUNTRY_SHARE_COUNTRY_NOT_FOUND)
     */
    copyUsingPost: (
      listId: number,
      dto: CopyShareDto,
      params: RequestParams = {},
    ) =>
      this.request<CopiedProductListResponseDto, void>({
        path: `/country-sharing/${listId}/copy`,
        method: 'POST',
        body: dto,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns details and list of elements sorted by title ASC. Prices not displayed for role == BASIC_USER
     *
     * @tags countrySharing
     * @name GetCountryShareDetailsUsingGet
     * @summary Get shared list details
     * @request GET:/country-sharing/{listId}/details
     * @secure
     * @response `200` `CountryShareDetailsDto` Success
     * @response `464` `void` When share does not exist or is unpinned and country does not match (COUNTRY_SHARE_COUNTRY_NOT_FOUND)
     */
    getCountryShareDetailsUsingGet: (
      listId: number,
      params: RequestParams = {},
    ) =>
      this.request<CountryShareDetailsDto, void>({
        path: `/country-sharing/${listId}/details`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns list of elements sorted by title ASC. Prices not displayed for role == BASIC_USER
     *
     * @tags countrySharing
     * @name GetCountryShareElementsUsingGet
     * @summary Get shared list elements
     * @request GET:/country-sharing/{listId}/list-elements
     * @secure
     * @response `200` `ResultListDtoOfDisplayProductListElementWithStatusResponseDto` Success
     * @response `464` `void` When share does not exist or is unpinned and country does not match (COUNTRY_SHARE_COUNTRY_NOT_FOUND)
     */
    getCountryShareElementsUsingGet: (
      listId: number,
      params: RequestParams = {},
    ) =>
      this.request<
        ResultListDtoOfDisplayProductListElementWithStatusResponseDto,
        void
      >({
        path: `/country-sharing/${listId}/list-elements`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags countrySharing
     * @name PinCountryProductListUsingPut
     * @summary Pin country shared list
     * @request PUT:/country-sharing/{listId}/pin
     * @secure
     * @response `200` `void` Success
     * @response `464` `void` When share does not exist or is unpinned and country does not match (COUNTRY_SHARE_COUNTRY_NOT_FOUND)
     */
    pinCountryProductListUsingPut: (
      listId: number,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/country-sharing/${listId}/pin`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags countrySharing
     * @name CountryShareUsingPost
     * @summary Share list
     * @request POST:/country-sharing/{listId}/share
     * @secure
     * @response `200` `CountryShareDetailsDto` Success
     * @response `409` `void` - When key user has no permission in one of the sharing countries - When product list is default
     * @response `460` `void` When one of the country id is incorrect or country list is empty or null
     * @response `464` `void` When list does not exist or is deleted (PRODUCT_LIST_NOT_FOUND)
     */
    countryShareUsingPost: (
      listId: number,
      dto: CountryShareDataDto,
      params: RequestParams = {},
    ) =>
      this.request<CountryShareDetailsDto, void>({
        path: `/country-sharing/${listId}/share`,
        method: 'POST',
        body: dto,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags countrySharing
     * @name UnpinCountryProductListUsingPut
     * @summary Unpin country shared list
     * @request PUT:/country-sharing/{listId}/unpin
     * @secure
     * @response `200` `void` Success
     * @response `464` `void` When share does not exist or is unpinned and country does not match (COUNTRY_SHARE_COUNTRY_NOT_FOUND)
     */
    unpinCountryProductListUsingPut: (
      listId: number,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/country-sharing/${listId}/unpin`,
        method: 'PUT',
        secure: true,
        ...params,
      }),
  }
  currencies = {
    /**
     * No description
     *
     * @tags currencies
     * @name GetCurrencyExchangesListUsingGet
     * @summary Get all currencies list
     * @request GET:/currencies/list
     * @secure
     * @response `200` `CurrencyExchangesListDto` Success
     */
    getCurrencyExchangesListUsingGet: (params: RequestParams = {}) =>
      this.request<CurrencyExchangesListDto, any>({
        path: `/currencies/list`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  dictionaries = {
    /**
     * No description
     *
     * @tags products
     * @name GetProductDictionaryUsingGet
     * @summary Returns products which fulfill searching criteria for availability indicator list. Sorted by title ASC. Current max result size: 10
     * @request GET:/dictionaries/products/availability-indicator
     * @secure
     * @response `200` `ResultListWithTotalCountDtoOfProductAvailabilityIndicatorDictRowResponseDto` OK
     */
    getProductDictionaryUsingGet: (
      query: {
        search: string
      },
      params: RequestParams = {},
    ) =>
      this.request<
        ResultListWithTotalCountDtoOfProductAvailabilityIndicatorDictRowResponseDto,
        any
      >({
        path: `/dictionaries/products/availability-indicator`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  disposableFile = {
    /**
     * @description Validates signed token and returns the file.
     *
     * @tags files
     * @name GetFileByDisposableTokenUsingGet
     * @summary Get file by signed token
     * @request GET:/disposable-file/{token}
     * @secure
     * @response `200` `void` OK
     * @response `464` `void` File not found (FILE_NOT_FOUND)
     */
    getFileByDisposableTokenUsingGet: (
      token: string,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/disposable-file/${token}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  }
  disposableUrl = {
    /**
     * @description Generate disposable URL for given file path
     *
     * @tags files
     * @name GenerateDisposableUrlUsingGet
     * @summary Generate disposable URL
     * @request GET:/disposable-url/{path}
     * @secure
     * @response `200` `StringDto` Successfully logged in
     * @response `464` `void` File not found in given path (FILE_NOT_FOUND)
     */
    generateDisposableUrlUsingGet: (path: string, params: RequestParams = {}) =>
      this.request<StringDto, void>({
        path: `/disposable-url/${path}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  favoriteProducts = {
    /**
     * @description Sorted by creation date DESC and title ASC
     *
     * @tags favoriteProducts
     * @name GetFavoritesUsingGet
     * @summary Get favorite products list
     * @request GET:/favorite-products/list
     * @secure
     * @response `200` `ResultListDtoOfFavoriteProductResponseDto` Success
     */
    getFavoritesUsingGet: (params: RequestParams = {}) =>
      this.request<ResultListDtoOfFavoriteProductResponseDto, any>({
        path: `/favorite-products/list`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags favoriteProducts
     * @name AddToFavoritesUsingPost
     * @summary Add product to favorites
     * @request POST:/favorite-products/{productId}
     * @secure
     * @response `200` `void` Success
     * @response `464` `void` When product does not exist (PRODUCT_NOT_FOUND)
     */
    addToFavoritesUsingPost: (productId: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/favorite-products/${productId}`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags favoriteProducts
     * @name DeleteFromFavoritesUsingDelete
     * @summary Delete product from favorites
     * @request DELETE:/favorite-products/{productId}
     * @secure
     * @response `200` `void` Success
     * @response `464` `void` When product does not exist (PRODUCT_NOT_FOUND)
     */
    deleteFromFavoritesUsingDelete: (
      productId: number,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/favorite-products/${productId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  }
  firebaseStatistics = {
    /**
     * No description
     *
     * @tags firebaseStatistics
     * @name GetProductListStatisticsUsingGet
     * @summary Get number of product lists globally and per country
     * @request GET:/firebase-statistics/amount-product-list
     * @secure
     * @response `200` `ProductListAmountDto` Success
     */
    getProductListStatisticsUsingGet: (params: RequestParams = {}) =>
      this.request<ProductListAmountDto, any>({
        path: `/firebase-statistics/amount-product-list`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags firebaseStatistics
     * @name GetProductQuantitiesAmountStatisticsUsingGet
     * @summary Get product quantities amount on the list globally and per country
     * @request GET:/firebase-statistics/amount-quantity-per-product
     * @secure
     * @response `200` `ResultListDtoOfProductQuantityAmountDto` Success
     */
    getProductQuantitiesAmountStatisticsUsingGet: (
      params: RequestParams = {},
    ) =>
      this.request<ResultListDtoOfProductQuantityAmountDto, any>({
        path: `/firebase-statistics/amount-quantity-per-product`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags firebaseStatistics
     * @name GetPriceStatisticsUsingGet
     * @summary Get average price per product
     * @request GET:/firebase-statistics/average-price-per-product
     * @secure
     * @response `200` `ProductPriceAverageDto` Success
     */
    getPriceStatisticsUsingGet: (params: RequestParams = {}) =>
      this.request<ProductPriceAverageDto, any>({
        path: `/firebase-statistics/average-price-per-product`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags firebaseStatistics
     * @name GetProductsStatisticsUsingGet
     * @summary Get average number of products per list globally and per country
     * @request GET:/firebase-statistics/average-products
     * @secure
     * @response `200` `ProductAverageOnListDto` Success
     */
    getProductsStatisticsUsingGet: (params: RequestParams = {}) =>
      this.request<ProductAverageOnListDto, any>({
        path: `/firebase-statistics/average-products`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags firebaseStatistics
     * @name GetAverageQuantityUsingGet
     * @summary Get average quantity of products
     * @request GET:/firebase-statistics/average-quantity-per-product
     * @secure
     * @response `200` `ProductQuantityAverageDto` Success
     */
    getAverageQuantityUsingGet: (params: RequestParams = {}) =>
      this.request<ProductQuantityAverageDto, any>({
        path: `/firebase-statistics/average-quantity-per-product`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags firebaseStatistics
     * @name GetFavoriteOccurencesStatisticsUsingGet
     * @summary Get product occurences on favorite list
     * @request GET:/firebase-statistics/favorite-occurences
     * @secure
     * @response `200` `FavoriteOccurrencesDto` Success
     */
    getFavoriteOccurencesStatisticsUsingGet: (params: RequestParams = {}) =>
      this.request<FavoriteOccurrencesDto, any>({
        path: `/firebase-statistics/favorite-occurences`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags firebaseStatistics
     * @name GetProductListOverallStatisticsUsingGet
     * @summary Get overall product list statistics
     * @request GET:/firebase-statistics/product-list-overall-statistics
     * @secure
     * @response `200` `ResultListDtoOfProductListOverallStatisticsDto` Success
     */
    getProductListOverallStatisticsUsingGet: (params: RequestParams = {}) =>
      this.request<ResultListDtoOfProductListOverallStatisticsDto, any>({
        path: `/firebase-statistics/product-list-overall-statistics`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags firebaseStatistics
     * @name GetProductOccurrencesStatisticsUsingGet
     * @summary Get product occurrences on list
     * @request GET:/firebase-statistics/product-occurrences
     * @secure
     * @response `200` `ProductOccurrencesDto` Success
     */
    getProductOccurrencesStatisticsUsingGet: (params: RequestParams = {}) =>
      this.request<ProductOccurrencesDto, any>({
        path: `/firebase-statistics/product-occurrences`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  login = {
    /**
     * No description
     *
     * @tags auth
     * @name LoginUsingGet
     * @summary login
     * @request GET:/login
     * @response `200` `void` OK
     */
    loginUsingGet: (
      query?: {
        /**
         * mobile
         * @default false
         */
        mobile?: boolean
        /**
         * okta
         * @default false
         */
        okta?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/login`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * @description Logins user when valid credentials
     *
     * @tags auth
     * @name LoginUsingPost
     * @summary Login user
     * @request POST:/login
     * @response `200` `AuthenticationDetailsResponseDto` Successfully logged in
     * @response `401` `void` Wrong JWT
     */
    loginUsingPost: (dto: LoginRequestDto, params: RequestParams = {}) =>
      this.request<AuthenticationDetailsResponseDto, void>({
        path: `/login`,
        method: 'POST',
        body: dto,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  logout = {
    /**
     * No description
     *
     * @tags auth
     * @name LogoutUsingGet
     * @summary Logout user, jwt is required when cookies are disabled
     * @request GET:/logout
     * @response `200` `StringDto` OK
     * @response `202` `StringDto` Successfully logged out
     */
    logoutUsingGet: (
      query?: {
        /** jwtToken */
        jwtToken?: string
        /**
         * mobile
         * @default false
         */
        mobile?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<StringDto, any>({
        path: `/logout`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name LogoutUsingPost
     * @summary Logout user
     * @request POST:/logout
     * @response `202` `void` Successfully logged out
     */
    logoutUsingPost: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/logout`,
        method: 'POST',
        ...params,
      }),
  }
  logoutFromB2C = {
    /**
     * No description
     *
     * @tags auth
     * @name LogoutFromB2CUsingGet
     * @summary Logout user
     * @request GET:/logout-from-b2c
     * @response `202` `void` Successfully logged out
     */
    logoutFromB2CUsingGet: (
      query?: {
        /**
         * mobile
         * @default false
         */
        mobile?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/logout-from-b2c`,
        method: 'GET',
        query: query,
        ...params,
      }),
  }
  notifications = {
    /**
     * No description
     *
     * @tags notifications
     * @name NotificationsPageUsingGet
     * @summary Notifications page sorted by date DESC
     * @request GET:/notifications/list
     * @secure
     * @response `200` `ResultPageWithTotalCountDtoOfNotificationDto` Success
     */
    notificationsPageUsingGet: (
      query?: {
        /**
         * Zero-based page index
         * @format int32
         * @default 0
         */
        page?: number
        /**
         * Page size
         * @format int32
         * @default 10
         */
        size?: number
        /**
         * Column name and direction, comma separated
         * @default "name,asc"
         */
        sort?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResultPageWithTotalCountDtoOfNotificationDto, any>({
        path: `/notifications/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags notifications
     * @name MarkAllNotificationsAsReadUsingPut
     * @summary Read all notifications
     * @request PUT:/notifications/mark-all-as-read
     * @secure
     * @response `200` `void` Success
     */
    markAllNotificationsAsReadUsingPut: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/notifications/mark-all-as-read`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags notifications
     * @name UnreadCountUsingGet
     * @summary Returns number of unread notifications
     * @request GET:/notifications/unread-count
     * @secure
     * @response `200` `IntegerDto` Success
     */
    unreadCountUsingGet: (params: RequestParams = {}) =>
      this.request<IntegerDto, any>({
        path: `/notifications/unread-count`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags notifications
     * @name ReadNotificationUsingPut
     * @summary Read single notification
     * @request PUT:/notifications/{notificationId}/read
     * @secure
     * @response `200` `void` Success
     * @response `464` `void` - when notification does not belong to user or does not exist (NOTIFICATION_NOT_FOUND)
     */
    readNotificationUsingPut: (
      notificationId: number,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/notifications/${notificationId}/read`,
        method: 'PUT',
        secure: true,
        ...params,
      }),
  }
  offline = {
    /**
     * @description After success pull all product lists
     *
     * @tags offline
     * @name SyncUsingPost
     * @summary Synchronize product lists
     * @request POST:/offline/sync
     * @secure
     * @response `200` `SynchronizeResultResponseDto` Success
     * @response `460` `void` Form validation failed
     */
    syncUsingPost: (dto: SynchronizeRequestDto, params: RequestParams = {}) =>
      this.request<SynchronizeResultResponseDto, void>({
        path: `/offline/sync`,
        method: 'POST',
        body: dto,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  password = {
    /**
     * No description
     *
     * @tags password
     * @name ResetPasswordUsingGet
     * @summary resetPassword
     * @request GET:/password/reset
     * @response `200` `void` Redirect to password reset form
     */
    resetPasswordUsingGet: (
      query?: {
        /**
         * mobile
         * @default false
         */
        mobile?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/password/reset`,
        method: 'GET',
        query: query,
        ...params,
      }),
  }
  pinnedLists = {
    /**
     * @description ## Sorting rule for fields other that lastUpdatedAt: provided field, provided order -> lastUpdatedAt, desc -> id, desc -> isShared, desc ## Sorting rule for lastUpdatedAt: lastUpdatedAt, provided direction -> id, desc -> isShared, desc ## Default sorting: - lastUpdatedAt, desc -> id, desc -> isShared, desc ## Sorting fields: - title - createdAt - lastUpdatedAt - numberOfProducts - description
     *
     * @tags pinnedLists
     * @name GetPinnedListsUsingGet
     * @summary Get pinned lists
     * @request GET:/pinned-lists/list
     * @secure
     * @response `200` `ResultListDtoOfPinnedListResponseDto` Success
     */
    getPinnedListsUsingGet: (
      query?: {
        asc?: boolean
        /**
         * Column name
         * @default "name"
         */
        sortBy?: string
        /**
         * Column direction
         * @default "asc"
         */
        sortOrder?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResultListDtoOfPinnedListResponseDto, any>({
        path: `/pinned-lists/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  privateSharing = {
    /**
     * No description
     *
     * @tags privateSharing
     * @name DownloadPrivateSharePdfUsingGet
     * @summary Download private share as pdf
     * @request GET:/private-sharing/list/{listId}/download-pdf
     * @secure
     * @response `200` `string` Success
     * @response `464` `void` - when list was not shared with user or does not exist (PRIVATE_SHARE_USER_NOT_FOUND)
     */
    downloadPrivateSharePdfUsingGet: (
      listId: number,
      params: RequestParams = {},
    ) =>
      this.request<string, void>({
        path: `/private-sharing/list/${listId}/download-pdf`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags privateSharing
     * @name DownloadPrivateShareXlsxUsingGet
     * @summary Download private share as xlsx
     * @request GET:/private-sharing/list/{listId}/download-xlsx
     * @secure
     * @response `200` `string` Success
     * @response `464` `void` - when list was not shared with user or does not exist (PRIVATE_SHARE_USER_NOT_FOUND)
     */
    downloadPrivateShareXlsxUsingGet: (
      listId: number,
      query?: {
        /**
         * includeFinancials
         * @default false
         */
        includeFinancials?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<string, void>({
        path: `/private-sharing/list/${listId}/download-xlsx`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags privateSharing
     * @name GetPrivateSharePdfLinkUsingGet
     * @summary Get link to private share in pdf
     * @request GET:/private-sharing/list/{listId}/pdf-link
     * @secure
     * @response `200` `StringDto` Success
     * @response `464` `void` - when list was not shared with user or does not exist (PRIVATE_SHARE_USER_NOT_FOUND)
     */
    getPrivateSharePdfLinkUsingGet: (
      listId: number,
      params: RequestParams = {},
    ) =>
      this.request<StringDto, void>({
        path: `/private-sharing/list/${listId}/pdf-link`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags privateSharing
     * @name GetPrivateShareXlsxLinkUsingGet
     * @summary Get link to private share in xlsx
     * @request GET:/private-sharing/list/{listId}/xlsx-link
     * @secure
     * @response `200` `StringDto` Success
     * @response `464` `void` - when list was not shared with user or does not exist (PRIVATE_SHARE_USER_NOT_FOUND)
     */
    getPrivateShareXlsxLinkUsingGet: (
      listId: number,
      query?: {
        /**
         * includeFinancials
         * @default false
         */
        includeFinancials?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<StringDto, void>({
        path: `/private-sharing/list/${listId}/xlsx-link`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags privateSharing
     * @name GetPrivateShareDetailsUsingGet
     * @summary Get shared list details
     * @request GET:/private-sharing/{listId}/details
     * @secure
     * @response `200` `PrivateShareDetailsDto` Success
     * @response `464` `void` When list was not shared with user or does not exist (PRIVATE_SHARE_USER_NOT_FOUND)
     */
    getPrivateShareDetailsUsingGet: (
      listId: number,
      params: RequestParams = {},
    ) =>
      this.request<PrivateShareDetailsDto, void>({
        path: `/private-sharing/${listId}/details`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns list of elements sorted by title ASC. Prices not displayed for role == BASIC_USER
     *
     * @tags privateSharing
     * @name GetPrivateShareElementsV2DeprecatedUsingGet
     * @summary Get shared list elements
     * @request GET:/private-sharing/{listId}/list-elements
     * @secure
     * @response `200` `PrivateShareElementsDetailsDto` Success
     * @response `464` `void` - when list was not shared with user or does not exist (PRIVATE_SHARE_USER_NOT_FOUND)
     */
    getPrivateShareElementsV2DeprecatedUsingGet: (
      listId: number,
      params: RequestParams = {},
    ) =>
      this.request<PrivateShareElementsDetailsDto, void>({
        path: `/private-sharing/${listId}/list-elements`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags privateSharing
     * @name ShareWithScpUsingPost
     * @summary shareWithScp
     * @request POST:/private-sharing/{listId}/scp-share
     * @secure
     * @response `200` `LongDto` Success
     * @response `409` `void` When user is sharing with himself
     * @response `460` `void` - When both userIds and companyIds are empty - When one of the users is not scp - When one of the users does not exist - When one of the scp users is not accepted
     * @response `464` `void` - When list does not exist or is deleted(PRODUCT_LIST_NOT_FOUND)
     */
    shareWithScpUsingPost: (
      listId: number,
      dto: ScpShareDataDto,
      params: RequestParams = {},
    ) =>
      this.request<LongDto, void>({
        path: `/private-sharing/${listId}/scp-share`,
        method: 'POST',
        body: dto,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags privateSharing
     * @name ShareUsingPost
     * @summary share
     * @request POST:/private-sharing/{listId}/share
     * @secure
     * @response `200` `LongDto` Success
     * @response `409` `void` When user is sharing with himself
     * @response `460` `void` Form validation failed
     * @response `464` `void` - When list does not exist or is deleted (PRODUCT_LIST_NOT_FOUND)
     */
    shareUsingPost: (
      listId: number,
      dto: ShareDataDto,
      params: RequestParams = {},
    ) =>
      this.request<LongDto, void>({
        path: `/private-sharing/${listId}/share`,
        method: 'POST',
        body: dto,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags privateSharing
     * @name CopyAsMyListUsingPost
     * @summary copyAsMyList
     * @request POST:/private-sharing/{sharedListId}/copy
     * @secure
     * @response `200` `CopiedProductListResponseDto` Success
     * @response `460` `void` Form validation failed
     * @response `464` `void` When list does not exist or is not shared for user (PRIVATE_SHARE_USER_NOT_FOUND)
     */
    copyAsMyListUsingPost: (
      sharedListId: number,
      dto: CopyShareDto,
      params: RequestParams = {},
    ) =>
      this.request<CopiedProductListResponseDto, void>({
        path: `/private-sharing/${sharedListId}/copy`,
        method: 'POST',
        body: dto,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  productLists = {
    /**
     * @description ## Default sorting: - lastUpdatedAt DESC ## Sorting fields: - title - createdAt - lastUpdatedAt - numberOfProducts (in case of the same number, last updatedAt DESC) - description (in case of the same description, last updatedAt DESC)
     *
     * @tags productLists
     * @name GetProductListsUsingGet
     * @summary Get product lists
     * @request GET:/product-lists/list
     * @secure
     * @response `200` `ResultListDtoOfProductListRowResponseDto` Success
     */
    getProductListsUsingGet: (
      query?: {
        asc?: boolean
        /**
         * Column name
         * @default "name"
         */
        sortBy?: string
        /**
         * Column direction
         * @default "asc"
         */
        sortOrder?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResultListDtoOfProductListRowResponseDto, any>({
        path: `/product-lists/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns list of lists sorted by title ASC
     *
     * @tags productLists
     * @name CreateListUsingPost
     * @summary Create new product list
     * @request POST:/product-lists/list
     * @secure
     * @response `200` `ProductListRowResponseDto` Success
     * @response `460` `void` Form validation failed
     */
    createListUsingPost: (
      body: ProductListRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<ProductListRowResponseDto, void>({
        path: `/product-lists/list`,
        method: 'POST',
        body: body,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns list of lists sorted by title ASC
     *
     * @tags productLists
     * @name DeleteListsUsingDelete
     * @summary Delete product lists
     * @request DELETE:/product-lists/list
     * @secure
     * @response `200` `ResultListDtoOfProductListRowResponseDto` Success
     * @response `409` `void` User attempts to delete default list
     * @response `460` `void` Form validation failed
     */
    deleteListsUsingDelete: (
      query: {
        ids: number[]
      },
      params: RequestParams = {},
    ) =>
      this.request<ResultListDtoOfProductListRowResponseDto, void>({
        path: `/product-lists/list`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags productLists
     * @name ChangeElementCrpUsingPut
     * @summary Change price of product on the list
     * @request PUT:/product-lists/list-elements/{elementId}/change-crp
     * @secure
     * @response `200` `void` Success
     * @response `400` `void` - when user has no permission for price change
     * @response `460` `void` Form validation failed
     * @response `464` `void` - when element does not belong to user or does not exist (PRODUCT_LIST_ELEMENT_NOT_FOUND)
     */
    changeElementCrpUsingPut: (
      elementId: number,
      query?: {
        /**
         * @min 0
         * @exclusiveMin false
         */
        value?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/product-lists/list-elements/${elementId}/change-crp`,
        method: 'PUT',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags productLists
     * @name ChangeElementCrpAndQuantityUsingPut
     * @summary Change price and quantity of product on the list
     * @request PUT:/product-lists/list-elements/{elementId}/change-crp-and-quantity
     * @secure
     * @response `200` `void` Success
     * @response `400` `void` - when user has no permission for price and quantity change
     * @response `409` `void` - when critical quantity exceeded - when quantity not multiply of delivery unit
     * @response `460` `void` Form validation failed
     * @response `464` `void` - when element does not belong to user or does not exist (PRODUCT_LIST_ELEMENT_NOT_FOUND)
     */
    changeElementCrpAndQuantityUsingPut: (
      elementId: number,
      query: {
        /**
         * @min 0
         * @exclusiveMin false
         */
        crp: number
        /**
         * @format int32
         * @min 1
         * @exclusiveMin false
         * @max 999999
         * @exclusiveMax false
         */
        quantity: number
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/product-lists/list-elements/${elementId}/change-crp-and-quantity`,
        method: 'PUT',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags productLists
     * @name ChangeElementQuantityUsingPut
     * @summary Change quantity of product on the list
     * @request PUT:/product-lists/list-elements/{elementId}/change-quantity
     * @secure
     * @response `200` `void` Success
     * @response `409` `void` - when critical quantity exceeded - when quantity not multiply of delivery unit
     * @response `460` `void` Form validation failed
     * @response `464` `void` - when element does not belong to user or does not exist (PRODUCT_LIST_ELEMENT_NOT_FOUND)
     */
    changeElementQuantityUsingPut: (
      elementId: number,
      query: {
        /**
         * @format int32
         * @min 1
         * @exclusiveMin false
         * @max 999999
         * @exclusiveMax false
         */
        value: number
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/product-lists/list-elements/${elementId}/change-quantity`,
        method: 'PUT',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags productLists
     * @name MoveElementsToAnotherListUsingPut
     * @summary Move products between lists
     * @request PUT:/product-lists/list-elements/{initialListId}/{targetListId}/move
     * @secure
     * @response `200` `void` Success
     * @response `400` `void` - when one of the elements to be moved is not on the initial list
     * @response `460` `void` - when ids is null - when one of the elements related product is already on the target list
     * @response `464` `void` - when one of the lists does not belong to user or does not exist (PRODUCT_LIST_NOT_FOUND)
     */
    moveElementsToAnotherListUsingPut: (
      initialListId: number,
      targetListId: number,
      move: MoveDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/product-lists/list-elements/${initialListId}/${targetListId}/move`,
        method: 'PUT',
        body: move,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns list of elements sorted by created at ASC. Prices not displayed for role == BASIC_USER
     *
     * @tags productLists
     * @name GetProductListElementsUsingGet
     * @summary Get product list elements - Deprecated
     * @request GET:/product-lists/list-elements/{listId}
     * @secure
     * @response `200` `ResultListDtoOfDisplayProductListElementResponseDto` Success
     * @response `464` `void` - when list does not belong to user or does not exist (PRODUCT_LIST_NOT_FOUND)
     */
    getProductListElementsUsingGet: (
      listId: number,
      params: RequestParams = {},
    ) =>
      this.request<ResultListDtoOfDisplayProductListElementResponseDto, void>({
        path: `/product-lists/list-elements/${listId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags productLists
     * @name AddProductToListUsingPost
     * @summary Add product to list
     * @request POST:/product-lists/list-elements/{listId}
     * @secure
     * @response `200` `DisplayProductListElementResponseDto` Success
     * @response `409` `void` - when product already on the list - when critical quantity exceeded
     * @response `460` `void` Form validation failed
     * @response `464` `void` - when list does not belong to user or does not exist (PRODUCT_LIST_NOT_FOUND) - when product does not exist (PRODUCT_NOT_FOUND)
     */
    addProductToListUsingPost: (
      listId: number,
      body: ProductListElementRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<DisplayProductListElementResponseDto, void>({
        path: `/product-lists/list-elements/${listId}`,
        method: 'POST',
        body: body,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags productLists
     * @name DeleteProductsFromListUsingDelete
     * @summary Delete products from list
     * @request DELETE:/product-lists/list-elements/{listId}
     * @secure
     * @response `200` `void` Success
     * @response `460` `void` Form validation failed
     */
    deleteProductsFromListUsingDelete: (
      listId: number,
      query: {
        ids: number[]
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/product-lists/list-elements/${listId}`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Returns product list elements by default sorted by custom order ASC, created at ASC and id ASC
     *
     * @tags productLists
     * @name UpdateProductListElementsCustomSortingUsingPut
     * @summary Updates user custom sorting for list elements
     * @request PUT:/product-lists/list-elements/{listId}/sorting/custom-sort
     * @secure
     * @response `200` `ListElementsDetailsDto` Success
     * @response `460` `void` Validation errors
     * @response `464` `void` - when list does not belong to user or does not exist (PRODUCT_LIST_NOT_FOUND)
     */
    updateProductListElementsCustomSortingUsingPut: (
      listId: number,
      orders: SortOrderRequestDto[],
      params: RequestParams = {},
    ) =>
      this.request<ListElementsDetailsDto, void>({
        path: `/product-lists/list-elements/${listId}/sorting/custom-sort`,
        method: 'PUT',
        body: orders,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns list of lists sorted by title ASC
     *
     * @tags productLists
     * @name EditListUsingPut
     * @summary Edit product list
     * @request PUT:/product-lists/list/{listId}
     * @secure
     * @response `200` `ResultListDtoOfProductListRowResponseDto` Success
     * @response `460` `void` Form validation failed
     * @response `464` `void` - list does not exist or belongs to another user (PRODUCT_LIST_NOT_FOUND)
     */
    editListUsingPut: (
      listId: number,
      body: ProductListRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<ResultListDtoOfProductListRowResponseDto, void>({
        path: `/product-lists/list/${listId}`,
        method: 'PUT',
        body: body,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns list of lists sorted by title ASC. Parameters 'favorite' and 'pinned' are not copied.
     *
     * @tags productLists
     * @name CopyListUsingPost
     * @summary Copy product list
     * @request POST:/product-lists/list/{listId}/copy
     * @secure
     * @response `200` `ProductListRowWithElementsResponseDto` Success
     * @response `464` `void` - list does not exist or belongs to another user (PRODUCT_LIST_NOT_FOUND)
     */
    copyListUsingPost: (listId: number, params: RequestParams = {}) =>
      this.request<ProductListRowWithElementsResponseDto, void>({
        path: `/product-lists/list/${listId}/copy`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns list of lists sorted by title ASC. Parameters 'favorite' and 'pinned' are not copied.
     *
     * @tags productLists
     * @name CopyListWithTitleAndDescriptionUsingPost
     * @summary Copy product list with title and description provided
     * @request POST:/product-lists/list/{listId}/copy-with-title
     * @secure
     * @response `200` `ProductListRowWithElementsResponseDto` Success
     * @response `460` `void` - title is not unique or null
     * @response `464` `void` - list does not exist or belongs to another user (PRODUCT_LIST_NOT_FOUND)
     */
    copyListWithTitleAndDescriptionUsingPost: (
      listId: number,
      dto: CopyProductListDto,
      params: RequestParams = {},
    ) =>
      this.request<ProductListRowWithElementsResponseDto, void>({
        path: `/product-lists/list/${listId}/copy-with-title`,
        method: 'POST',
        body: dto,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags productLists
     * @name DownloadPdfUsingGet
     * @summary Download product list as pdf
     * @request GET:/product-lists/list/{listId}/download-pdf
     * @secure
     * @response `200` `string` Success
     * @response `464` `void` - when list does not belong to user or does not exist (PRODUCT_LIST_NOT_FOUND)
     */
    downloadPdfUsingGet: (listId: number, params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/product-lists/list/${listId}/download-pdf`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags productLists
     * @name DownloadXlsxUsingGet
     * @summary Download product list as xlsx
     * @request GET:/product-lists/list/{listId}/download-xlsx
     * @secure
     * @response `200` `string` Success
     * @response `464` `void` - when list does not belong to user or does not exist (PRODUCT_LIST_NOT_FOUND)
     */
    downloadXlsxUsingGet: (
      listId: number,
      query?: {
        /**
         * includeFinancials
         * @default false
         */
        includeFinancials?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<string, void>({
        path: `/product-lists/list/${listId}/download-xlsx`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags productLists
     * @name GetPdfLinkUsingGet
     * @summary Get link to product list in pdf
     * @request GET:/product-lists/list/{listId}/pdf-link
     * @secure
     * @response `200` `StringDto` Success
     * @response `464` `void` - when list does not belong to user or does not exist (PRODUCT_LIST_NOT_FOUND)
     */
    getPdfLinkUsingGet: (listId: number, params: RequestParams = {}) =>
      this.request<StringDto, void>({
        path: `/product-lists/list/${listId}/pdf-link`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags productLists
     * @name PinProductListUsingPut
     * @summary Pin product list
     * @request PUT:/product-lists/list/{listId}/pin
     * @secure
     * @response `200` `void` Success
     * @response `464` `void` - when list does not belong to user or does not exist (PRODUCT_LIST_NOT_FOUND)
     */
    pinProductListUsingPut: (listId: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/product-lists/list/${listId}/pin`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags productLists
     * @name SendProductListUsingPost
     * @summary Send email with product list
     * @request POST:/product-lists/list/{listId}/send-email
     * @secure
     * @response `200` `void` Success
     * @response `460` `void` Form validtion failed
     * @response `464` `void` - when list does not belong to user or does not exist (PRODUCT_LIST_NOT_FOUND)
     * @response `500` `void` - exceptions regarding document creation
     */
    sendProductListUsingPost: (
      listId: number,
      body: EmailDataDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/product-lists/list/${listId}/send-email`,
        method: 'POST',
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags productLists
     * @name UnpinProductListUsingPut
     * @summary Unpin product list
     * @request PUT:/product-lists/list/{listId}/unpin
     * @secure
     * @response `200` `void` Success
     * @response `464` `void` - when list does not belong to user or does not exist (PRODUCT_LIST_NOT_FOUND)
     */
    unpinProductListUsingPut: (listId: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/product-lists/list/${listId}/unpin`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags productLists
     * @name GetXlsxLinkUsingGet1
     * @summary Get link to product list in xlsx
     * @request GET:/product-lists/list/{listId}/xlsx-link
     * @secure
     * @response `200` `StringDto` Success
     * @response `464` `void` - when list does not belong to user or does not exist (PRODUCT_LIST_NOT_FOUND)
     */
    getXlsxLinkUsingGet1: (
      listId: number,
      query?: {
        /**
         * includeFinancials
         * @default false
         */
        includeFinancials?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<StringDto, void>({
        path: `/product-lists/list/${listId}/xlsx-link`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags productLists
     * @name GetListDetailsUsingGet
     * @summary Get product list details
     * @request GET:/product-lists/{listId}
     * @secure
     * @response `200` `ProductListRowResponseDto` Success
     * @response `464` `void` - list does not exist or belongs to another user (PRODUCT_LIST_NOT_FOUND)
     */
    getListDetailsUsingGet: (listId: number, params: RequestParams = {}) =>
      this.request<ProductListRowResponseDto, void>({
        path: `/product-lists/${listId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns list of elements sorted by set sorting
     *
     * @tags productLists
     * @name UpdateProductListElementsSortParameterUsingPut
     * @summary Edit product list elements sorting
     * @request PUT:/product-lists/{listId}/list-elements/sorting
     * @secure
     * @response `200` `ListElementsDetailsDto` Success
     * @response `409` `void` - when sortParameter = CUSTOM and none of the product list elements has custom order set (CUSTOM_ORDER_NOT_SET)
     * @response `464` `void` - list does not exist or belongs to another user (PRODUCT_LIST_NOT_FOUND)
     */
    updateProductListElementsSortParameterUsingPut: (
      listId: number,
      requestDto: ListSortParameterRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<ListElementsDetailsDto, void>({
        path: `/product-lists/${listId}/list-elements/sorting`,
        method: 'PUT',
        body: requestDto,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  products = {
    /**
     * No description
     *
     * @tags products
     * @name GetProductAvailabilityIndicatorListUsingGet
     * @summary Returns product availability indicator list which fulfill searching criteria
     * @request GET:/products/availability-indicator
     * @secure
     * @response `200` `ResultPageWithTotalCountDtoOfProductAvailabilityIndicatorRowResponseDto` OK
     */
    getProductAvailabilityIndicatorListUsingGet: (
      query?: {
        indicatorTrafficLights?: 'AVAILABLE' | 'WARNING' | 'NOT_AVAILABLE'
        /**
         * Zero-based page index
         * @format int32
         * @default 0
         */
        page?: number
        phaseOutStatuses?:
          | 'AVAILABLE'
          | 'UPCOMING_PHASE_OUT'
          | 'PHASED_OUT'
          | 'BLOCKED'
          | 'NOT_AVAILABLE'
        /** @example "Lorem ipsum" */
        searchValue?: string
        showAvailabilityStatuses?:
          | 'SHIP_HOLD'
          | 'OUT_OF_STOCK'
          | 'LIMITED_QUANTITY'
          | 'AVAILABLE'
        /** @example true */
        showProductsWithCustomStatusOnly?: boolean
        /**
         * Page size
         * @format int32
         * @default 10
         */
        size?: number
        /**
         * Column name and direction, comma separated
         * @default "name,asc"
         */
        sort?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<
        ResultPageWithTotalCountDtoOfProductAvailabilityIndicatorRowResponseDto,
        any
      >({
        path: `/products/availability-indicator`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags products
     * @name GetProductsCartValuesUsingGet
     * @summary Returns values that are needed to display statuses in the cart
     * @request GET:/products/cart-values
     * @secure
     * @response `200` `OfflineProductsListDto` Success
     */
    getProductsCartValuesUsingGet: (params: RequestParams = {}) =>
      this.request<OfflineProductsListDto, any>({
        path: `/products/cart-values`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags products
     * @name GetDynamicFiltersUsingGet
     * @summary getDynamicFilters
     * @request GET:/products/dynamic-filters
     * @secure
     * @response `200` `ResultListDtoOfDynamicFilterDto` Success
     */
    getDynamicFiltersUsingGet: (params: RequestParams = {}) =>
      this.request<ResultListDtoOfDynamicFilterDto, any>({
        path: `/products/dynamic-filters`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags products
     * @name GetFilterMatchesUsingPost
     * @summary Returns number of matching records
     * @request POST:/products/filter-matches
     * @secure
     * @response `202` `FilterMatchesDto` Accepted
     */
    getFilterMatchesUsingPost: (
      dto: SearchAndFilterRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<FilterMatchesDto, any>({
        path: `/products/filter-matches`,
        method: 'POST',
        body: dto,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags products
     * @name GetProductsByIdsUsingGet
     * @summary Get products list by product ids. Sorted By title ASC
     * @request GET:/products/list
     * @secure
     * @response `200` `ResultListDtoOfProductRowResponseDto` Success
     */
    getProductsByIdsUsingGet: (
      query: {
        ids: number[]
      },
      params: RequestParams = {},
    ) =>
      this.request<ResultListDtoOfProductRowResponseDto, any>({
        path: `/products/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags products
     * @name GetProductsUsingPost
     * @summary Get product page. Sorted by product.default_sorting DESC
     * @request POST:/products/list
     * @secure
     * @response `200` `ResultPageWithTotalCountDtoOfProductRowResponseDto` Success
     */
    getProductsUsingPost: (
      dto: SearchAndFilterRequestDto,
      query?: {
        /**
         * Zero-based page index
         * @format int32
         * @default 0
         */
        page?: number
        /**
         * Page size
         * @format int32
         * @default 10
         */
        size?: number
        /**
         * Column name and direction, comma separated
         * @default "name,asc"
         */
        sort?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResultPageWithTotalCountDtoOfProductRowResponseDto, any>({
        path: `/products/list`,
        method: 'POST',
        query: query,
        body: dto,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags products
     * @name AddProductsToMultipleListsUsingPost
     * @summary Adds products to multiple lists
     * @request POST:/products/list-elements
     * @secure
     * @response `200` `MultipleProductsAdditionResponseDto` Success
     * @response `460` `void` Validation errors
     * @response `464` `void` - when one of the lists does not belong to user or does not exist (PRODUCT_LIST_NOT_FOUND, SHARE_DRAFT_NOT_FOUND, PRODUCT_NOT_FOUND)
     */
    addProductsToMultipleListsUsingPost: (
      requestDto: MultipleProductsAdditionRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<MultipleProductsAdditionResponseDto, void>({
        path: `/products/list-elements`,
        method: 'POST',
        body: requestDto,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags products
     * @name ProductCountersUsingGet
     * @summary Returns product categories counters
     * @request GET:/products/product-counters
     * @secure
     * @response `202` `ProductCountersResponseDto` Accepted
     */
    productCountersUsingGet: (params: RequestParams = {}) =>
      this.request<ProductCountersResponseDto, any>({
        path: `/products/product-counters`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags products
     * @name SendCartUsingPost
     * @summary Creates .xlsx file, store it on Microsoft Azure Storage and then send it via email
     * @request POST:/products/send-cart
     * @secure
     * @response `200` `void` Success
     * @response `202` `void` Accepted
     * @response `464` `void` - When currency does not exist (CURRENCY_EXCHANGE_NOT_FOUND) - When country does not exist (COUNTRY_NOT_FOUND)
     */
    sendCartUsingPost: (cartDto: CartDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/products/send-cart`,
        method: 'POST',
        body: cartDto,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags products
     * @name GetProductsListUsingGet
     * @summary New autocomplete. Sorted by title ASC. Current max result size: 5
     * @request GET:/products/v2/autocomplete
     * @secure
     * @response `200` `ResultListWithTotalCountDtoOfProductResponseDto` Success
     * @response `460` `ValidationExceptionResponseDto` Form validation failed
     */
    getProductsListUsingGet: (
      query: {
        search: string
      },
      params: RequestParams = {},
    ) =>
      this.request<
        ResultListWithTotalCountDtoOfProductResponseDto,
        ValidationExceptionResponseDto
      >({
        path: `/products/v2/autocomplete`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags products
     * @name VersionUsingGet
     * @summary Returns current version
     * @request GET:/products/version
     * @secure
     * @response `200` `StringDto` Success
     */
    versionUsingGet: (params: RequestParams = {}) =>
      this.request<StringDto, any>({
        path: `/products/version`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags products
     * @name AddProductToAvailabilityIndicatorUsingPost
     * @summary Add product to availability indicator
     * @request POST:/products/{productId}/availability-indicator
     * @secure
     * @response `200` `ProductAvailabilityIndicatorDictRowResponseDto` Success
     * @response `464` `void` Product not found
     */
    addProductToAvailabilityIndicatorUsingPost: (
      productId: number,
      params: RequestParams = {},
    ) =>
      this.request<ProductAvailabilityIndicatorDictRowResponseDto, void>({
        path: `/products/${productId}/availability-indicator`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags products
     * @name UpdateUsingPut
     * @summary Updates product availability indicator data and returns its new version
     * @request PUT:/products/{productId}/availability-indicator
     * @secure
     * @response `200` `ProductAvailabilityIndicatorDetailsResponseDto` Success
     * @response `409` `void` MISSING_GLOBAL_PRODUCT_AVAILABILITY_INDICATOR_DATA - missing data for global product availability indicator; GLOBAL_PRODUCT_AVAILABILITY_INDICATOR_DESCRIPTION_REQUIRED - missing description for global product availability indicator; MISSING_COUNTRY_PRODUCT_AVAILABILITY_INDICATOR_DATA - missing data for country product availability indicators; COUNTRY_PRODUCT_AVAILABILITY_INDICATOR_DESCRIPTION_REQUIRED - missing description for some of country product availability indicators; CANNOT_SET_MANY_PRODUCT_INDICATORS_FOR_ONE_COUNTRY - country can be assigned to only one status
     * @response `464` `void` Product not found
     */
    updateUsingPut: (
      productId: number,
      dto: ProductAvailabilityIndicatorUpdateRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<ProductAvailabilityIndicatorDetailsResponseDto, void>({
        path: `/products/${productId}/availability-indicator`,
        method: 'PUT',
        body: dto,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags products
     * @name DeleteUsingDelete
     * @summary Removes product from availability indicator list
     * @request DELETE:/products/{productId}/availability-indicator
     * @secure
     * @response `204` `void` Success
     * @response `464` `void` Product not found
     */
    deleteUsingDelete: (productId: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/products/${productId}/availability-indicator`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags products
     * @name DetailsUsingGet
     * @summary Returns product details
     * @request GET:/products/{productId}/details
     * @secure
     * @response `200` `ProductsPropertiesDetailsDto` Success
     * @response `202` `ProductsPropertiesDetailsDto` Accepted
     * @response `464` `void` - When product does not exist (PRODUCT_NOT_FOUND) - When user has no country set (COUNTRY_NOT_FOUND) - When user has no currency set (CURRENCY_EXCHANGE_NOT_FOUND)
     */
    detailsUsingGet: (productId: number, params: RequestParams = {}) =>
      this.request<ProductsPropertiesDetailsDto, void>({
        path: `/products/${productId}/details`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags products
     * @name GetProductAvailabilityIndicatorDetailsUsingGet
     * @summary Returns product availability indicator details
     * @request GET:/products/{productTitle}/availability-indicator
     * @secure
     * @response `200` `ProductAvailabilityIndicatorDetailsResponseDto` Success
     * @response `464` `void` Product not found
     */
    getProductAvailabilityIndicatorDetailsUsingGet: (
      productTitle: string,
      params: RequestParams = {},
    ) =>
      this.request<ProductAvailabilityIndicatorDetailsResponseDto, void>({
        path: `/products/${productTitle}/availability-indicator`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags products
     * @name DetailsByTitleUsingGet
     * @summary Returns product details
     * @request GET:/products/{productTitle}/details-by-title
     * @secure
     * @response `202` `ProductsPropertiesDetailsDto` Accepted
     */
    detailsByTitleUsingGet: (
      productTitle: string,
      params: RequestParams = {},
    ) =>
      this.request<ProductsPropertiesDetailsDto, any>({
        path: `/products/${productTitle}/details-by-title`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  report = {
    /**
     * No description
     *
     * @tags report
     * @name SendReportDataIssueUsingPost
     * @summary Sends email with report
     * @request POST:/report
     * @secure
     * @response `200` `void` Success
     * @response `460` `ValidationException` Form validation failed
     */
    sendReportDataIssueUsingPost: (
      body: ReportDataDto,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationException>({
        path: `/report`,
        method: 'POST',
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  }
  shareDraft = {
    /**
     * No description
     *
     * @tags shareDraft
     * @name AddProductToDraftUsingPost
     * @summary Add product to draft
     * @request POST:/share-draft/draft-elements/{draftId}
     * @secure
     * @response `200` `DisplayProductListElementResponseDto` Success
     * @response `409` `void` - when quantity not multiply of delivery unit - when product already added - when user currency different than draft currency
     * @response `460` `void` Form validation failed
     * @response `464` `void` - when draft does not exist or does not belong to user (SHARE_DRAFT_NOT_FOUND) - when product does not exist (PRODUCT_NOT_FOUND)
     */
    addProductToDraftUsingPost: (
      draftId: number,
      dto: ShareDraftElementDto,
      params: RequestParams = {},
    ) =>
      this.request<DisplayProductListElementResponseDto, void>({
        path: `/share-draft/draft-elements/${draftId}`,
        method: 'POST',
        body: dto,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags shareDraft
     * @name ChangeDraftElementCrpUsingPut
     * @summary Change price of product on the draft
     * @request PUT:/share-draft/draft-elements/{elementId}/change-crp
     * @secure
     * @response `200` `void` Success
     * @response `409` `void` When user is not set with one of draft countries
     * @response `460` `void` Form validation failed
     * @response `464` `void` When element does not belong to user or does not exist (SHARE_DRAFT_ELEMENT_NOT_FOUND)
     */
    changeDraftElementCrpUsingPut: (
      elementId: number,
      query?: {
        /**
         * @min 0
         * @exclusiveMin false
         */
        value?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/share-draft/draft-elements/${elementId}/change-crp`,
        method: 'PUT',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags shareDraft
     * @name ChangeDraftElementCrpAndQuantityUsingPut
     * @summary Change price and quantity of product on the draft
     * @request PUT:/share-draft/draft-elements/{elementId}/change-crp-and-quantity
     * @secure
     * @response `200` `void` Success
     * @response `409` `void` - When quantity not multiply of delivery unit - When user is not set with one of draft countries
     * @response `460` `void` Form validation failed
     * @response `464` `void` When element does not belong to user or does not exist (SHARE_DRAFT_ELEMENT_NOT_FOUND)
     */
    changeDraftElementCrpAndQuantityUsingPut: (
      elementId: number,
      query: {
        /**
         * @min 0
         * @exclusiveMin false
         */
        crp: number
        /**
         * @format int32
         * @min 1
         * @exclusiveMin false
         * @max 999999
         * @exclusiveMax false
         */
        quantity: number
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/share-draft/draft-elements/${elementId}/change-crp-and-quantity`,
        method: 'PUT',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags shareDraft
     * @name ChangeDraftElementQuantityUsingPut
     * @summary Change quantity of product on the draft
     * @request PUT:/share-draft/draft-elements/{elementId}/change-quantity
     * @secure
     * @response `200` `void` Success
     * @response `409` `void` - When quantity not multiply of delivery unit - When user is not set with one of draft countries
     * @response `460` `void` Form validation failed
     * @response `464` `void` When element does not belong to user or does not exist (SHARE_DRAFT_ELEMENT_NOT_FOUND)
     */
    changeDraftElementQuantityUsingPut: (
      elementId: number,
      query: {
        /**
         * @format int32
         * @min 1
         * @exclusiveMin false
         * @max 999999
         * @exclusiveMax false
         */
        value: number
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/share-draft/draft-elements/${elementId}/change-quantity`,
        method: 'PUT',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description ## Default sorting: - lastUpdatedAt DESC -> id DESC ## Sorting fields: - title -> last updatedAt DESC -> id DESC - createdAt -> last updatedAt DESC -> id DESC - lastUpdatedAt -> id DESC - numberOfProducts -> last updatedAt DESC -> id DESC - description -> last updatedAt DESC -> id DESC
     *
     * @tags shareDraft
     * @name GetShareDraftsUsingGet
     * @summary Get drafts
     * @request GET:/share-draft/list
     * @secure
     * @response `200` `ResultListDtoOfShareDraftDto` Success
     */
    getShareDraftsUsingGet: (
      query?: {
        asc?: boolean
        /**
         * Column name
         * @default "name"
         */
        sortBy?: string
        /**
         * Column direction
         * @default "asc"
         */
        sortOrder?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResultListDtoOfShareDraftDto, any>({
        path: `/share-draft/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags shareDraft
     * @name RemoveShareDraftUsingDelete
     * @summary Remove draft
     * @request DELETE:/share-draft/{draftId}
     * @secure
     * @response `200` `void` Success
     * @response `409` `void` When user is not set with one of draft countries
     * @response `464` `void` When given draftId does not exist for user (SHARE_DRAFT_NOT_FOUND)
     */
    removeShareDraftUsingDelete: (
      draftId: number,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/share-draft/${draftId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags shareDraft
     * @name GetDraftDetailsUsingGet
     * @summary Get draft details
     * @request GET:/share-draft/{draftId}/details
     * @secure
     * @response `200` `ShareDraftDetailsDto` Success
     * @response `409` `void` When user is not set with one of draft countries
     * @response `464` `void` When given draftId does not exist for user (SHARE_DRAFT_NOT_FOUND)
     */
    getDraftDetailsUsingGet: (draftId: number, params: RequestParams = {}) =>
      this.request<ShareDraftDetailsDto, void>({
        path: `/share-draft/${draftId}/details`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags shareDraft
     * @name DownloadDraftPdfUsingGet
     * @summary Download draft as pdf
     * @request GET:/share-draft/{draftId}/download-pdf
     * @secure
     * @response `200` `string` Success
     * @response `409` `void` When user is not set with one of draft countries
     * @response `464` `void` When given draftId does not exist for user (SHARE_DRAFT_NOT_FOUND)
     */
    downloadDraftPdfUsingGet: (draftId: number, params: RequestParams = {}) =>
      this.request<string, void>({
        path: `/share-draft/${draftId}/download-pdf`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags shareDraft
     * @name DownloadDraftXlsxUsingGet
     * @summary Download draft as xlsx
     * @request GET:/share-draft/{draftId}/download-xlsx
     * @secure
     * @response `200` `string` Success
     * @response `409` `void` When user is not set with one of draft countries
     * @response `464` `void` When given draftId does not exist for user (SHARE_DRAFT_NOT_FOUND)
     */
    downloadDraftXlsxUsingGet: (
      draftId: number,
      query?: {
        /**
         * includeFinancials
         * @default false
         */
        includeFinancials?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<string, void>({
        path: `/share-draft/${draftId}/download-xlsx`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags shareDraft
     * @name DeleteElementsFromDraftUsingDelete
     * @summary Delete elements from draft
     * @request DELETE:/share-draft/{draftId}/draft-elements
     * @secure
     * @response `200` `void` Success
     * @response `409` `void` When user is not set with one of draft countries
     * @response `460` `void` Form validation failed
     */
    deleteElementsFromDraftUsingDelete: (
      draftId: number,
      query: {
        ids: number[]
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/share-draft/${draftId}/draft-elements`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Returns list of elements sorted by set sorting
     *
     * @tags shareDraft
     * @name UpdateShareDraftElementsSortParameterUsingPut
     * @summary Edit share draft elements sorting
     * @request PUT:/share-draft/{draftId}/draft-elements/sorting
     * @secure
     * @response `200` `ShareDraftDetailsDto` Success
     * @response `409` `void` - when sortParameter = CUSTOM and none of the product list elements has custom order set (CUSTOM_ORDER_NOT_SET) - when there is draft country and user country mismatch (DRAFT_COUNTRY_MISMATCH)
     * @response `464` `void` - list does not exist or belongs to another user (SHARE_DRAFT_NOT_FOUND)
     */
    updateShareDraftElementsSortParameterUsingPut: (
      draftId: number,
      requestDto: ListSortParameterRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<ShareDraftDetailsDto, void>({
        path: `/share-draft/${draftId}/draft-elements/sorting`,
        method: 'PUT',
        body: requestDto,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags shareDraft
     * @name EditDraftUsingPut
     * @summary Edit draft title and description
     * @request PUT:/share-draft/{draftId}/edit
     * @secure
     * @response `200` `void` Success
     * @response `409` `void` When user is not set with one of draft countries
     * @response `460` `void` Form validation failed
     * @response `464` `void` When given draftId does not exist for user (SHARE_DRAFT_NOT_FOUND)
     */
    editDraftUsingPut: (
      draftId: number,
      dto: TitleAndDescriptionDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/share-draft/${draftId}/edit`,
        method: 'PUT',
        body: dto,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags shareDraft
     * @name GetDraftPdfLinkUsingGet
     * @summary Get link to draft in pdf
     * @request GET:/share-draft/{draftId}/pdf-link
     * @secure
     * @response `200` `StringDto` Success
     * @response `409` `void` When user is not set with one of draft countries
     * @response `464` `void` When given draftId does not exist for user (SHARE_DRAFT_NOT_FOUND)
     */
    getDraftPdfLinkUsingGet: (draftId: number, params: RequestParams = {}) =>
      this.request<StringDto, void>({
        path: `/share-draft/${draftId}/pdf-link`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags shareDraft
     * @name PublishDraftUsingPost
     * @summary Publish draft
     * @request POST:/share-draft/{draftId}/publish-draft
     * @secure
     * @response `200` `void` Success
     * @response `409` `void` - When share was edited after draft had started - When countries are not subset of original share country set - When user is not set with one of draft countries
     * @response `460` `void` Form validation failed
     * @response `464` `void` When given draftId does not exist for user (SHARE_DRAFT_NOT_FOUND)
     */
    publishDraftUsingPost: (
      draftId: number,
      dto: ShareDraftPublishDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/share-draft/${draftId}/publish-draft`,
        method: 'POST',
        body: dto,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags shareDraft
     * @name UpdateEditDateUsingPut
     * @summary Update edit date
     * @request PUT:/share-draft/{draftId}/update-edit-date
     * @secure
     * @response `200` `void` Success
     * @response `409` `void` When user is not set with one of draft countries
     * @response `464` `void` When given draftId does not exist for user (SHARE_DRAFT_NOT_FOUND)
     */
    updateEditDateUsingPut: (draftId: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/share-draft/${draftId}/update-edit-date`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags shareDraft
     * @name GetDraftXlsxLinkUsingGet
     * @summary Get link to draft in xlsx
     * @request GET:/share-draft/{draftId}/xlsx-link
     * @secure
     * @response `200` `StringDto` Success
     * @response `409` `void` When user is not set with one of draft countries
     * @response `464` `void` When given draftId does not exist for user (SHARE_DRAFT_NOT_FOUND)
     */
    getDraftXlsxLinkUsingGet: (
      draftId: number,
      query?: {
        /**
         * includeFinancials
         * @default false
         */
        includeFinancials?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<StringDto, void>({
        path: `/share-draft/${draftId}/xlsx-link`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns share draft elements by default sorted by custom order ASC, created at ASC and id ASC
     *
     * @tags shareDraft
     * @name UpdateShareDraftElementsCustomSortUsingPut
     * @summary Updates user custom sorting for draft elements
     * @request PUT:/share-draft/{listId}/draft-elements/sorting/custom-sort
     * @secure
     * @response `200` `ShareDraftDetailsDto` Success
     * @response `409` `void` When user is not set with one of draft countries
     * @response `460` `void` Form validation failed
     * @response `464` `void` - when draft does not belong to user or does not exist (SHARE_DRAFT_NOT_FOUND)
     */
    updateShareDraftElementsCustomSortUsingPut: (
      listId: number,
      orders: SortOrderRequestDto[],
      params: RequestParams = {},
    ) =>
      this.request<ShareDraftDetailsDto, void>({
        path: `/share-draft/${listId}/draft-elements/sorting/custom-sort`,
        method: 'PUT',
        body: orders,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags shareDraft
     * @name StartDraftUsingPost
     * @summary Start draft
     * @request POST:/share-draft/{shareId}/start-draft
     * @secure
     * @response `200` `ShareDraftDetailsDto` Success
     * @response `409` `void` - When KEY_USER has no permission in one of country share countries - When user is not set with one of draft countries
     * @response `464` `void` When country share does not exist (COUNTRY_SHARE_NOT_FOUND)
     */
    startDraftUsingPost: (shareId: number, params: RequestParams = {}) =>
      this.request<ShareDraftDetailsDto, void>({
        path: `/share-draft/${shareId}/start-draft`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  tableImportStatuses = {
    /**
     * No description
     *
     * @tags tableImportStatus
     * @name FindLatestUsingGet
     * @summary Returns list of latest table import statuses
     * @request GET:/table-import-statuses
     * @secure
     * @response `200` `ResultListDtoOfTableImportStatusResponseDto` OK
     */
    findLatestUsingGet: (params: RequestParams = {}) =>
      this.request<ResultListDtoOfTableImportStatusResponseDto, any>({
        path: `/table-import-statuses`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tableImportStatus
     * @name RunAllTableImportersUsingPost
     * @summary Run fetching data from all tables
     * @request POST:/table-import-statuses
     * @secure
     * @response `200` `void` OK
     */
    runAllTableImportersUsingPost: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/table-import-statuses`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags tableImportStatus
     * @name FindSynchronizationStatusUsingGet
     * @summary Returns list of latest table import statuses
     * @request GET:/table-import-statuses/synchronization
     * @secure
     * @response `200` `TableImportStatusSynchronizationResponseDto` OK
     */
    findSynchronizationStatusUsingGet: (params: RequestParams = {}) =>
      this.request<TableImportStatusSynchronizationResponseDto, any>({
        path: `/table-import-statuses/synchronization`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tableImportStatus
     * @name FindTableHistoryUsingGet
     * @summary Returns history of given table imports for last 30 days
     * @request GET:/table-import-statuses/{tableName}
     * @secure
     * @response `200` `ResultListDtoOfTableImportStatusDetailsResponseDto` OK
     */
    findTableHistoryUsingGet: (tableName: string, params: RequestParams = {}) =>
      this.request<ResultListDtoOfTableImportStatusDetailsResponseDto, any>({
        path: `/table-import-statuses/${tableName}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tableImportStatus
     * @name RunTableImporterUsingPost
     * @summary Run fetching data from given table
     * @request POST:/table-import-statuses/{tableName}
     * @secure
     * @response `200` `void` OK
     */
    runTableImporterUsingPost: (
      tableName: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/table-import-statuses/${tableName}`,
        method: 'POST',
        secure: true,
        ...params,
      }),
  }
  test = {
    /**
     * No description
     *
     * @tags test
     * @name GetLogsUsingGet
     * @summary getLogs
     * @request GET:/test/logs
     * @response `200` `void` OK
     */
    getLogsUsingGet: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/test/logs`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags test
     * @name SendTestPushUsingGet
     * @summary Test push
     * @request GET:/test/send-test-push
     * @response `200` `void` Success
     */
    sendTestPushUsingGet: (
      query: {
        /** language */
        language: string
        /** type */
        type:
          | 'SYNCHRONIZATION_REQUIRED'
          | 'PRIVATE_LIST_SHARED'
          | 'PUBLIC_LIST_UPDATED'
          | 'LANGUAGE_UPDATED'
        /**
         * userId
         * @format int64
         */
        userId: number
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/test/send-test-push`,
        method: 'GET',
        query: query,
        ...params,
      }),
  }
  user = {
    /**
     * @description Set new FCM registration ID for current login session
     *
     * @tags userProfile
     * @name SetFcmRegistrationIdUsingPost
     * @summary Set new FCM registration ID
     * @request POST:/user/fcm-registration-id
     * @secure
     * @response `202` `void` Success
     */
    setFcmRegistrationIdUsingPost: (
      body: FcmRegistrationIdDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/user/fcm-registration-id`,
        method: 'POST',
        body: body,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags userProfile
     * @name EditFirebaseConsentAcceptedUsingPatch
     * @summary Update has mobile firebase consent accepted
     * @request PATCH:/user/has-mobile-firebase-consent-accepted
     * @secure
     * @response `200` `DisplayUserProfileResponseDto` Success
     */
    editFirebaseConsentAcceptedUsingPatch: (
      requestDto: BooleanRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<DisplayUserProfileResponseDto, any>({
        path: `/user/has-mobile-firebase-consent-accepted`,
        method: 'PATCH',
        body: requestDto,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags userProfile
     * @name EditFirebaseConsentUsingPatch
     * @summary Update flat firebase consent has been seen
     * @request PATCH:/user/has-seen-mobile-firebase-consent
     * @secure
     * @response `200` `DisplayUserProfileResponseDto` Success
     */
    editFirebaseConsentUsingPatch: (
      requestDto: BooleanRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<DisplayUserProfileResponseDto, any>({
        path: `/user/has-seen-mobile-firebase-consent`,
        method: 'PATCH',
        body: requestDto,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags userProfile
     * @name EditLanguageUsingPut
     * @summary Edit user language.
     * @request PUT:/user/language
     * @secure
     * @response `200` `DisplayUserProfileResponseDto` Success
     * @response `460` `ValidationExceptionResponseDto` Form validation failed
     */
    editLanguageUsingPut: (
      dto: EditLanguageRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<
        DisplayUserProfileResponseDto,
        ValidationExceptionResponseDto
      >({
        path: `/user/language`,
        method: 'PUT',
        body: dto,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags userProfile
     * @name GetLanguagesUsingGet
     * @summary Get languages
     * @request GET:/user/language/list
     * @secure
     * @response `200` `(LanguageResponseDto)[]` Success
     */
    getLanguagesUsingGet: (params: RequestParams = {}) =>
      this.request<LanguageResponseDto[], any>({
        path: `/user/language/list`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags organizationRole
     * @name GetAllOrganizationRolesUsingGet
     * @summary Get organization roles list based on user role
     * @request GET:/user/organization-role/list
     * @secure
     * @response `200` `OrganizationRoleListResponseDto` Success
     */
    getAllOrganizationRolesUsingGet: (params: RequestParams = {}) =>
      this.request<OrganizationRoleListResponseDto, any>({
        path: `/user/organization-role/list`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags userProfile
     * @name GetUserProfileUsingGet
     * @summary Get user profile. Separator not displayed when role == BASIC_USER
     * @request GET:/user/profile
     * @secure
     * @response `200` `DisplayUserProfileResponseDto` Success
     */
    getUserProfileUsingGet: (params: RequestParams = {}) =>
      this.request<DisplayUserProfileResponseDto, any>({
        path: `/user/profile`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags userProfile
     * @name EditUserProfileUsingPut
     * @summary Edit user profile. Separator not saved for role == BASIC_USER. Returns user profile. Separator not displayed when role == BASIC_USER
     * @request PUT:/user/profile
     * @secure
     * @response `200` `DisplayUserProfileResponseDto` Success
     * @response `460` `ValidationExceptionResponseDto` Form validation failed
     * @response `464` `void` When language not found (LANGUAGE_NOT_FOUND)
     */
    editUserProfileUsingPut: (
      dto: BaseUserProfileDto,
      params: RequestParams = {},
    ) =>
      this.request<
        DisplayUserProfileResponseDto,
        ValidationExceptionResponseDto | void
      >({
        path: `/user/profile`,
        method: 'PUT',
        body: dto,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags userProfile
     * @name EditScpUserProfileUsingPut
     * @summary Edit scp user profile.
     * @request PUT:/user/scp-profile
     * @secure
     * @response `200` `DisplayUserProfileResponseDto` Success
     * @response `460` `ValidationExceptionResponseDto` Form validation failed
     */
    editScpUserProfileUsingPut: (
      dto: EditScpUserProfileRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<
        DisplayUserProfileResponseDto,
        ValidationExceptionResponseDto
      >({
        path: `/user/scp-profile`,
        method: 'PUT',
        body: dto,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags userProfile
     * @name GetSubregionsWithPermissionUsingGet
     * @summary Get list of subregions in which user is KEY_USER
     * @request GET:/user/user-subregion-permissions
     * @secure
     * @response `200` `ResultListDtoOfSubregionDto` Success
     */
    getSubregionsWithPermissionUsingGet: (params: RequestParams = {}) =>
      this.request<ResultListDtoOfSubregionDto, any>({
        path: `/user/user-subregion-permissions`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name ChangeOriginScpCountryUsingPatch
     * @summary changeOriginScpCountry
     * @request PATCH:/user/{userId}/origin-scp-country
     * @response `200` `void` OK
     */
    changeOriginScpCountryUsingPatch: (
      userId: number,
      dto: ChangeOriginScpCountryRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/user/${userId}/origin-scp-country`,
        method: 'PATCH',
        body: dto,
        type: ContentType.Json,
        ...params,
      }),
  }
  users = {
    /**
     * @description Returns list of users ordered by username asc. Caller is excluded from results
     *
     * @tags users
     * @name AutocompleteUsingGet
     * @summary Get users autocomplete
     * @request GET:/users/autocomplete
     * @secure
     * @response `200` `ResultListDtoOfUserAutocompleteDto` Success
     */
    autocompleteUsingGet: (
      query: {
        search: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResultListDtoOfUserAutocompleteDto, any>({
        path: `/users/autocomplete`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UsersCountries
     * @name AssignCountryByIdUsingPatch
     * @summary Setting current user country by country id
     * @request PATCH:/users/countries/{countryId}
     * @secure
     * @response `200` `UserResponseDto` Success
     * @response `409` `void` When country already assigned
     */
    assignCountryByIdUsingPatch: (
      countryId: number,
      params: RequestParams = {},
    ) =>
      this.request<UserResponseDto, void>({
        path: `/users/countries/${countryId}`,
        method: 'PATCH',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name DemoteAllUsingDelete
     * @summary Demote role to BASIC_USER in all countries
     * @request DELETE:/users/demote-all/{userId}
     * @secure
     * @response `200` `void` OK
     * @response `400` `void` - KEY_USER tries to assign role in country without permission in this country - KEY_USER tries to edit user with role that is not in his range of visibility. Range for KEY_USER: ADVANCED_USER, ADVANCED_USER_PLUS, BASIC_USER
     * @response `464` `void` User not found (USER_NOT_FOUND)
     */
    demoteAllUsingDelete: (userId: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/users/demote-all/${userId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Returns all external users as list
     *
     * @tags users
     * @name GetExternalUsersUsingGet
     * @summary Get external user accounts list
     * @request GET:/users/externals
     * @secure
     * @response `200` `ResultListDtoOfExternalUserDto` Success
     */
    getExternalUsersUsingGet: (params: RequestParams = {}) =>
      this.request<ResultListDtoOfExternalUserDto, any>({
        path: `/users/externals`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns all users as list
     *
     * @tags users
     * @name GetInternalUsersUsingGet
     * @summary Get users accounts list
     * @request GET:/users/internals
     * @secure
     * @response `200` `ResultListDtoOfUserListRowDto` Success
     */
    getInternalUsersUsingGet: (params: RequestParams = {}) =>
      this.request<ResultListDtoOfUserListRowDto, any>({
        path: `/users/internals`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Possible sortBy values = lastSeenAt, username, email, scpStatus, company, id, organizationRole, subregion, country Possible sortOrder values = asc, desc
     *
     * @tags users
     * @name DownloadExternalsCsvUsingGet
     * @summary Download external user list in csv
     * @request GET:/users/list/download-externals-csv
     * @secure
     * @response `200` `void` Success
     */
    downloadExternalsCsvUsingGet: (
      query?: {
        asc?: boolean
        /**
         * Column name
         * @default "name"
         */
        sortBy?: string
        /**
         * Column direction
         * @default "asc"
         */
        sortOrder?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/users/list/download-externals-csv`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Possible sortBy values = lastActivity, username. Possible sortOrder values = asc, desc
     *
     * @tags users
     * @name DownloadCsvUsingGet
     * @summary Download user list in csv
     * @request GET:/users/list/download-internals-csv
     * @secure
     * @response `200` `void` Success
     */
    downloadCsvUsingGet: (
      query?: {
        asc?: boolean
        /**
         * Column name
         * @default "name"
         */
        sortBy?: string
        /**
         * Column direction
         * @default "asc"
         */
        sortOrder?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/users/list/download-internals-csv`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Returns list of scp users ordered by username asc. Caller
     *
     * @tags users
     * @name ScpAutocompleteUsingGet
     * @summary Get scp users autocomplete
     * @request GET:/users/scp-autocomplete
     * @secure
     * @response `200` `ResultListDtoOfScpUserAutocompleteDto` Success
     */
    scpAutocompleteUsingGet: (
      query: {
        search: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ResultListDtoOfScpUserAutocompleteDto, any>({
        path: `/users/scp-autocomplete`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name ConfirmScpUserUsingPatch
     * @summary Confirm scp user
     * @request PATCH:/users/scp/{userId}/confirm
     * @secure
     * @response `200` `void` OK
     */
    confirmScpUserUsingPatch: (
      userId: number,
      dto: ScpUserConfirmationRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/users/scp/${userId}/confirm`,
        method: 'PATCH',
        body: dto,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Permitted for ADMIN users only
     *
     * @tags users
     * @name UpgradeToAdminUsingPut
     * @summary Upgrade user to admin
     * @request PUT:/users/upgrade-to-admin/{userId}
     * @secure
     * @response `200` `void` OK
     * @response `464` `void` User not found (USER_NOT_FOUND)
     */
    upgradeToAdminUsingPut: (userId: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/users/upgrade-to-admin/${userId}`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * @description - if user is BASIC_USER in particular country, it will not be visible on the list
     *
     * @tags users
     * @name GetEditInfoUsingGet
     * @summary Get user
     * @request GET:/users/{userId}
     * @secure
     * @response `200` `UserEditInfoResponseDto` Success
     * @response `400` `void` KEY_USER tries to fetch user with role that is not visible to him
     * @response `464` `void` User not found (USER_NOT_FOUND)
     */
    getEditInfoUsingGet: (userId: number, params: RequestParams = {}) =>
      this.request<UserEditInfoResponseDto, void>({
        path: `/users/${userId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UpdateUsingPut1
     * @summary Update user data
     * @request PUT:/users/{userId}
     * @secure
     * @response `200` `UserEditInfoResponseDto` Success; returns user details and send silent PUSH notification if account is disabled
     * @response `400` `void` KEY_USER tries to update user data in incorrect way (change role to admin, update admin account, set role which is disabled for KEY_USER, update user with role which is uneditable/ungrantable for KEY_USER
     * @response `403` `void` User does not have permissions to update user data
     * @response `409` `void` User tries to set more than one role for the same country
     * @response `460` `void` Validation errors (set admin and non-admin roles in parallel, set external role, set non-admin role without assigned country or with country which does not exist)
     * @response `464` `void` User not found (USER_NOT_FOUND)
     */
    updateUsingPut1: (
      userId: number,
      dto: UserUpdateRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<UserEditInfoResponseDto, void>({
        path: `/users/${userId}`,
        method: 'PUT',
        body: dto,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name DeleteByUserIdUsingDelete
     * @summary Soft delete user data
     * @request DELETE:/users/{userId}
     * @secure
     * @response `202` `void` Success, user deleted
     * @response `403` `void` User does not have permissions to update user data
     * @response `409` `void` Admin user cannot delete another admin
     * @response `464` `void` User not found (USER_NOT_FOUND)
     */
    deleteByUserIdUsingDelete: (userId: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/users/${userId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Returns user details
     *
     * @tags users
     * @name ChangeEnableUsingPut
     * @summary Change enable flag
     * @request PUT:/users/{userId}/change-enable
     * @secure
     * @response `200` `UserEditInfoResponseDto` Success
     * @response `464` `void` User not found (USER_NOT_FOUND)
     */
    changeEnableUsingPut: (
      userId: number,
      enable: BooleanRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<UserEditInfoResponseDto, void>({
        path: `/users/${userId}/change-enable`,
        method: 'PUT',
        body: enable,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description - Returns user details - Empty list means BASIC_USER in every country
     *
     * @tags users
     * @name EditUserUsingPut
     * @summary Edit user non admin roles
     * @request PUT:/users/{userId}/change-roles
     * @secure
     * @response `200` `UserEditInfoResponseDto` Success
     * @response `400` `void` - KEY_USER tries to assign role in country without permission in this country - KEY_USER tries to edit user with role that is not in his range of visibility. Range for KEY_USER: ADVANCED_USER, ADVANCED_USER_PLUS, BASIC_USER - KEY_USER tries to assign role that is not in his range of visibility
     * @response `409` `void` Country ids are repeating
     * @response `460` `void` Country id does not exist or role is not assignable. Assignable roles: ADVANCED_USER, ADVANCED_USER_PLUS, KEY_USER
     * @response `464` `void` User not found (USER_NOT_FOUND)
     */
    editUserUsingPut: (
      userId: number,
      body: EditUserRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<UserEditInfoResponseDto, void>({
        path: `/users/${userId}/change-roles`,
        method: 'PUT',
        body: body,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  v2 = {
    /**
     * @description Returns list of elements sorted by title ASC. Prices not displayed for role == BASIC_USER
     *
     * @tags countrySharing
     * @name GetCountryShareElementsV2DeprecatedUsingGet
     * @summary Get shared list elements v2
     * @request GET:/v2/country-sharing/{listId}/list-elements
     * @secure
     * @response `200` `CountryShareElementsDetailsDto` Success
     * @response `464` `void` When share does not exist or is unpinned and country does not match (COUNTRY_SHARE_COUNTRY_NOT_FOUND)
     */
    getCountryShareElementsV2DeprecatedUsingGet: (
      listId: number,
      params: RequestParams = {},
    ) =>
      this.request<CountryShareElementsDetailsDto, void>({
        path: `/v2/country-sharing/${listId}/list-elements`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description After success pull all product lists
     *
     * @tags offline
     * @name SyncV2UsingPost
     * @summary Synchronize product lists v2
     * @request POST:/v2/offline/sync
     * @secure
     * @response `200` `SynchronizeResultResponseDto` Success
     * @response `460` `void` Form validation failed
     */
    syncV2UsingPost: (dto: SynchronizeRequestDto, params: RequestParams = {}) =>
      this.request<SynchronizeResultResponseDto, void>({
        path: `/v2/offline/sync`,
        method: 'POST',
        body: dto,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns list of elements sorted by title ASC. Prices not displayed for role == BASIC_USER
     *
     * @tags privateSharing
     * @name GetPrivateShareElementsV2UsingGet
     * @summary Get shared list elements
     * @request GET:/v2/private-sharing/{listId}/list-elements
     * @secure
     * @response `200` `PrivateShareElementsDetailsDto` Success
     * @response `464` `void` - when list was not shared with user or does not exist (PRIVATE_SHARE_USER_NOT_FOUND)
     */
    getPrivateShareElementsV2UsingGet: (
      listId: number,
      params: RequestParams = {},
    ) =>
      this.request<PrivateShareElementsDetailsDto, void>({
        path: `/v2/private-sharing/${listId}/list-elements`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns list of elements sorted by title ASC. Prices not displayed for role == BASIC_USER
     *
     * @tags productLists
     * @name GetProductListElementsV2UsingGet
     * @summary Get product list elements
     * @request GET:/v2/product-lists/list-elements/{listId}
     * @secure
     * @response `200` `ListElementsDetailsDto` Success
     * @response `464` `void` - when list does not belong to user or does not exist (PRODUCT_LIST_NOT_FOUND)
     */
    getProductListElementsV2UsingGet: (
      listId: number,
      params: RequestParams = {},
    ) =>
      this.request<ListElementsDetailsDto, void>({
        path: `/v2/product-lists/list-elements/${listId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  v3 = {
    /**
     * No description
     *
     * @tags products
     * @name ProductCountersV3UsingGet
     * @summary Returns product categories counters
     * @request GET:/v3/products/counters
     * @secure
     * @response `202` `ProductCountersResponseDto` Accepted
     */
    productCountersV3UsingGet: (params: RequestParams = {}) =>
      this.request<ProductCountersResponseDto, any>({
        path: `/v3/products/counters`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
}
