import { API } from '@/api'

export const maxTitleLength = 200

export const maxMessageLength = 250

export type DropdownTypes = 'autocomplete' | 'history' | 'subregion' | 'country'

export enum ShareDialogType {
  INDIVIDUAL = 'INDIVIDUAL',
  COUNTRY = 'COUNTRY',
  SCP_USER = 'SCP_USER',
}

export const DropdownsRecordTypes: Record<DropdownTypes, string> = {
  autocomplete: 'autocomplete',
  history: 'history',
  subregion: 'subregion',
  country: 'country',
}

export const dropdownAutocomplete: DropdownTypes = 'autocomplete'

export type SharingOptionsItem = API.UserAutocompleteDto | API.CountryDto

export type SharingOptionsArray = API.UserAutocompleteDto[] | API.CountryDto[]

export type AllDropdownItems = {
  country?: string
  id?: number
  title?: string
  adId: string
  username?: string
}
