import { ROUTER_CONFIG } from '@/routes/router.config'
import { ErrorCodes } from '@/types'
import { NavigationGuardNext, Router } from 'vue-router'

export const navigateNextToErrors = (
  next: NavigationGuardNext,
  errorCode: ErrorCodes,
) => {
  next({
    ...ROUTER_CONFIG.errors,
    query: {
      code: errorCode,
    },
  })
}

export const findRouteByName = (routeName: string) => {
  return Object.values(ROUTER_CONFIG).find((route) => {
    return route.name === routeName
  })
}

export const goToExternalUsersList = async (router: Router) => {
  await router.push({
    name: ROUTER_CONFIG.users.name,
    query: { type: 'external' },
  })
}
