import { ProductGgpData } from '@/domain/product'

type GgpStatus = 'red' | 'yellow' | 'green' | null
type GgpTooltipText = 'good' | 'acceptable' | 'low' | 'missing'
type Nil = null | undefined

export type GgpData = {
  isValid: boolean
  percentage: string
  status: GgpStatus
  tooltipText?: GgpTooltipText
}

const INVALID_GGP_PERCENTAGE = ' - %'

function getGgpPercentage(crp: number | Nil, ggpUnitPrice: number | Nil) {
  if (crp == null || crp === 0 || ggpUnitPrice == null) {
    return null
  }
  const rawGGP = ((crp - (ggpUnitPrice ?? 0)) / crp) * 100

  return rawGGP >= 0 ? `${Math.round(rawGGP)}%` : null
}

function getGgpStatus(
  crp: number | Nil,
  lowerLimit: number | Nil,
  upperLimit: number | Nil,
) {
  if (crp == null || lowerLimit == null || upperLimit == null) {
    return null
  }

  if (crp < lowerLimit) {
    return 'red'
  }

  return crp > upperLimit ? 'green' : 'yellow'
}

function getGgpTooltipText(
  crp: number | Nil,
  lowerLimit: number | Nil,
  upperLimit: number | Nil,
) {
  if (crp == null || crp === 0 || lowerLimit == null || upperLimit == null) {
    return 'missing'
  }
  if (crp < lowerLimit) {
    return 'low'
  }

  return crp > upperLimit ? 'good' : 'acceptable'
}

type TotalOfKey = 'crp' | 'ggpUnitPrice' | 'lowerLimit' | 'upperLimit'

function getTotalOf(products: readonly ProductGgpData[], key: TotalOfKey) {
  return products.reduce((acc, product) => {
    return acc + (product.quantity ?? 0) * (product[key] ?? 0)
  }, 0)
}

export function calculateGgp(product: ProductGgpData): GgpData {
  const { crp, lowerLimit, upperLimit, ggpUnitPrice } = product
  const ggpPercentage = getGgpPercentage(crp, ggpUnitPrice)

  return {
    isValid: !!ggpPercentage,
    status: getGgpStatus(crp, lowerLimit, upperLimit),
    percentage: ggpPercentage ? ggpPercentage : INVALID_GGP_PERCENTAGE,
    tooltipText: ggpPercentage
      ? getGgpTooltipText(crp, lowerLimit, upperLimit)
      : 'missing',
  }
}

export function calculateTotalAmount(products: readonly ProductGgpData[]) {
  return getTotalOf(products, 'crp')
}

export function calculateTotalGgp(
  products: readonly ProductGgpData[],
): GgpData {
  const totalCrp = getTotalOf(products, 'crp')
  const totalLowerLimit = getTotalOf(products, 'lowerLimit')
  const totalUpperLimit = getTotalOf(products, 'upperLimit')
  const totalUnitPrice = getTotalOf(products, 'ggpUnitPrice')
  const totalGgpPercentage = getGgpPercentage(totalCrp, totalUnitPrice)
  const status = getGgpStatus(totalCrp, totalLowerLimit, totalUpperLimit)
  const totalTooltipText = getGgpTooltipText(
    totalCrp,
    totalLowerLimit,
    totalUpperLimit,
  )

  return {
    isValid: !!totalGgpPercentage,
    status,
    percentage: totalGgpPercentage
      ? totalGgpPercentage
      : INVALID_GGP_PERCENTAGE,
    tooltipText: totalGgpPercentage ? totalTooltipText : 'missing',
  }
}
