import { format } from 'date-fns'

export const formatDate = (dateFormat: DateFormat, date?: Date) => {
  return date ? format(new Date(date), dateFormat) : ''
}

export type DateFormat = 'dd-MM-yyyy'
export type Date = number

export const formatDateTime = (dateTimeFormat: DateTimeFormat, date?: Date) => {
	return date ? format(new Date(date), dateTimeFormat) : ''
}

export type DateTimeFormat = `${DateFormat} HH:mm`
