import { API } from '@/api'
import { MutationTree } from 'vuex'
import { NotificationsState } from './types'

export const mutations: MutationTree<NotificationsState> = {
  updateUnreadCounter(state, payload: number) {
    state.unreadCounter = payload
  },
  updateArePushNotificationsAllowedByUser(state, payload: boolean | null) {
    state.arePushNotificationsAllowedByUser = payload
  },
  updateIsLoading(state, payload: boolean) {
    state.isLoading = payload
  },
  setScrollState(state, scrollState: unknown) {
    state.scrollState = scrollState
  },
  setNotificationsBell(state, payload: boolean) {
    state.isNotificationBellAnimated = payload
  },
  setInboxNotifications(state, payload: API.NotificationDto[]) {
    state.inboxNotifications = payload
  },
  setInboxNotificationsChange(state, payload: boolean) {
    state.inboxNotificationsChange = payload
  },
  readInboxNotification(state, notificationId: number) {
    const updatedInboxNotifications = state.inboxNotifications.map(
      (notification) =>
        notification.id === notificationId
          ? { ...notification, read: true }
          : notification,
    )
    state.inboxNotifications = updatedInboxNotifications
  },
}
