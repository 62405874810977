<template>
  <div class="fade">
    <slot />
    <div class="fade-effect" />
  </div>
</template>

<style lang="scss" scoped>
.fade {
  display: flex;
  flex: 1;
  position: relative;

  :deep(.p-listbox-list) {
    padding-bottom: 30px !important;
  }

  .fade-effect {
    position: absolute;
    bottom: 0;
    height: 55px;
    width: calc(100% - 20px);
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.2),
      #ffffff
    );
    pointer-events: none;
  }
}
</style>
