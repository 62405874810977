import { API } from '@/api'
import { apiClient, errorHandler } from '@/services/data.service'
import { AppState } from '@/store/types'
import { ActionTree } from 'vuex'
import { ProductDetailsState } from './types'

export const actions: ActionTree<ProductDetailsState, AppState> = {
  async fetchProductsConnectedToCurrentProducts({ commit, dispatch, state }) {
    const productDetails = state.product
    if (!productDetails) {
      return
    }
    try {
      if (productDetails.alternativeProducts?.length) {
        dispatch('fetchAlternativeProducts', productDetails.alternativeProducts)
      } else {
        commit('updateAlternativeProducts', [])
      }
      if (productDetails.relatedProducts?.length) {
        dispatch('fetchRelatedProducts', productDetails.relatedProducts)
      } else {
        commit('updateRelatedProducts', [])
      }
    } catch (e) {
      errorHandler(e)
    }
  },

  async fetchProductDetailsByTitle({ commit, dispatch }, productTitle: string) {
    commit('updateIsLoading', true)
    commit('updateProductDetails', undefined)
    try {
      const productDetails: API.ProductsPropertiesDetailsDto = (
        await apiClient.products.detailsByTitleUsingGet(productTitle)
      ).data
      commit('updateProductDetails', productDetails)

      await dispatch('fetchProductsConnectedToCurrentProducts')
    } catch (e) {
      errorHandler(e)
    }
    commit('updateIsLoading', false)
  },

  async fetchProductDetailsById({ commit, dispatch }, id: number) {
    commit('updateIsLoading', true)
    commit('updateProductDetails', undefined)
    try {
      const productDetails: API.ProductsPropertiesDetailsDto = (
        await apiClient.products.detailsUsingGet(id)
      ).data
      commit('updateProductDetails', productDetails)

      await dispatch('fetchProductsConnectedToCurrentProducts')
    } catch (e) {
      errorHandler(e)
    }
    commit('updateIsLoading', false)
  },

  async fetchAlternativeProducts({ commit }, ids: number[]) {
    commit('updateAlternativeProducts', [])
    try {
      const products: API.ProductRowResponseDto[] = (
        await apiClient.products.getProductsByIdsUsingGet({ ids })
      ).data.content
      commit('updateAlternativeProducts', products)
    } catch (e) {
      errorHandler(e)
    }
  },

  async fetchRelatedProducts({ commit }, ids: number[]) {
    commit('updateRelatedProducts', [])
    try {
      const products: API.ProductRowResponseDto[] = (
        await apiClient.products.getProductsByIdsUsingGet({ ids })
      ).data.content
      commit('updateRelatedProducts', products)
    } catch (e) {
      errorHandler(e)
    }
  },

  async reportIssue(_ctx, payload: API.ReportDataDto) {
    try {
      await apiClient.report.sendReportDataIssueUsingPost(payload)
    } catch (e) {
      errorHandler(e)
    }
  },
}
