import { isSmallerThanSmall } from '@/helpers/useBreakpoints'
import useUserHelper from '@/helpers/useUserHelper'
import { NAVIGATION_CONFIG, NavigationConfigItem } from '@/routes/router.config'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const HEADER_CLASS_NAMES = {
  TRUE_CENTER: 'header__true-center',
  SEARCH_WRAPPER: 'header__search-wrapper',
}

const useNavigationConfig = () => {
  const { role } = useUserHelper()
  const route = useRoute()
  const { t } = useI18n()

  /**
   * Check current route name with main route name and all of its children
   */
  const isRouteActive = ({ children, main }: NavigationConfigItem) => {
    const childrenNames = children.map(({ name }) => name)

    return [main.name, ...childrenNames].includes(route.name as string)
  }

  const isHamburgerMenuVisible = computed(() => {
    return isSmallerThanSmall.value
  })

  /**
   * Prepare navigation config base on user role and visibility
   */
  const navigationConfig = computed(() => {
    return NAVIGATION_CONFIG.filter(({ main }) => {
      if (!main.allowedRoles) {
        return true
      }

      return role.value ? main.allowedRoles.includes(role.value) : false
    })
      .map((navigationItem) => {
        const isActive = isRouteActive(navigationItem)
        const label = t(`common.${navigationItem.main.name}`)

        return {
          ...navigationItem.main,
          label,
          class: { isActive },
        }
      })
      .filter((navigationItem) => {
        return isHamburgerMenuVisible.value
          ? true
          : navigationItem.meta?.isVisibleOnlyInHamburgerMenu !== true
      })
  })

  /**
   * CSS class for search wrapper: search bar in header can be on horizontal center of page or not
   */
  const searchWrapperClass = computed(() => {
    const isFewNavItems = navigationConfig.value.length < 2
    if (isHamburgerMenuVisible.value || isFewNavItems) {
      //return HEADER_CLASS_NAMES.TRUE_CENTER // TODO - Causes layout bugs, make sure if we need this class
      return HEADER_CLASS_NAMES.SEARCH_WRAPPER
    }

    return HEADER_CLASS_NAMES.SEARCH_WRAPPER
  })

  return {
    isHamburgerMenuVisible,
    searchWrapperClass,
    navigationConfig,
  }
}

export default useNavigationConfig
