import { FilterGroup, LegacyFilterLeaf, SearchFilters } from '@/store/types'
import { deviceGroup } from './devices.config'
import { productCluster } from './productCluster.config'

export const careArea = () => {
  return FilterGroup({
    label: 'search.careArea',
    children: [
      LegacyFilterLeaf(0, {
        value: 'OR',
        label: 'search.operatingRoom',
      }),
      LegacyFilterLeaf(1, {
        value: 'ICU',
        label: 'search.intensiveCareUnit',
      }),
      LegacyFilterLeaf(2, {
        value: 'NICU',
        label: 'search.neonatalIntensiveCareUnit',
      }),
    ],
  })
}

export const patientGroup = () => {
  return FilterGroup({
    label: 'search.patientGroup',
    children: [
      LegacyFilterLeaf(20, {
        value: 'ADULTS',
        label: 'search.adults',
      }),
      LegacyFilterLeaf(21, {
        value: 'PAEDIATRICS',
        label: 'search.pediatrics',
      }),
      LegacyFilterLeaf(22, {
        value: 'NEONATES',
        label: 'search.neonates',
      }),
    ],
  })
}

export const moreFilters = () => {
  return FilterGroup({
    label: 'search.moreFilters',
    children: [
      LegacyFilterLeaf(50, {
        value: 'RFID',
        label: 'search.rfid',
      }),
      LegacyFilterLeaf(51, {
        value: 'LATEX_FREE',
        label: 'search.latexFree',
      }),
      LegacyFilterLeaf(52, {
        value: 'NEW',
        label: 'search.new',
      }),
    ],
  })
}

export const getDefaultSearchFilters = (): SearchFilters => ({
  careArea: careArea(),
  deviceGroup: deviceGroup(),
  patientGroup: patientGroup(),
  usage: FilterGroup({
    label: 'search.usage',
    children: [], // Dynamic filter
  }),
  productCluster: productCluster(),
  moreFilters: moreFilters(),
})
