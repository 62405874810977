import { NotificationsState } from './types'

export const state: NotificationsState = {
  unreadCounter: 0,
  arePushNotificationsAllowedByUser: null,
  isLoading: false,
  isNotificationBellAnimated: false,
  inboxNotifications: [],
  inboxNotificationsChange: false,
  scrollState: undefined,
}
