import { RouteRecordRaw, createRouter, createWebHashHistory } from 'vue-router'
import { ROUTER_CONFIG } from './router.config'

const routes: Array<RouteRecordRaw> = [
  {
    ...ROUTER_CONFIG.finder,
    component: () => import(/* webpackChunkName: "finder" */ './finder'),
  },
  {
    ...ROUTER_CONFIG.searchResults,
    component: () =>
      import(/* webpackChunkName: "searchResults" */ './finder/SearchResults'),
  },
  {
    ...ROUTER_CONFIG.onboarding,
    component: () =>
      import(/* webpackChunkName: "onboarding" */ './onboarding'),
  },
  {
    ...ROUTER_CONFIG.scpRegistration,
    component: () =>
      import(/* webpackChunkName: "scpRegistration" */ './scpRegistration'),
  },
  {
    ...ROUTER_CONFIG.scpRegistrationWaitingRoom,
    component: () =>
      import(
        /* webpackChunkName: "scpRegistrationWaitingRoom" */ './scpRegistrationWaitingRoom'
      ),
  },
  {
    ...ROUTER_CONFIG.scpRegistrationRejected,
    component: () =>
      import(
        /* webpackChunkName: "scpRegistrationRejected" */ './scpRegistrationRejected'
      ),
  },
  {
    ...ROUTER_CONFIG.scpOnboarding,
    component: () =>
      import(/* webpackChunkName: "scpOnboarding" */ './scpOnboarding'),
  },
  {
    ...ROUTER_CONFIG.token,
    redirect: ROUTER_CONFIG.finder.path,
  },
  {
    ...ROUTER_CONFIG.productList,
    component: () =>
      import(/* webpackChunkName: "productLists" */ './productLists'),
  },
  {
    ...ROUTER_CONFIG.productListEdit,
    component: () =>
      import(/* webpackChunkName: "productLists" */ './productLists'),
  },
  {
    ...ROUTER_CONFIG.productListTabs,
    component: () =>
      import(/* webpackChunkName: "productLists" */ './productLists'),
  },
  {
    ...ROUTER_CONFIG.productListDetails,
    component: () =>
      import(
        /* webpackChunkName: "productListDetails" */ './productListDetails'
      ),
  },
  {
    ...ROUTER_CONFIG.countryProductListDetails,
    component: () =>
      import(
        /* webpackChunkName: "productListDetails" */ './productListDetails'
      ),
  },
  {
    ...ROUTER_CONFIG.privateProductListDetails,
    component: () =>
      import(
        /* webpackChunkName: "productListDetails" */ './productListDetails'
      ),
  },
  {
    ...ROUTER_CONFIG.draftProductListDetails,
    component: () =>
      import(
        /* webpackChunkName: "productListDetails" */ './productListDetails'
      ),
  },

  {
    ...ROUTER_CONFIG.productDetailsByTitle,
    component: () =>
      import(/* webpackChunkName: "productListDetails" */ './ProductDetails'),
  },
  {
    ...ROUTER_CONFIG.favorites,
    component: () => import(/* webpackChunkName: "favorites" */ './favorites'),
  },
  {
    ...ROUTER_CONFIG.profile,
    component: () => import(/* webpackChunkName: "profile" */ './profile'),
  },
  {
    ...ROUTER_CONFIG.users,
    component: () => import(/* webpackChunkName: "users" */ './users'),
  },
  {
    ...ROUTER_CONFIG.editUser,
    component: () =>
      import(/* webpackChunkName: "EditUser" */ './users/EditUsers'),
  },
  {
    ...ROUTER_CONFIG.editScpUser,
    component: () =>
      import(
        /* webpackChunkName: "EditScpUser" */ './users/EditUsers/scp/ScpForm.vue'
      ),
  },
  {
    ...ROUTER_CONFIG.system,
    component: () => import(/* webpackChunkName: "system" */ './system'),
  },
  {
    ...ROUTER_CONFIG.systemTabs,
    component: () => import(/* webpackChunkName: "system" */ './system'),
  },
  {
    ...ROUTER_CONFIG.manageAvailability,
    component: () =>
      import(
        /* webpackChunkName: "manageAvailability" */ './manageAvailability'
      ),
  },
  {
    ...ROUTER_CONFIG.errors,
    component: () => import(/* webpackChunkName: "errors" */ './ErrorPage'),
  },
  {
    ...ROUTER_CONFIG.loginError,
    component: () =>
      import(/* webpackChunkName: "loginError" */ './loginError'),
  },
  {
    ...ROUTER_CONFIG.notifications,
    component: () =>
      import(/* webpackChunkName: "notifications" */ './notifications'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach(() => {
	console.log('router.beforeEach location:', JSON.stringify(location, null, 2))

  const PARAM = 'hca4life'
  const { hash, origin, search } = location

	if (hash === '#/' && search.length && search.includes('?code=')) {
		console.warn('#/ detected at the end of the login callback - trying to redirect back to the HCA app root page')
		const searchParams = new URLSearchParams(search)

		location.replace(origin + '#/?code=' + searchParams.get('code'))

		return
	}

  const isDeepLink =
    hash.startsWith('#/p/') || hash.startsWith('#/product-list/')
  const hashUrl = new URL(hash.slice(1), origin)
  const inHash = hashUrl.searchParams.has(PARAM)
  const inSearch = new URLSearchParams(location.search).has(PARAM)
  const isRedirUrl = (inHash || inSearch) && isDeepLink

  if (isRedirUrl && /(iphone|ipad|ipod)/i.test(navigator.userAgent)) {
    location.replace(process.env.VUE_APP_STORE_REDIRECT_IOS!)
  } else if (isRedirUrl && /android/i.test(navigator.userAgent)) {
    location.replace(process.env.VUE_APP_STORE_REDIRECT_ANDROID!)
  } else {
    if (inHash) {
      hashUrl.searchParams.delete(PARAM)
      location.hash = hashUrl.href.slice(hashUrl.origin.length)
    }

    if (inSearch) {
      const url = new URL(location.href)
      url.searchParams.delete(PARAM)
      location.replace(url)
    }
  }
})

export default router
