export enum AnalyticEvent {
  ADD_ALTERNATIVE_ITEM_TO_FAVORITES = 'add_alternative_item_to_favorites',
  ADD_MULTI_PRODUCTS_TO_MULTI_LISTS = 'add_multiple_product_list',
  ADD_RELATED_PRODUCT_TO_FAVORITES = 'add_related_product_to_favorites',
  ADD_SINGLE_PRODUCT_TO_SINGLE_LIST = 'add_product_to_product_list',
  CHANGE_COUNTRY = 'change_country',
  CHANGE_CURRENCY = 'change_currency',
  CHECK_FOR_AVAILABLE_STOCK = 'check_for_available_stock',
  CHECK_FOR_CLEARANCE = 'check_for_clearance',
  CHECK_FOR_CURATED_LISTS_UPDATES = 'check_for_curated_lists_updates',
  CHECK_FOR_PHASE_OUT = 'check_for_phase_out',
  CHECK_FOR_SENT_PRODUCT_LISTS = 'check_for_sent_product_lists',
  CHECK_FOR_SHOW_PRICES = 'check_for_show_prices',
  CLICK_ADD_TO_FAVORITES = 'click_add_to_favorites',
  CLICK_ADD_TO_PRODUCT_LIST_FROM_PRODUCT_DETAILS = 'click_add_to_product_list_from_product_details',
  CLICK_ADDITIONAL_INFORMATION_LINK = 'click_additional_information_link',
  CLICK_AVAILABILITY_STATUS = 'click_availability_status',
  CLICK_CARE_AREA = 'click_care_area',
  CLICK_DEVICE_GROUPS = 'click_device_groups',
  CLICK_IMAGE_VIEW = 'click_image_view',
  CLICK_PRODUCT_CLUSTER = 'click_product_cluster',
  CLICK_PRODUCT_DETAILS_FROM_ALTERNATIVE_ITEMS = 'click_product_details_from_alternative_items',
  CLICK_PRODUCT_DETAILS_FROM_FAVORITES = 'click_product_details_from_favorites',
  CLICK_PRODUCT_DETAILS_FROM_FINDER = 'click_product_details_from_finder',
  CLICK_PRODUCT_DETAILS_FROM_PRODUCT_LIST = 'click_product_details_from_product_list',
  CLICK_PRODUCT_DETAILS_FROM_RELATED_ITEMS = 'click_product_details_from_related_items',
  CLICK_TRAINING_MATERIAL_LINK = 'click_training_material_link',
  CLICK_VIEW_ALL_PRODUCTS = 'click_view_all_products',
  DUPLICATE_PRODUCT_LIST = 'duplicate_product_list',
  EDIT_PRODUCT_LIST = 'edit_product_list',
  ENLARGED_IMAGE_VIEW = 'enlarged_image_view',
  EXPORT_PRODUCT_LIST_PDF = 'export_product_list_pdf',
  EXPORT_PRODUCT_LIST_XLS = 'export_product_list_xls',
  HIDE_PRODUCTS_WITHOUT_MARKET_CLEARANCE = 'hide_products_without_market_clearance',
  MARK_ALL_NOTIFICATIONS_AS_READ = 'mark_all_as_read',
  MOVE_PRODUCT_LIST = 'move_product_list',
  PAGE_VIEW = 'page_view',
  PIN_PRODUCT_LIST = 'pin_product_list',
  REPORT_DATA_ISSUE = 'report_data_issue',
  SEARCH_TERM = 'search_term',
  SELECT_PRODUCTS_PRODUCT_LIST = 'select_products_product_list',
  SHARE_PRODUCT_LIST = 'share_product_list',
  SHARE_PRODUCT_LIST_COMPLETED = 'share_product_list_completed',
  SHOW_USER_PROFILE = 'show_user_profile',
  SKIP_ONBOARDING = 'skip_onboarding',
  USED_FILTER = 'used_filter',
}
