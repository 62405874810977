import { FilterGroup, LegacyFilterLeaf } from '@/store/types'

export const ventilationAccessories = () => {
  return FilterGroup({
    label: 'productCluster.ventilationAccessories',
    selectAllChildren: {
      label: 'productCluster.allVentilationAccessories',
    },
    children: [
      LegacyFilterLeaf(400, {
        label: 'productCluster.breathingCircuits',
        value: 'VENTILATION_BREATHING_CIRCUITS',
      }),
      LegacyFilterLeaf(401, {
        label: 'productCluster.breathingSystemFiltersAndHMEs',
        value: 'VENTILATION_BREATHING_FILTERS',
      }),
      LegacyFilterLeaf(402, {
        label: 'productCluster.cO2Cuvettes',
        value: 'VENTILATION_ACCESSORIES_C02_CUVETTES',
      }),
      LegacyFilterLeaf(403, {
        label: 'productCluster.catheterMountsAndAirwayConnectors',
        value: 'VENTILATION_ACCESSORIES_CATHETER_MOUNTS',
      }),
      LegacyFilterLeaf(404, {
        label: 'productCluster.nonInvasivePatientInterfacesAndAccessories',
        value: 'VENTILATION_NON_INVASIVE_PATIENT_INTERFACES_AND_ACCESSORIES',
      }),
      LegacyFilterLeaf(405, {
        label: 'productCluster.oxymixer',
        value: 'VENTILATION_ACCESSORIES_OXYMIZER',
      }),
      LegacyFilterLeaf(406, {
        label: 'productCluster.deviceRelatedAccessories',
        value: 'VENTILATION_ACCESSORIES_ACCESSORIES',
      }),
      LegacyFilterLeaf(407, {
        label: 'productCluster.pack2GoSet2Go',
        value: 'VENTILATION_ACCESSORIES_PACK2GO',
      }),
      LegacyFilterLeaf(408, {
        label: 'productCluster.humidifier',
        value: 'VENTILATION_HUMIDIFIER',
      }),
      LegacyFilterLeaf(409, {
        label: 'productCluster.intubationKits',
        value: 'VENTILATION_INTUBATION_KITS',
      }),
      LegacyFilterLeaf(4010, {
        label: 'productCluster.sparePartsForResuscitators',
        value: 'VENTILATION_SPARE_PARTS_FOR_RESUSCITATORS',
      }),
      LegacyFilterLeaf(4011, {
        label: 'productCluster.nebulizer',
        value: 'VENTILATION_NEBULIZER',
      }),
    ],
  })
}

export const sodaLime = () => {
  return FilterGroup({
    label: 'productCluster.sodaLime',
    children: [
      LegacyFilterLeaf(410, {
        label: 'productCluster.sodaLime',
        value: 'SODA_LIME',
      }),
    ],
  })
}

export const sensors = () => {
  return FilterGroup({
    label: 'productCluster.sensors',
    children: [
      LegacyFilterLeaf(420, {
        label: 'productCluster.sensors',
        value: 'SENSORS',
      }),
    ],
  })
}

export const monitoringAccessories = () => {
  return FilterGroup({
    label: 'productCluster.monitoringAccessories',
    selectAllChildren: {
      label: 'productCluster.allMonitoringAccessories',
    },
    children: [
      LegacyFilterLeaf(435, {
        label: 'productCluster.electrocardiography',
        value: 'MONITORING_ACCESSORIES_ELECTROCARDIOGRAPHY',
      }),
      LegacyFilterLeaf(436, {
        label: 'productCluster.nonInvasiveBloodPressure',
        value: 'MONITORING_ACCESSORIES_NON_INVASIVE_BLOOD_PRESSURE',
      }),
      LegacyFilterLeaf(437, {
        label: 'productCluster.pulseOximetry',
        value: 'MONITORING_ACCESSORIES_PULSE_OXIMETRY',
      }),
      LegacyFilterLeaf(438, {
        label: 'productCluster.temperature',
        value: 'MONITORING_ACCESSORIES_TEMPERATURE',
      }),
      LegacyFilterLeaf(439, {
        label: 'productCluster.invasiveBloodPressure',
        value: 'MONITORING_ACCESSORIES_INVASIVE_BLOOD_PRESSURE',
      }),
      LegacyFilterLeaf(4310, {
        label: 'productCluster.capnography',
        value: 'MONITORING_ACCESSORIES_CAPNOGRAPHY',
      }),
      LegacyFilterLeaf(4311, {
        label: 'productCluster.neuromuscularTransm',
        value: 'MONITORING_ACCESSORIES_NEUROMUSCULAR_TRANSM',
      }),
      LegacyFilterLeaf(4312, {
        label: 'productCluster.bisectralIndex',
        value: 'MONITORING_ACCESSORIES_BISECTRAL_INDEX',
      }),
      LegacyFilterLeaf(4313, {
        label: 'productCluster.cardiacOutput',
        value: 'MONITORING_ACCESSORIES_CARDIAC_OUTPUT',
      }),
      LegacyFilterLeaf(4314, {
        label: 'productCluster.pulseContCardOutput',
        value: 'MONITORING_ACCESSORIES_PULSE_CONT_CARD_OUTPUT',
      }),
      LegacyFilterLeaf(4315, {
        label: 'productCluster.scio',
        value: 'MONITORING_ACCESSORIES_SCIO',
      }),
      LegacyFilterLeaf(4316, {
        label: 'productCluster.electroencephalography',
        value: 'MONITORING_ACCESSORIES_ELECTROENCEPHALOGRAPHY',
      }),
      LegacyFilterLeaf(4317, {
        label: 'productCluster.continuousNonInvasiveArterialPressure',
        value:
          'MONITORING_ACCESSORIES_CONTINUOUS_NON_INVASIVE_ARTERIAL_PRESSURE',
      }),
    ],
  })
}

export const wardEquipment = () => {
  return FilterGroup({
    label: 'productCluster.wardEquipment',
    selectAllChildren: {
      label: 'productCluster.allWardEquipment',
    },
    children: [
      LegacyFilterLeaf(440, {
        label: 'productCluster.oxygenTherapy',
        value: 'WARD_EQUIPMENT_OXYGEN_THERAPY',
      }),
      LegacyFilterLeaf(441, {
        label: 'productCluster.aerosolTherapy',
        value: 'WARD_EQUIPMENT_AEROSOL_THERAPY',
      }),
      LegacyFilterLeaf(442, {
        label: 'productCluster.medicalAspiration',
        value: 'WARD_EQUIPMENT_MEDICAL_ASPIRATION',
      }),
      LegacyFilterLeaf(443, {
        label: 'productCluster.accessoryForGasSupply',
        value: 'WARD_EQUIPMENT_ACCESSORY_FOR_GAS',
      }),
    ],
  })
}

export const railAccessories = () => {
  return FilterGroup({
    label: 'productCluster.railAccessories',
    selectAllChildren: {
      label: 'productCluster.allRailAccessories',
    },
    children: [
      LegacyFilterLeaf(450, {
        label: 'productCluster.rail_accessories',
        value: 'RAIL_ACCESSORIES_PC2',
      }),
      LegacyFilterLeaf(451, {
        label: 'productCluster.examinationLight',
        value: 'RAIL_ACCESSORIES_EXAMINATION_LIGHT',
      }),
      LegacyFilterLeaf(452, {
        label: 'productCluster.hingedArms',
        value: 'RAIL_ACCESSORIES_HINGED_ARMS',
      }),
    ],
  })
}

export const neonatalCare = () => {
  return FilterGroup({
    label: 'productCluster.neonatalCare',
    selectAllChildren: {
      label: 'productCluster.allNeonatalCare',
    },
    children: [
      LegacyFilterLeaf(460, {
        label: 'productCluster.neoWarmingTherapy',
        value: 'NEONATAL_CARE_NEO_WARMING_THERAPY',
      }),
      LegacyFilterLeaf(461, {
        label: 'productCluster.neoVentilationAccessories',
        value: 'NEONATAL_CARE_NEO_VETINLATION_ACCESSORIES',
      }),
      LegacyFilterLeaf(462, {
        label: 'productCluster.neoSensors',
        value: 'NEONATAL_CARE_NEO_SENSORS',
      }),
      LegacyFilterLeaf(463, {
        label: 'productCluster.neoMonitoringAccessories',
        value: 'NEONATAL_CARE_NEO_MONITORING_ACCESSORIES',
      }),
      LegacyFilterLeaf(464, {
        label: 'productCluster.neoWardEquipment',
        value: 'NEONATAL_CARE_NEO_WARD_EQUIPMENT',
      }),
      LegacyFilterLeaf(465, {
        label: 'productCluster.neoRailAccessoriesAndExaminationLight',
        value: 'NEONATAL_CARE_NEO_RAIL_ACCESSORIES_AND_EXAMINATION_LIGHT',
      }),
    ],
  })
}

export const productCluster = () => {
  return FilterGroup({
    label: 'search.productCluster',
    children: [
      ventilationAccessories(),
      sodaLime(),
      sensors(),
      monitoringAccessories(),
      wardEquipment(),
      railAccessories(),
      neonatalCare(),
    ],
  })
}
