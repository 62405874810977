import { DEFAULT_SORT_TYPE } from '../productLists.config'
import { ProductListsState } from './types'

export const state: ProductListsState = {
  productLists: [],
  sharedProductLists: [],
  pinnedProductLists: [],
  draftProductLists: [],
  isLoadingProductLists: false,
  listSortingType: DEFAULT_SORT_TYPE,
  isEditMode: false,
  editionMode: 'single',
}
