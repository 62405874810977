import { checkCanEnter } from '@/helpers/getters'
import { scpRole } from '@/helpers/roles.utils'
import {
  onboardingRequiredScpStatus,
  pendingScpStatus,
  rejectedScpStatus,
} from '@/helpers/scpStatuses.utils'
import { ROUTER_CONFIG } from '@/routes/router.config'
import { findRouteByName, navigateNextToErrors } from '@/routes/router.utils'
import { checkIsPasswordReset } from '@/services/data.service'
import { AppStore, getDynamicStore } from '@/store'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

let fetchInitDataPromise: undefined | Promise<void>

const fetchInitDataIfNeeded = async (store: AppStore) => {
  if (!fetchInitDataPromise) {
    fetchInitDataPromise = store.dispatch('fetchInitData')
  }

  return fetchInitDataPromise
}

export const canEnterRoute = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const params = new URLSearchParams(to.path.substring(1))
  const store = await getDynamicStore()
  await fetchInitDataIfNeeded(store)
  const oldRedirectPath = store.state.persisted?.redirectFromPath

  if (!store.state.user?.username && oldRedirectPath && oldRedirectPath.name !== 'finder') {
    store.dispatch('setRedirectFromPath', { name: oldRedirectPath.name, path: oldRedirectPath.path })
  } else if (!store.state.user?.username) {
    store.dispatch('setRedirectFromPath', { name: to.name, path: to.path })
  }

  if (checkIsPasswordReset(params)) {
    next()

    return
  }

  if (!to.name) {
    navigateNextToErrors(next, 404)

    return
  }

  const dest = to.name as string
  const currentRouteConfig = findRouteByName(dest)

  if (currentRouteConfig === undefined) {
    navigateNextToErrors(next, 404)

    return
  }
  const { allowedRoles, allowedScpStatuses } = currentRouteConfig

  if (checkCanEnter(store.state, allowedRoles, allowedScpStatuses)) {
    next()

    return
  }

  const userState = store.state.user

  if (!userState) {
    // Failed to fetch user
    navigateNextToErrors(next, 403)

    return
  }

  if (userState.role !== scpRole) {
    next(ROUTER_CONFIG.finder)

    return
  }

  if (userState.scpStatus === rejectedScpStatus) {
    next(ROUTER_CONFIG.scpRegistrationRejected)

    return
  }

  if (userState.scpStatus === pendingScpStatus) {
    next(
      userState.country
        ? ROUTER_CONFIG.scpRegistrationWaitingRoom
        : ROUTER_CONFIG.scpRegistration,
    )

    return
  }

  if (userState.scpStatus === onboardingRequiredScpStatus) {
    next(ROUTER_CONFIG.scpOnboarding)

    return
  }
  navigateNextToErrors(next, 403)
}
