import { API } from '@/api'
import { FavoriteProduct } from '@/store/types'
import { MutationTree } from 'vuex'
import { ProductDetailsState } from './types'

export const mutations: MutationTree<ProductDetailsState> = {
  updateIsLoading(state, payload: boolean) {
    state.isLoading = payload
  },

  updateProductDetails(state, payload: API.ProductsPropertiesDetailsDto) {
    state.product = payload
  },

  updateProductFavorite(state, { id, isFavorite }: FavoriteProduct) {
    if (state.product?.id === id) {
      state.product.favorite = isFavorite
    }

    const otherProducts = [
      ...(state.alternativeProducts || []),
      ...(state.relatedProducts || []),
    ]

    for (const product of otherProducts) {
      if (product.productId === id) {
        product.favorite = isFavorite
      }
    }
  },

  updateAlternativeProducts(state, payload: API.ProductRowResponseDto[]) {
    state.alternativeProducts = payload
  },

  updateRelatedProducts(state, payload: API.ProductRowResponseDto[]) {
    state.relatedProducts = payload
  },

  updateIsFavoriteDisabled(state, payload: boolean) {
    state.isFavoriteDisabled = payload
  },
}
