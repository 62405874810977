import { API } from '@/api'
import { checkStatus } from '@/helpers/scpStatuses.utils'
import { getPersisted } from '@/store/persistenceHelper'
import { AppState } from '@/store/types'

export const isKeyUser = (state: AppState) => {
  return state.user?.role === 'KEY_USER'
}

export const isAdmin = (state: AppState) => {
  return state.user?.role === 'ADMIN'
}

export const isSalesChannelPartner = (state: AppState) => {
  return state.user?.role === 'SALES_CHANNEL_PARTNER'
}

export const isBasicUser = (state: AppState) => {
  return state.user?.role === 'BASIC_USER'
}

export const isBasicPlusUser = (state: AppState) => {
  return state.user?.role === 'BASIC_USER_PLUS'
}

export const isAdvancedUser = (state: AppState) => {
  return state.user?.role === 'ADVANCED_USER'
}

export const isKeyUserOrAdmin = (state: AppState) => {
  return isKeyUser(state) || isAdmin(state)
}

export const checkRole = (
  roleToCheck: API.DisplayUserProfileResponseDto['role'],
  roles: API.DisplayUserProfileResponseDto['role'][],
): boolean => {
  return roles.includes(roleToCheck)
}

export const checkCanEnter = (
  state: AppState,
  roles?: API.DisplayUserProfileResponseDto['role'][],
  scpStatuses?: API.DisplayUserProfileResponseDto['scpStatus'][],
): boolean => {
  if (!roles) {
    return true // when roles are not configured: any user is allowed, even if there is no user in store
  }
  const isRoleAllowed = checkRole(state.user!.role, roles)

  const isTestableForScpStatus =
    isSalesChannelPartner(state) && !!scpStatuses && isRoleAllowed

  return isTestableForScpStatus
    ? checkStatus(state, scpStatuses)
    : isRoleAllowed
}

export const canShowPrices = (state: AppState) => {
  return (
    state.user?.showPrices &&
    !isBasicUser(state) &&
    !isSalesChannelPartner(state)
  )
}

export const isFirebaseConsentsGranted = (state: AppState) => {
  const consents = getPersisted()?.firebaseConsents

  if (state.user?.id === undefined || consents === undefined) {
    return undefined
  }

  return consents[state.user.id]
}

export const isNotesTutorialShowed = (state: AppState) => {
  return getTutorialStatusByUserId(
    state.user?.id,
    getPersisted()?.isNotesTutorialShowed,
  )
}

export const isClipboardTutorialShowed = (state: AppState) => {
  return getTutorialStatusByUserId(
    state.user?.id,
    getPersisted()?.isClipboardTutorialShowed,
  )
}

export const isMultiProductsToMultiListsTutorialShowed = (state: AppState) => {
  return getTutorialStatusByUserId(
    state.user?.id,
    getPersisted()?.isMultiProductsToMultiListsTutorialShowed,
  )
}

export const isSortingTutorialShowed = (state: AppState) => {
  return getTutorialStatusByUserId(
    state.user?.id,
    getPersisted()?.isSortingTutorialShowed,
  )
}

function getTutorialStatusByUserId(
  id?: number,
  isShowedList?: Record<number, boolean>,
) {
  if (id === undefined || isShowedList === undefined) {
    return undefined
  }

  return isShowedList[id]
}
