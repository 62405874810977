import { API } from '@/api'
import { ShareDialogType } from '@/routes/productListDetails/ShareDialog/shareDialog.config'
import { ROUTER_CONFIG } from '@/routes/router.config'
import {
  apiClient,
  errorHandler,
  productListErrorHander,
  saveAsMineSuccessHandler,
  sharingSuccessHandler,
  showToastAfterSavingCustomOrder,
} from '@/services/data.service'
import { getDynamicRouter } from '@/services/router.service'
import { AppState } from '@/store/types'
import { ActionTree } from 'vuex'
import { SortingType } from './../productListDetails.config'
import {
  CopyShareProductListPayload,
  DeleteProductsPayload,
  DraftUpdatePayload,
  ManageConflictDialogPayload,
  ModifyProductPayload,
  MoveProductsPayload,
  MultipleSelectionPayload,
  ProductListDetailsState,
  ProductListType,
  ScpShareProductListPayload,
  ShareGlobalListPayload,
  SharePrivateProductListPayload,
  UpdateProductListPayload,
} from './types'

export const actions: ActionTree<ProductListDetailsState, AppState> = {
  clearProductList({ commit }) {
    const updateProductListEmptyPayload: UpdateProductListPayload = {
      productList: undefined,
      productListType: undefined,
    }
    commit('updateProductList', updateProductListEmptyPayload)
  },

  async fetchProductListDetails({ commit, dispatch }, listId: number) {
    commit('updateIsLoading', true)
    await Promise.all([
      dispatch('fetchProductListData', listId),
      dispatch('fetchProductListElements', listId),
    ])
    commit('updateIsLoading', false)
  },

  async fetchProductListData({ commit, dispatch }, listId: number) {
    dispatch('clearProductList')
    try {
      const { data } = await apiClient.productLists.getListDetailsUsingGet(
        listId,
      )
      const updateProductListPayload: UpdateProductListPayload = {
        productList: data as API.ProductListRowResponseDto,
        productListType: 'personal',
      }
      commit('updateProductList', updateProductListPayload)
    } catch (e) {
      errorHandler(e)
    }
  },

  async fetchCountryProductListDetails({ commit, dispatch }, listId: number) {
    commit('updateIsLoading', true)
    dispatch('clearProductList')

    try {
      const { data } =
        await apiClient.countrySharing.getCountryShareDetailsUsingGet(listId)
      const updateProductListPayload: UpdateProductListPayload = {
        productList: data as API.CountryShareDetailsDto,
        productListType: 'countryShare',
      }
      commit('updateProductList', updateProductListPayload)
      commit('updateProductListElements', data.elements)
      if (data.elements?.length) {
        commit('updateSelectedProduct', data.elements[0])
      }
    } catch (e) {
      errorHandler(e)
    }
    commit('updateIsLoading', false)
  },

  async fetchPrivateProductListDetails({ commit, dispatch }, listId: number) {
    commit('updateIsLoading', true)
    dispatch('clearProductList')

    try {
      const { data } =
        await apiClient.privateSharing.getPrivateShareDetailsUsingGet(listId)
      const updateProductListPayload: UpdateProductListPayload = {
        productList: data as API.PrivateShareDetailsDto,
        productListType: 'privateShare',
      }
      commit('updateProductList', updateProductListPayload)
      commit('updateProductListElements', data.elements)
      if (data.elements?.length) {
        commit('updateSelectedProduct', data.elements[0])
      }
    } catch (e) {
      errorHandler(e)
    }
    commit('updateIsLoading', false)
  },
  async fetchDraftProductListDetails({ commit, dispatch }, listId: number) {
    commit('updateIsLoading', true)
    dispatch('clearProductList')

    try {
      const { data } = await apiClient.shareDraft.getDraftDetailsUsingGet(
        listId,
      )
      const updateProductListPayload: UpdateProductListPayload = {
        productList: data as API.ShareDraftDetailsDto,
        productListType: 'draft',
      }
      commit('updateProductList', updateProductListPayload)
      commit('updateProductListElements', data.elements)
      commit('updateProductListElementsWithSorting', data)
      commit('updateListSortingType', data.sortParameter)

      if (data.elements?.length) {
        commit('updateSelectedProduct', data.elements[0])
      }
    } catch (e) {
      errorHandler(e)
    }
    commit('updateIsLoading', false)
  },

  async fetchProductListElements({ commit }, listId: number) {
    commit('updateProductListElements', [])
    commit('updateSelectedProduct', undefined)
    try {
      const productListElements = (
        await apiClient.v2.getProductListElementsV2UsingGet(listId)
      ).data
      commit('updateProductListElements', productListElements.elements ?? [])
      commit('updateProductListElementsWithSorting', productListElements)
      commit('updateListSortingType', productListElements.sortParameter)
      if (productListElements?.elements?.length) {
        commit('updateSelectedProduct', productListElements.elements[0])
      }
    } catch (e) {
      errorHandler(e)
    }
  },



  async saveProductListAsMine(
    { commit },
    payload: CopyShareProductListPayload,
  ) {
    commit('updateIsLoading', true)

    try {
      const { data } = await apiClient.countrySharing.copyUsingPost(
        payload.listId,
        payload.body,
      )

      ;(await getDynamicRouter()).push({
        name: ROUTER_CONFIG.productListDetails.name,
        params: { productListId: data.id },
      })

      saveAsMineSuccessHandler(payload.listName)
    } catch (e) {
      productListErrorHander(e)
    }
    commit('updateIsLoading', false)
  },

  async savePrivateSharedListAsMine(
    { commit },
    payload: CopyShareProductListPayload,
  ) {
    commit('updateIsLoading', true)

    try {
      const { data } = await apiClient.privateSharing.copyAsMyListUsingPost(
        payload.listId,
        payload.body,
      )
      ;(await getDynamicRouter()).push({
        name: ROUTER_CONFIG.productListDetails.name,
        params: { productListId: data.id },
      })

      saveAsMineSuccessHandler(payload.listName)
    } catch (e) {
      productListErrorHander(e)
    }
    commit('updateIsLoading', false)
  },

  async duplicateProductList({ commit }, listId: number) {
    const { data } = await apiClient.productLists.copyListUsingPost(listId)

    const updateProductListPayload: UpdateProductListPayload = {
      productList: data,
      productListType: 'personal',
    }

    commit('updateProductList', updateProductListPayload)
  },

  async sharePrivateList({ commit }, payload: SharePrivateProductListPayload) {
    commit('updateIsLoading', true)
    try {
      await apiClient.privateSharing.shareUsingPost(
        payload.listId,
        payload.body,
      )

      await sharingSuccessHandler(payload)
    } catch (e) {
      errorHandler(e)
    }
    commit('updateIsLoading', false)
  },

  async shareCountryList({ commit }, payload: ShareGlobalListPayload) {
    commit('updateIsLoading', true)
    try {
      await apiClient.countrySharing.countryShareUsingPost(
        payload.listId,
        payload.body,
      )

      await sharingSuccessHandler(payload)
    } catch (e) {
      errorHandler(e)
    }
    commit('updateIsLoading', false)
  },

  async sharePrivateListWithScp(
    { commit },
    payload: ScpShareProductListPayload,
  ) {
    commit('updateIsLoading', true)
    try {
      await apiClient.privateSharing.shareWithScpUsingPost(
        payload.listId,
        payload.body,
      )

      await sharingSuccessHandler(payload)
    } catch (e) {
      errorHandler(e)
    }
    commit('updateIsLoading', false)
  },

  async publishDraftList({ commit }, payload: DraftUpdatePayload) {
    commit('updateIsLoading', true)
    try {
      await apiClient.shareDraft.publishDraftUsingPost(payload.listId, {
        countryIds: payload.countryIds,
        note: payload.note,
      })
    } catch (e) {
      errorHandler(e)
    }
    commit('updateIsLoading', false)
  },

  async updateDraftListDate({ commit }, listId: number) {
    commit('updateIsLoading', true)
    try {
      await apiClient.shareDraft.updateEditDateUsingPut(listId)
    } catch (e) {
      errorHandler(e)
    }
    commit('updateIsLoading', false)
  },

  async updateDraftAndPublish(
    { commit, dispatch },
    payload: DraftUpdatePayload,
  ) {
    commit('updateIsLoading', true)
    try {
      await dispatch('updateDraftListDate', payload.listId)
      await dispatch('publishDraftList', payload)
      await sharingSuccessHandler({
        listId: payload.listId,
        listName: payload.listName,
        shareType: ShareDialogType.COUNTRY,
      })
    } catch (e) {
      errorHandler(e)
    }
    commit('updateIsLoading', false)
  },

  async draftListDetails({ commit }, listId: number) {
    commit('updateIsLoading', true)
    try {
      await apiClient.shareDraft.getDraftDetailsUsingGet(listId)
    } catch (e) {
      errorHandler(e)
    }
    commit('updateIsLoading', false)
  },

  async deleteProducts({ state, commit }, payload: DeleteProductsPayload) {
    try {
      await apiClient.productLists.deleteProductsFromListUsingDelete(
        payload.listId,
        { ids: [...payload.ids] },
      )
      commit('deleteFromProductListElements', payload)
      commit('updateSelectedProduct', state.productListElements[0])
      commit('clearMultipleSelectedProducts')
    } catch (e) {
      errorHandler(e)
    }
  },

  async deleteDraftProducts({ state, commit }, payload: DeleteProductsPayload) {
    try {
      await apiClient.shareDraft.deleteElementsFromDraftUsingDelete(
        payload.listId,
        { ids: [...payload.ids] },
      )
      commit('deleteFromProductListElements', payload)
      commit('updateSelectedProduct', state.productListElements[0])
      commit('clearMultipleSelectedProducts')
    } catch (e) {
      errorHandler(e)
    }
  },

  async moveProducts({ dispatch, commit }, payload: MoveProductsPayload) {
    const { initialListId, targetListId, move } = payload
    await apiClient.productLists.moveElementsToAnotherListUsingPut(
      initialListId,
      targetListId,
      move,
    )

    dispatch('fetchProductListDetails', initialListId)
    commit('clearMultipleSelectedProducts')
  },

  selectProduct(
    { state, commit },
    payload: API.DisplayProductListElementResponseDto,
  ) {
    if (state.isSelectMode) return
    commit('updateSelectedProduct', payload)
  },

  selectMultipleProducts({ commit }, payload: MultipleSelectionPayload) {
    commit('updateMultipleSelectedProducts', payload)
  },

  manageMoveToDialog({ commit }, payload: boolean) {
    commit('updateMoveToDialogVisible', payload)
  },

  manageConflictDialog({ commit }, payload: ManageConflictDialogPayload) {
    commit('updateConflictDialog', payload)
  },

  setSelectionMode({ state, commit }, payload: boolean) {
    commit('updateIsSelectMode', payload)
    commit('clearMultipleSelectedProducts')
    if (payload) {
      commit('updateSelectedProduct', undefined)
    } else {
      commit('updateSelectedProduct', state.productListElements[0])
    }
  },


  async saveElementCrpOrQuantity({ dispatch }, payload: ModifyProductPayload) {
    const { what } = payload
    const actionName = 'crp' in what ? 'saveElementCrp' : 'saveElementQuantity'
    await dispatch(actionName, payload)
  },

  async saveElementCrp({ dispatch }, payload: ModifyProductPayload) {
    const { product, what, productListType } = payload
    if (!('crp' in what)) {
      return
    }
    const dataToSend = {
      value: what.crp,
      cancelToken: `CRP-${product.id}`,
    }

    const targetAction =
      productListType === 'draft'
        ? apiClient.shareDraft.changeDraftElementCrpUsingPut
        : apiClient.productLists.changeElementCrpUsingPut

    try {
      await targetAction(product.id, dataToSend)
    } catch (e) {
      errorHandler(e)
      dispatch('fetchProductListElements', product.listId)
    }
  },

  async saveElementQuantity({ dispatch }, payload: ModifyProductPayload) {
    const { product, what, productListType } = payload
    if (!('quantity' in what)) {
      return
    }
    const dataToSend = {
      value: what.quantity,
      cancelToken: `Quantity-${product.id}`,
    }

    const targetAction =
      productListType === 'draft'
        ? apiClient.shareDraft.changeDraftElementQuantityUsingPut
        : apiClient.productLists.changeElementQuantityUsingPut

    try {
      await targetAction(product.id, dataToSend)
    } catch (e) {
      errorHandler(e)
      dispatch('fetchProductListElements', product.listId)
    }
  },
  async changeProductListSortingType(
    { commit, dispatch },
    payload: {
      listId: number
      sortingParameter: SortingType
      productListType: ProductListType
    },
  ) {
    commit('updateIsLoading', true)

    try {
      if (payload.productListType === 'draft') {
        await apiClient.shareDraft.updateShareDraftElementsSortParameterUsingPut(
          payload.listId,
          { sortParameter: payload.sortingParameter },
        )
        await dispatch('fetchDraftProductListDetails', payload.listId)
      } else {
        await apiClient.productLists.updateProductListElementsSortParameterUsingPut(
          payload.listId,
          { sortParameter: payload.sortingParameter },
        )
        await dispatch('fetchProductListElements', payload.listId)
      }
      commit('updateListSortingType', payload.sortingParameter)
    } catch (e) {
      errorHandler(e)
    }
    commit('updateIsLoading', false)
  },

  setOrderEditionMode({ state, commit }, payload: boolean) {
    commit('updateIsOrderEditionMode', payload)
    commit('clearMultipleSelectedProducts')
    if (payload) {
      commit('updateSelectedProduct', undefined)
    } else {
      commit('updateSelectedProduct', state.productListElements[0])
    }
  },

  setOrderOfListItems(
    { commit },
    payload: { onListId: number; order: number },
  ) {
    commit('updateOrderEditionListElements', payload)
  },

  setBasicOrder({ commit }) {
    commit('setSortingList')
  },

  async saveCustomOrderForPorductList(
    { commit, dispatch },
    payload: {
      listId: number
      orders: API.SortOrderRequestDto[]
    },
  ) {
    commit('updateIsLoading', true)
    try {
      await apiClient.productLists.updateProductListElementsCustomSortingUsingPut(
        payload.listId,
        payload.orders,
      )
      await dispatch('fetchProductListElements', payload.listId)
      await dispatch('setOrderEditionMode', false)
      commit('updateListSortingType', 'CUSTOM')
      showToastAfterSavingCustomOrder()
    } catch (e) {
      errorHandler(e)
    }
    commit('updateIsLoading', false)
  },

  async saveCustomOrderForDraft(
    { commit, dispatch },
    payload: {
      listId: number
      orders: API.SortOrderRequestDto[]
    },
  ) {
    commit('updateIsLoading', true)
    try {
      await apiClient.shareDraft.updateShareDraftElementsCustomSortUsingPut(
        payload.listId,
        payload.orders,
      )
      await dispatch('fetchDraftProductListDetails', payload.listId)
      await dispatch('setOrderEditionMode', false)
      commit('updateListSortingType', 'CUSTOM')
      showToastAfterSavingCustomOrder()
    } catch (e) {
      errorHandler(e)
    }
    commit('updateIsLoading', false)
  },
}
