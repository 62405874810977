import { API } from '@/api'
import * as Getters from '@/helpers/getters'
import { ROUTER_CONFIG } from '@/routes/router.config'
import {
  apiClient,
  countrySharingErrorHandler,
  draftErrorHandler,
  errorHandler,
  productListErrorHander,
} from '@/services/data.service'
import { getDynamicRouter } from '@/services/router.service'
import { AppState } from '@/store/types'
import { ActionTree } from 'vuex'
import {
  CopyShareProductListPayload,
  EditionModeType,
  ProductListsState,
  SortingType,
} from './types'

export const actions: ActionTree<ProductListsState, AppState> = {
  async getAllUserProductLists({ dispatch, commit }) {
    commit('updateIsLoadingProductLists', true)
    await Promise.all([
      dispatch('fetchUserProductLists'),
      dispatch('fetchSharedLists'),
      dispatch('fetchPinnedLists'),
      dispatch('fetchDraftLists'),
    ])
    commit('updateIsLoadingProductLists', false)
  },

  async fetchUserProductLists({ commit, dispatch }) {
    try {
      const { data } = await apiClient.productLists.getProductListsUsingGet()
      commit('updateProductLists', data)
      dispatch('manageProductListCounterTimestamp', undefined, {
        root: true,
      })
    } catch (e) {
      errorHandler(e)
    }
  },

  async fetchSharedLists({ commit, rootState }) {
    if (Getters.isSalesChannelPartner(rootState)) {
      commit('updateSharedProductLists', undefined)

      return
    }
    try {
      const { data } = await apiClient.countrySharing.getCountrySharesUsingGet()
      commit('updateSharedProductLists', data)
    } catch (e) {
      errorHandler(e)
    }
  },
  async fetchPinnedLists({ commit }) {
    try {
      const { data } = await apiClient.pinnedLists.getPinnedListsUsingGet()
      commit('updatePinnedProductLists', data)
    } catch (e) {
      errorHandler(e)
    }
  },

  async fetchDraftLists({ commit, rootState }) {
    if (!Getters.isKeyUserOrAdmin(rootState)) {
      commit('updateDraftProductLists', undefined)

      return
    }
    try {
      const { data } = await apiClient.shareDraft.getShareDraftsUsingGet()
      commit('updateDraftProductLists', data)
    } catch (e) {
      errorHandler(e)
    }
  },

  async saveCreatedProductList(
    { dispatch },
    payload: API.ProductListRowResponseDto,
  ) {
    try {
      await apiClient.productLists.createListUsingPost(payload)
      dispatch('getAllUserProductLists')
    } catch (e) {
      productListErrorHander(e)
    }
  },

  async saveProductListAsMine(
    { dispatch },
    payload: CopyShareProductListPayload,
  ) {
    try {
      await apiClient.countrySharing.copyUsingPost(payload.listId, payload.body)
    } catch (e) {
      productListErrorHander(e)
    }
    dispatch('getAllUserProductLists')
  },

  async startDraft(_ctx, payload: number) {
    try {
      const { data } = await apiClient.shareDraft.startDraftUsingPost(payload)
      ;(await getDynamicRouter()).push({
        name: ROUTER_CONFIG.draftProductListDetails.name,
        params: {
          productListId: data.id,
        },
      })
    } catch (e) {
      draftErrorHandler(e)
    }
  },

  async setListSortingType({ commit }, payload: SortingType) {
    commit('updateListSortingType', payload)
  },

  async pinPrivateList({ dispatch }, payload: number) {
    try {
      await apiClient.productLists.pinProductListUsingPut(payload)
    } finally {
      dispatch('getAllUserProductLists')
    }
  },

  async unpinPrivateList({ dispatch }, payload: number) {
    try {
      await apiClient.productLists.unpinProductListUsingPut(payload)
    } finally {
      dispatch('getAllUserProductLists')
    }
  },

  async pinSharedList({ dispatch }, payload: number) {
    try {
      await apiClient.countrySharing.pinCountryProductListUsingPut(payload)
    } finally {
      dispatch('getAllUserProductLists')
    }
  },

  async unpinSharedList({ dispatch }, payload: number) {
    try {
      await apiClient.countrySharing.unpinCountryProductListUsingPut(payload)
    } finally {
      dispatch('getAllUserProductLists')
    }
  },

  async setEditionMode({ commit }, payload: boolean) {
    commit('updateisEditMode', payload)
    if (payload) {
      ;(await getDynamicRouter()).push({
        name: ROUTER_CONFIG.productListEdit.name,
      })
    }
  },

  async deleteProductLists({ dispatch }, payload: { ids: number[] }) {
    try {
      await apiClient.productLists.deleteListsUsingDelete(payload)
    } catch (e) {
      errorHandler(e)
    }
    dispatch('getAllUserProductLists')
  },

  async deleteDraftList({ dispatch }, payload: number) {
    try {
      await apiClient.shareDraft.removeShareDraftUsingDelete(payload)
    } catch (e) {
      errorHandler(e)
    }
    dispatch('getAllUserProductLists')
  },

  async deleteSharedList({ dispatch }, payload: number) {
    try {
      await apiClient.countrySharing.removeCountryShareUsingDelete(payload)
    } catch (e) {
      countrySharingErrorHandler(e)
    }
    dispatch('getAllUserProductLists')
  },

  async setEditionType({ commit }, payload: EditionModeType) {
    commit('updateEditionMode', payload)
  },

  async saveEditedProductList(
    { dispatch },
    payload: { listId: number; productList: API.ProductListRowResponseDto },
  ) {
    try {
      await apiClient.productLists.editListUsingPut(
        payload.listId,
        payload.productList,
      )
    } catch (e) {
      productListErrorHander(e)
    }
    dispatch('getAllUserProductLists')
  },

  async saveEditedDraftProductList(
    { dispatch },
    payload: { listId: number; productList: API.TitleAndDescriptionDto },
  ) {
    try {
      await apiClient.shareDraft.editDraftUsingPut(
        payload.listId,
        payload.productList,
      )
    } catch (e) {
      productListErrorHander(e)
    }
    dispatch('getAllUserProductLists')
  },
}
