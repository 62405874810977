/* eslint-disable no-console */
import { INBOX_NOTIFICATIONS_QUERY } from '@/routes/notifications/notifications.service'
import { logEvent } from '@/services/analytics.service'
import { apiClient, errorHandler } from '@/services/data.service'
import {
  getFcmToken,
  onBackgroundMessage,
  onForegroundMessage,
} from '@/services/firebase.service'
import { AppState } from '@/store/types'
import { AnalyticEvent } from '@/types/analyticEvents'
import { MessagePayload } from 'firebase/messaging'
import { ActionTree } from 'vuex'
import { NotificationsState } from './types'

export const actions: ActionTree<NotificationsState, AppState> = {
  /**
   * Initialization of push notifications
   * Calling this function would trigger asking for user permission for showing notifications
   */
  async initNotifications({ dispatch, commit }) {
    const fcmToken = await getFcmToken()
    commit('updateArePushNotificationsAllowedByUser', Boolean(fcmToken))
    if (!fcmToken) {
      console.error('Firebase Cloud Messaging problem: ', { fcmToken })

      return
    }
    console.log('Firebase Cloud Messaging initialized: fcmToken: ')
    console.log(fcmToken) // TODO: remove it before production

    try {
      // Register fcmToken on Backend - this way backend can connect user with notifications
      await apiClient.user.setFcmRegistrationIdUsingPost({
        fcmRegistrationId: fcmToken,
      })
    } catch (e) {
      errorHandler(e)

      return
    }

    onBackgroundMessage(
      (msg) => {
        dispatch('onBackgroundMessageAction', msg)
      },
      (msg) => {
        dispatch('onBackgroundMessageClickedAction', msg)
      },
    )
    onForegroundMessage((msg) => {
      dispatch('onForegroundMessage', msg)
    })
  },

  async fetchUnreadCounter({ commit }) {
    try {
      const { data } = await apiClient.notifications.unreadCountUsingGet()
      commit('updateUnreadCounter', data.value)
    } catch (e) {
      errorHandler(e)
    }
  },

  async fetchInboxNotifications({ dispatch, commit }) {
    try {
      const { data } = await apiClient.notifications.notificationsPageUsingGet(
        INBOX_NOTIFICATIONS_QUERY,
      )

      commit('setInboxNotifications', data.content)
      await dispatch('triggerNotificationsInbox')
    } catch (e) {
      errorHandler(e)
    }
  },

  /**
   * This action is triggered when a user receives a push notification
   * and web app is opened in the background
   */
  async onBackgroundMessageAction({ dispatch }, payload: MessagePayload) {
    dispatch('fetchUnreadCounter')
    dispatch('fetchInboxNotifications')
    console.log('onBackgroundMessageAction', payload)
  },

  /**
   * This action is triggered when a user clicked
   * on system push notification
   */
  async onBackgroundMessageClickedAction(
    { dispatch },
    payload: MessagePayload,
  ) {
    dispatch('fetchUnreadCounter')
    console.log('onBackgroundMessageClickedAction', payload)
  },

  /**
   * This action is triggered when a user receives a push notification
   * and web app is opened in the foreground.  In this case, none system notification is displayed
   */
  async onForegroundMessage({ dispatch }, payload: MessagePayload) {
    dispatch('fetchUnreadCounter')
    dispatch('fetchInboxNotifications')
    dispatch('triggerNotificationsBell')
    console.log('onForegroundMessage', payload)
  },

  async updateNotificationStatus({ commit, dispatch }, notificationId: number) {
    try {
      await apiClient.notifications.readNotificationUsingPut(notificationId)
      commit('readInboxNotification', notificationId)
      await dispatch('fetchUnreadCounter')
    } catch (e) {
      errorHandler(e)
    }
  },

  async markAllAsRead(_) {
    try {
      await apiClient.notifications.markAllNotificationsAsReadUsingPut()

      logEvent(AnalyticEvent.MARK_ALL_NOTIFICATIONS_AS_READ)
    } catch (e) {
      errorHandler(e)
    }
  },

  async triggerNotificationsBell({ commit }) {
    commit('setNotificationsBell', true)
    setTimeout(() => commit('setNotificationsBell', false), 1000)
  },

  async triggerNotificationsInbox({ commit }) {
    commit('setInboxNotificationsChange', true)
    setTimeout(() => commit('setInboxNotificationsChange', false), 0)
  },
}
/* eslint-enable no-console */
