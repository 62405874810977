import { API } from '@/api'
import {
  externalUserDisplayFormatter,
  internalUserDisplayFormatter,
} from '@/routes/users/users.service'
import { MutationTree } from 'vuex'
import {
  ExternalUsersFiltersModel,
  InternalUsersFiltersModel,
} from '../UsersList/useFilters'
import { UsersState } from './types'

export const mutations: MutationTree<UsersState> = {
  updateUsers(state, payload: API.UserListRowDto[]) {
    state.internalUsersArray = payload.map((user) =>
      internalUserDisplayFormatter(user),
    )
  },

  updateExternalUsers(state, payload: API.ExternalUserDto[]) {
    state.externalUsersArray = payload.map((user) =>
      externalUserDisplayFormatter(user),
    )
  },

  updateIsInternalLoading(state, payload: boolean) {
    state.isInternalLoading = payload
  },

  updateIsExternalLoading(state, payload: boolean) {
    state.isExternalLoading = payload
  },

  updateUser(state, payload: API.UserEditInfoResponseDto) {
    state.userForEdit = payload
  },

  updateUserCountryRole(state, payload: API.UserRoleResponseDto) {
    if (!state.userForEdit) return
    const countryRoles = state.userForEdit?.roles || []
    if (!payload.countryId) {
      state.userForEdit = {
        ...state.userForEdit,
        roles: undefined,
      }

      return
    }

    const countryRoleIndex = countryRoles.findIndex(
      (item) => item.countryId === payload.countryId,
    )

    countryRoleIndex >= 0
      ? (state.userForEdit.roles = Object.assign([...countryRoles], {
          [countryRoleIndex]: payload,
        }))
      : (state.userForEdit = {
          ...state.userForEdit,
          roles: [payload, ...countryRoles],
        })
  },

  setInternalFilters(state, model: InternalUsersFiltersModel) {
    state.internalFilters = model
  },

  setExternalFilters(state, model: ExternalUsersFiltersModel) {
    state.externalFilters = model
  },
}
