import { API } from '@/api'
import { apiClient, errorHandler } from '@/services/data.service'
import { AppState } from '@/store/types'
import { ActionTree } from 'vuex'
import { StaticDataState } from './types'

export const actions: ActionTree<StaticDataState, AppState> = {
  async fetchStaticData({ commit, dispatch }) {
    commit('updateIsLoading', true)
    await Promise.all([
      dispatch('fetchCurrenciesList'),
      dispatch('fetchCountriesList'),
      dispatch('fetchScpCountriesList'),
      dispatch('fetchSubregionsList'),
      dispatch('fetchOrganizationRolesList'),
      dispatch('fetchDynamicFiltersList'),
      dispatch('fetchAppInfo'),
      dispatch('fetchLanguagesList'),
    ])
    commit('updateIsLoading', false)
  },

  async fetchScpStaticData({ commit, dispatch }) {
    commit('updateIsLoading', true)
    await Promise.all([
      dispatch('fetchCountriesList'),
      dispatch('fetchScpCountriesList'),
      dispatch('fetchSubregionsList'),
      dispatch('fetchOrganizationRolesList'),
      dispatch('fetchDynamicFiltersList'),
      dispatch('fetchAppInfo'),
      dispatch('fetchLanguagesList'),
    ])
    commit('updateIsLoading', false)
  },

  async fetchCurrenciesList({ commit, state }) {
    if (state.currencyExchangeList.length) {
      return // Load static data only once
    }
    try {
      const currenciesList: API.CurrencyExchangeDto[] =
        (await apiClient.currencies.getCurrencyExchangesListUsingGet()).data
          .items ?? []
      commit('updateCurrencyExchangesList', currenciesList)
    } catch (e) {
      errorHandler(e)
    }
  },

  async fetchCountriesList({ commit }) {
    try {
      const countriesList: API.CountryDto[] =
        (await apiClient.countries.getCountriesListUsingGet()).data.items ?? []
      commit('updateCountriesList', countriesList)
    } catch (e) {
      errorHandler(e)
    }
  },

  async fetchScpCountriesList({ commit, state }) {
    if (state.countriesList.length) {
      return // Load static data only once
    }
    try {
      const scpCountriesList: API.CountryDto[] =
        (await apiClient.countries.getCountriesListUsingGet()).data.items ?? []
      commit('updateCountriesList', scpCountriesList)
    } catch (e) {
      errorHandler(e)
    }
  },

  async fetchSubregionsList({ commit }) {
    try {
      const subregionsList = (await apiClient.countries.getSubregionsUsingGet())
        .data.content
      commit('updateSubregionsList', subregionsList)
    } catch (e) {
      errorHandler(e)
    }
  },

  async fetchOrganizationRolesList({ commit, state }) {
    if (state.organizationRolesList.length) {
      return // Load static data only once
    }
    try {
      const countriesList: API.OrganizationRoleListResponseDto['organizationRoles'] =
        (await apiClient.user.getAllOrganizationRolesUsingGet()).data
          .organizationRoles
      commit('updateOrganizationRolesList', countriesList)
    } catch (e) {
      errorHandler(e)
    }
  },

  async fetchDynamicFiltersList({ commit, state }) {
    if (state.dynamicFilters.length) {
      return // Load static data only once
    }
    try {
      const dynamicFiltersList: API.DynamicFilterDto[] = (
        await apiClient.products.getDynamicFiltersUsingGet()
      ).data.content
      commit('updateDynamicFiltersList', dynamicFiltersList)
    } catch (e) {
      errorHandler(e)
    }
  },

  async fetchAppInfo({ commit }) {
    try {
      const { data } = await apiClient.appInfo.getAppInfoUsingGet()
      commit('updateAppInfo', data)
    } catch (e) {
      errorHandler(e)
    }
  },
  async fetchLanguagesList({ commit, state }) {
    if (state.languagesList.length) {
      return // Load static data only once
    }
    try {
      const { data } = await apiClient.user.getLanguagesUsingGet()
      commit('updateLanguagesList', data)
    } catch (e) {
      errorHandler(e)
    }
  },
}
