import { apiClient } from '@/services/data.service'
import { AppState, FavoriteProduct } from '@/store/types'
import { ActionTree } from 'vuex'
import { SearchResultsState } from './types'

export const actions: ActionTree<SearchResultsState, AppState> = {
  async setProductFavorite(_, payload: FavoriteProduct) {
    const { id, isFavorite } = payload

    this.commit('searchResults/updateIsFavoriteDisabled', true)
    this.commit('product/updateIsFavoriteDisabled', true)

    try {
      if (isFavorite) {
        await apiClient.favoriteProducts.addToFavoritesUsingPost(id)
      } else {
        await apiClient.favoriteProducts.deleteFromFavoritesUsingDelete(id)
      }

      this.commit('searchResults/updateProductFavorite', payload)
      this.commit('product/updateProductFavorite', payload)
    } finally {
      this.commit('searchResults/updateIsFavoriteDisabled', false)
      this.commit('product/updateIsFavoriteDisabled', false)
    }
  },
}
