import { API } from '@/api'
import { ProductStatusData } from '@/domain/product'
import {
  AbnormalQuantity,
  Available,
  NotAvailablePhaseOut,
  Blocked,
  ContactDraeger,
  CustomStatus,
  LimitedQuantity,
  MarketClearance,
  OutOfStock,
  PhasedOut,
  STATUS_ORDER,
  ScpPhasedOut,
  ScpUpcomingPhaseOut,
  ShipHold,
  SingleProductStatus,
  UpcomingPhaseOut,
} from './status.config'

export interface UserPreferences {
  marketClearance: boolean
  availability: boolean
  phaseOut: boolean
}

export const phaseOutAvailableNumbers = [1, 2, 3]

type UserProfile = {
  clearanceCheck?: boolean
  showAvailability?: boolean
  phaseOutCheck?: boolean
  role?: API.DisplayUserProfileResponseDto['role']
}

export const getProductStatuses = (
  product: ProductStatusData,
  user: UserProfile = {},
): SingleProductStatus[] => {
  const { clearanceCheck, showAvailability, phaseOutCheck } = user
  const statuses: SingleProductStatus[] = []
  let custom: CustomStatus | undefined

  if ((phaseOutCheck || showAvailability) && product.customStatus) {
    statuses.push((custom = CustomStatus(product.customStatus)))
  }

  const isScp = user.role === 'SALES_CHANNEL_PARTNER'

  if (phaseOutCheck && !custom) {
    // TMP statuses
    if (product.phaseOut === 2) {
      statuses.push(isScp ? ScpUpcomingPhaseOut : UpcomingPhaseOut)
    } else if (product.phaseOut === 3) {
      statuses.push(isScp ? ScpPhasedOut : PhasedOut)
    } else if (product.phaseOut === 4) {
      statuses.push(Blocked)
    } else if (product.phaseOut === 5) {
      statuses.push(NotAvailablePhaseOut)
    } else {
      statuses.push(Available)
    }
  }

  if (clearanceCheck) {
    if (product.marketClearance === 1) {
      statuses.push(MarketClearance)
    } else {
      statuses.push(Available)
    }
  }

  if (showAvailability) {
    if (!custom) {
      // MARA statuses
      if (product.availability === 1) {
        statuses.push(ShipHold)
      } else if (product.availability === 2) {
        statuses.push(OutOfStock)
      } else if (product.availability === 3) {
        statuses.push(LimitedQuantity)
      } else {
        statuses.push(Available)
      }
    }

    const { quantity, criticalQuantity: critical } = product
    if (quantity == null || critical == null || quantity <= critical) {
      statuses.push(Available)
    } else {
      statuses.push(AbnormalQuantity)
    }
  }

  if (!statuses.length) return []

  if (statuses.every(isOk)) {
    if (custom && isScp) {
      return [ContactDraeger]
    }

    return [custom || Available]
  }

  if (isScp) {
    if (statuses.some((s) => s.id === 'SCP_PHASED_OUT')) {
      return [ScpPhasedOut]
    }

    if (statuses.some((s) => s.id === 'SCP_UPCOMING_PHASE_OUT')) {
      return [ScpUpcomingPhaseOut]
    }

    return [ContactDraeger]
  }

  return statuses
    .filter((s) => s.id !== 'AVAILABLE')
    .sort((a, b) => {
      const aIdx = STATUS_ORDER.indexOf(a.id)
      const bIdx = STATUS_ORDER.indexOf(b.id)

      return aIdx - bIdx
    })
}

function isOk(status: SingleProductStatus) {
  return status.severity === 'AVAILABLE'
}

export function isScpPhasedOut(statuses: SingleProductStatus[]) {
  const hasCustomStatus = statuses.some((s) => s.id === 'CustomStatus')

  if (hasCustomStatus) {
    return false
  }

  return statuses.some((s) => s.id === 'SCP_PHASED_OUT')
}

export function isAnyFormOfPhasedOut(statuses: SingleProductStatus[]) {
  const hasCustomStatus = statuses.some((s) => s.id === 'CustomStatus')

  if (hasCustomStatus) {
    return false
  }

  return statuses.some((s) => ['SCP_PHASED_OUT', 'PHASED_OUT'].includes(s.id))
}
