import { API } from '@/api'
import {
  adminRole,
  allRoles,
  allRolesExcept,
  keyUserRole,
  scpRole,
} from '@/helpers/roles.utils'
import {
  acceptedScpStatus,
  onboardingRequiredScpStatus,
  pendingScpStatus,
  rejectedScpStatus,
} from '@/helpers/scpStatuses.utils'
import { ProductListType } from '@/store/types'

export interface RouterConfig {
  path: string
  name: string
  meta?: {
    title: string
    productListType?: ProductListType
    isVisibleOnlyInHamburgerMenu?: boolean
    fromProductListDetails?: boolean
  }
  allowedRoles?: API.DisplayUserProfileResponseDto['role'][] // if undefined: all users and not logged users can enter the route
  allowedScpStatuses?: API.DisplayUserProfileResponseDto['scpStatus'][]
}

export interface NavigationConfigItem {
  main: RouterConfig
  children: RouterConfig[]
}

const finder: RouterConfig = {
  path: '/',
  name: 'finder',
  allowedRoles: allRoles,
  allowedScpStatuses: [acceptedScpStatus],
  meta: {
    title: 'Finder',
  },
}

const searchResults: RouterConfig = {
  path: '/search',
  name: 'searchResults',
  allowedRoles: allRoles,
  allowedScpStatuses: [acceptedScpStatus],
  meta: {
    title: 'Search Results',
  },
}
const onboarding: RouterConfig = {
  path: '/onboarding',
  name: 'onboarding',
  allowedRoles: allRolesExcept([scpRole]),
  meta: {
    title: 'Onboarding',
  },
}
const scpRegistration: RouterConfig = {
  path: '/scp-registration',
  name: 'scpRegistration',
  allowedRoles: [scpRole],
  allowedScpStatuses: [pendingScpStatus],
}
const scpRegistrationWaitingRoom: RouterConfig = {
  path: '/scp-registration/waiting-room',
  name: 'scpRegistrationWaitingRoom',
  allowedRoles: [scpRole],
  allowedScpStatuses: [pendingScpStatus],
}
const scpRegistrationRejected: RouterConfig = {
  path: '/scp-registration/rejected',
  name: 'scpRegistrationRejected',
  allowedRoles: [scpRole],
  allowedScpStatuses: [rejectedScpStatus],
}
const scpOnboarding: RouterConfig = {
  path: '/scp-onboarding',
  name: 'scpOnboarding',
  allowedRoles: [scpRole],
  allowedScpStatuses: [onboardingRequiredScpStatus],
  meta: {
    title: 'Onboarding',
  },
}
const token: RouterConfig = {
  path: '/code=:code',
  name: 'token',
  allowedRoles: undefined,
  meta: {
    title: 'Login',
  },
}
const productList: RouterConfig = {
  path: '/product-lists',
  name: 'productLists',
  allowedRoles: allRoles,
  allowedScpStatuses: [acceptedScpStatus],
  meta: {
    title: 'Product Lists',
  },
}
const productListEdit: RouterConfig = {
  path: '/product-lists/edit',
  name: 'productListsEdit',
  allowedRoles: allRoles,
  allowedScpStatuses: [acceptedScpStatus],
  meta: {
    title: 'Edit Product List',
  },
}
const productListTabs: RouterConfig = {
  path: '/product-lists/:tabName',
  name: 'productListsTabs',
  allowedRoles: allRoles,
  allowedScpStatuses: [acceptedScpStatus],
  meta: {
    title: 'Product Lists',
  },
}
const productListDetails: RouterConfig = {
  path: '/product-list/:productListId',
  name: 'productListDetails',
  allowedRoles: allRoles,
  allowedScpStatuses: [acceptedScpStatus],
  meta: {
    title: 'Product List Details',
  },
}
const privateProductListDetails: RouterConfig = {
  path: '/product-list/private/:productListId',
  name: 'privateProductListDetails',
  allowedRoles: allRoles,
  allowedScpStatuses: [acceptedScpStatus],
  meta: {
    title: 'Private Product List Details',
    productListType: 'private',
  },
}
const countryProductListDetails: RouterConfig = {
  path: '/product-list/country/:productListId',
  name: 'countryProductListDetails',
  allowedRoles: allRoles,
  allowedScpStatuses: [acceptedScpStatus],
  meta: {
    title: 'Country Product List Details',
    productListType: 'country',
  },
}

const draftProductListDetails: RouterConfig = {
  path: '/product-list/draft/:productListId',
  name: 'draftProductListDetails',
  allowedRoles: allRoles,
  allowedScpStatuses: [acceptedScpStatus],
  meta: {
    title: 'Draft Product List Details',
    productListType: 'draft',
  },
}

const productDetailsByTitle: RouterConfig = {
  path: '/p/:productTitle',
  name: 'productDetailsByTitle',
  allowedRoles: allRoles,
  allowedScpStatuses: [acceptedScpStatus],
  meta: {
    title: 'Product Details',
  },
}

const favorites: RouterConfig = {
  path: '/favorites',
  name: 'favorites',
  allowedRoles: allRoles,
  allowedScpStatuses: [acceptedScpStatus],
  meta: {
    title: 'Favorites',
  },
}
const profile: RouterConfig = {
  path: '/profile',
  name: 'profile',
  allowedRoles: allRoles,
  allowedScpStatuses: [acceptedScpStatus],
  meta: {
    title: 'Profile',
  },
}
const users: RouterConfig = {
  path: '/users',
  name: 'users',
  allowedRoles: [keyUserRole, adminRole],
  meta: {
    title: 'Users',
  },
}
const editUser: RouterConfig = {
  path: '/users/edit-user/:userId',
  name: 'editUser',
  allowedRoles: [keyUserRole, adminRole],
  meta: {
    title: 'Edit User',
  },
}
const editScpUser: RouterConfig = {
  path: '/users/edit-scp-user/:userId',
  name: 'editScpUser',
  allowedRoles: [keyUserRole, adminRole],
  meta: {
    title: 'Edit User',
  },
}
const system: RouterConfig = {
  path: '/system',
  name: 'system',
  allowedRoles: [adminRole],
  meta: {
    title: 'System',
  },
}
const systemTabs: RouterConfig = {
  path: '/system/:tabName',
  name: 'systemTabs',
  allowedRoles: [adminRole],
  meta: {
    title: 'System',
  },
}
const manageAvailability: RouterConfig = {
  path: '/availability/:productTitle',
  name: 'manageAvailability',
  allowedRoles: [adminRole],
  meta: {
    title: 'Manage availability',
  },
}
const notifications: RouterConfig = {
  path: '/notifications',
  name: 'notifications',
  allowedRoles: allRoles,
  allowedScpStatuses: [acceptedScpStatus],
  meta: {
    title: 'Notifications',
    isVisibleOnlyInHamburgerMenu: true,
  },
}
const errors: RouterConfig = {
  path: '/errors',
  name: 'error',
  allowedRoles: undefined,
}

const loginError: RouterConfig = {
  path: '/loginError',
  name: 'loginError',
  allowedRoles: undefined,
}

export const ROUTER_CONFIG = {
  finder,
  searchResults,
  onboarding,
  scpRegistration,
  scpRegistrationWaitingRoom,
  scpRegistrationRejected,
  scpOnboarding,
  token,
  productList,
  productListEdit,
  productListTabs,
  productListDetails,
  privateProductListDetails,
  countryProductListDetails,
  draftProductListDetails,
  productDetailsByTitle,
  favorites,
  profile,
  users,
  editUser,
  editScpUser,
  system,
  systemTabs,
  manageAvailability,
  errors,
  notifications,
  loginError,
}

export const NAVIGATION_CONFIG: NavigationConfigItem[] = [
  { main: finder, children: [searchResults] },
  {
    main: productList,
    children: [
      productListEdit,
      productListDetails,
      productListTabs,
      privateProductListDetails,
      countryProductListDetails,
      draftProductListDetails,
    ],
  },
  { main: favorites, children: [] },
  { main: profile, children: [] },
  { main: users, children: [] },
  { main: system, children: [] },
  { main: notifications, children: [] },
]

export const QUERY_PARAM_FROM_BREADCRUMBS = 'fromBreadcrumbs=true'
