import 'primeicons/primeicons.css'
import BadgeDirective from 'primevue/badgedirective'
import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice'
import 'primevue/resources/primevue.min.css'
import 'primevue/resources/themes/luna-blue/theme.css'
import ToastService from 'primevue/toastservice'
import Tooltip from 'primevue/tooltip'
import { createApp } from 'vue'

import App from '@/App.vue'
import { IS_DEV } from '@/flags'
import { i18n } from '@/i18n'
import router from '@/routes'
import { initFirebase } from '@/services/firebase.service'
import store from '@/store'
import { NavigationConfirmation } from './components/AppConfirmNavigation'
import { BlockScroll } from './helpers/useBlockScroll'
import { canEnterRoute } from './routes/router.guards'
import { useAnalyticsContext } from './services/analytics.service'

try {
  initFirebase()
} catch (e) {
  // eslint-disable-next-line no-console
  console.error('Firebase load error', e)
}

createApp(App)
  .use(ToastService)
  .use(ConfirmationService)
  .use(store)
  .use(i18n)
  .use(router)
  .use(PrimeVue, { ripple: true })
  .use(BlockScroll)
  .use(NavigationConfirmation)
  .directive('tooltip', Tooltip)
  .directive('badge', BadgeDirective)
  .mount('#app')

let previousRouteUrl: string | undefined
function getPageReferrer() {
  if (previousRouteUrl) return previousRouteUrl
  if (history.state.back) return dropSensitiveDataFromUrl(history.state.back)

  return document.referrer
}

router.beforeEach(canEnterRoute)

router.afterEach(async (to, from, failed) => {
  if (!failed && to.path !== from.path) {
    previousRouteUrl = from.fullPath
  }
})

useAnalyticsContext(() => ({
  page_title: router.currentRoute.value.meta.title || 'HCA Assist Web',
  page_location: dropSensitiveDataFromUrl(location.hash),
  page_referrer: getPageReferrer(),
  page_path: dropSensitiveDataFromUrl(location.pathname),
}))

/* eslint-disable no-console */
const buildDate = new Date(process.env.VUE_APP_BUILD_DATE!)
console.log('Version:', process.env.VUE_APP_VERSION)
console.log('Build date:', buildDate.toLocaleString(navigator.language))
/* eslint-enable no-console */

if (IS_DEV) {
  Object.assign(window, {
    $store: store,
  })
}

/**
 * If URL contains login information - `code` and `id_token` send to Analytics
 * only string '/code', otherwise send whole URL
 */
function dropSensitiveDataFromUrl(url: string) {
  return url && url.includes('code') && url.includes('id_token') ? '/code' : url
}
