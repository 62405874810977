import { API } from '@/api'
import { apiClient, errorHandler } from '@/services/data.service'
import store from '@/store'

export const NOTIFICATIONS_QUERY = { sort: 'desc' }
export const INBOX_NOTIFICATIONS_QUERY = { page: 0, size: 10, sort: 'desc' }

export const fetchNotifications = async (payload: {
  page?: number
  size?: number
  sort?: string
}) => {
  try {
    const { data } = await apiClient.notifications.notificationsPageUsingGet(
      payload,
    )

    return data
  } catch (e) {
    errorHandler(e)
  }

  return null
}

export const displayDivider = (
  current: API.NotificationDto,
  next?: API.NotificationDto,
) => {
  return current.read && next?.read
}

export const markAndFetchNotifications = async () => {
  await store.dispatch('notifications/markAllAsRead')
  await store.dispatch('notifications/fetchUnreadCounter')
  await store.dispatch('notifications/fetchInboxNotifications')
}
