import { t } from '@/i18n'
import { showToast } from '@/services/toast.service'
import {
  MaybeRefOrGetter,
  Ref,
  WritableComputedOptions,
  computed,
  isRef,
  nextTick,
  onUnmounted,
  ref,
  shallowRef,
  watch,
} from 'vue'

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop: (...args: unknown[]) => void = () => {}

export const getRandomId = () => {
  return '_' + Math.random().toString(36).substr(2, 9)
}

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms))

/**
 * On reactive objects Vue automatically unwraps Refs that are assigned to the
 * object's property and subscribes to the Ref's update so that they are
 * reflected on the object. In other words:
 *
 * ```
 *   const obj = reactive({ msg: 'foo' })
 *   const msg = ref('bar')
 *
 *   obj                // { msg: 'foo' }
 *   msg                // { value: 'bar' }
 *
 *   obj.msg = msg      // assigning whole Ref, not just msg.value but...
 *   obj.msg            // 'foo'
 *
 *   msg.value = 'blah' // when we change the ref value it's written to obj
 *   obj.msg            // 'blah'
 * ```
 *
 * The problem, however, is that TS wouldn't allow us to assign Ref<string>
 * where it expects plain string. This function allows to relax this check.
 *
 * WARNING: make sure that the result of the call is used only in reactive
 * objects. Using it in plain object context is not type safe!
 */
export const acceptRefForReactive = <R>(arg: Ref<R> | R): R => {
  return arg as unknown as R
}

/**
 * Checks if `value` is a member of an array or set. TS devs decided that
 * they're not going to permit any value as an argument to Array.includes or
 * Set.has to catch more bugs like `["foo"].includes(0)`. But there are cases
 * where this is valid. This function will permit that.

 * https://github.com/microsoft/TypeScript/issues/26255
 *
 * You can also pass in an optional comparator function but please note that
 * doing this disables type narrowing.
 */
export function has<T>(
  container: readonly T[] | ReadonlySet<T>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
): value is T
export function has<T, U>(
  container: readonly U[] | ReadonlySet<U>,
  value: T,
  eq: (value: T, other: U) => unknown,
): boolean
export function has(
  container: readonly unknown[] | ReadonlySet<unknown>,
  value: unknown,
  eq?: (value: unknown, other: unknown) => unknown,
) {
  if (eq) {
    for (const it of container) {
      if (eq(value, it)) return true
    }

    return false
  }

  return Array.isArray(container)
    ? container.includes(value)
    : (container as Set<unknown>).has(value)
}

// There's also NaN but TS can't treat it as literal type :(
// https://github.com/microsoft/TypeScript/issues/15135
//
// There's also HTMLAllCollection but no sane person would ever use that
export type Falsy = 0 | 0n | '' | false | null | undefined

export const truthy: <T>(obj: T | Falsy) => obj is Exclude<T, Falsy> =
  Boolean as never

export const notNil = <T extends NonNullable<unknown>>(
  obj: T | null | undefined,
): obj is T => obj != null

/**
 * This function should return a version of nextTick that will only call the
 * callback when component is still mounted. This is needed in cases where the
 * callback function is the same one that schedules waiting for render because
 * without checking if the component is still mounted we may end up in infinite
 * loop
 */
export const getWaitForRender = () => {
  let willRender = true
  onUnmounted(() => {
    willRender = false
  })

  const wrapCallback = (cb: () => void) => () => {
    if (willRender) cb()
  }

  return (cb: () => void) => {
    return willRender && nextTick(wrapCallback(cb))
  }
}

export const getTimestampDate = (timestamp: number) => {
  const date = new Date(timestamp)
  const pad2 = (val: number) => `${val}`.padStart(2, '0')
  const time = [
    pad2(date.getDate()),
    pad2(date.getMonth() + 1),
    date.getFullYear(),
  ].join('.')

  return time
}

/**
 * Function factory that returns a debounced version of fn that is awaitable
 */
export const debounce = <This, Args extends unknown[], R>(
  fn: (this: This, ...args: Args) => Awaited<R>,
  time: number,
) => {
  let timeoutId: ReturnType<typeof setTimeout>

  const cancel = () => {
    clearTimeout(timeoutId)
  }

  function debounced(this: This, ...args: Args): Promise<R> {
    cancel()

    return new Promise<R>((resolve, reject) => {
      timeoutId = setTimeout(() => {
        try {
          resolve(fn.apply(this, args))
        } catch (err) {
          reject(err)
        }
      }, time)
    })
  }

  return Object.assign(debounced, {
    cancel,
  })
}

export const deferred = <T>() => {
  let resolve: (val: T | Promise<T>) => void
  let reject: (err: Error) => void
  const promise = new Promise<T>((res, rej) => {
    resolve = res
    reject = rej
  })

  return [promise, resolve!, reject!] as const
}

type MapFn<T, R> = (value: T[keyof T], key: keyof T, self: T) => R
export function mapValues<T extends Record<string, unknown>, R>(
  obj: T,
  mapFn: MapFn<T, R>,
): { [K in keyof T]: R } {
  const entries = Object.entries(obj).map(([k, v]) => {
    return [k, mapFn(v as never, k, obj)]
  })

  return Object.fromEntries(entries)
}

export const getInitials = (text: string | undefined | null) => {
  if (!text) return ''

  return text
    .trim()
    .split(/\s+/)
    .filter((word) => !!word)
    .map((word) => word[0].toUpperCase())
    .filter((initial, index, arr) => index === 0 || index === arr.length - 1)
    .join('')
}

/**
 * Creates a reactive value that tells us if there is still some (async) call in
 * progress. It's meant to replace naive loading tracking which doesn't consider
 * multiple calls happening at the same time, for example:
 *
 * ```
 * // DON'T USE THIS! HERE BE RACE CONDITIONS!
 * const isLoading = ref(false)
 * const loadIt = async () => {
 *   try {
 *     isLoading.value = true
 *     return await axios.get(...)
 *   } finally {
 *     isLoading.value = false
 *   }
 * }
 * ```
 *
 * If `loadIt` was called multiple times in short burst then there would be a
 * race condition on the `isLoading` value because just after the first request
 * completes it would set `isLoading` to false. We'd like to wait for all loads
 * to complete. To fix this we can use `createLoadingTracker` like this:
 *
 * ```
 * const isLoading = createLoadingTracker()
 * const loadIt = isLoading.track(async () => {
 *   // Look ma, no try..catch!
 *   return await axios.get(...)
 * })
 * ```
 *
 * We can even track multiple functions:
 *
 * ```
 * const isLoadingOrSending = createLoadingTracker()
 * const loadIt = isLoadingOrSending.track(async () => {
 *   return await axios.get(...)
 * })
 *
 * const sendIt = isLoadingOrSending.track(async () => {
 *   return await axios.post(...)
 * })
 * ```
 */
export const createLoadingTracker = () => {
  let count = 0

  const isLoading = Object.assign(ref(false), {
    track<P extends unknown[], R>(doWork: (...args: P) => R | Promise<R>) {
      return async function tracked(...args: P): Promise<R> {
        try {
          isLoading.value = ++count > 0

          return await doWork(...args)
        } finally {
          isLoading.value = --count > 0
        }
      }
    },
  })

  return isLoading as Readonly<typeof isLoading>
}

export const rotateArrIndex = (
  curr: number,
  delta: number,
  arrayOrLength: unknown[] | number,
) => {
  const len =
    typeof arrayOrLength === 'number' ? arrayOrLength : arrayOrLength.length

  // double `% len` is needed to make sure that result is in [0; len - 1] range.
  // Without it it might end up < 0 when delta < 0
  return (((curr + delta) % len) + len) % len
}

export type WritableRefLike<T> = Ref<T> | WritableComputedOptions<T>

export function refFromWritable<T>(writable: WritableRefLike<T>): Ref<T> {
  return isRef(writable) ? writable : computed(writable)
}

export type UseDebouncedSyncRefApi<T> = {
  setNow(value: T): void
  flush(): void
  cancel(): void
}

export type UseDebouncedSyncRefResult<T> = [
  inner: Ref<T>,
  api: UseDebouncedSyncRefApi<T>,
  outer: Ref<T>,
]

export function useDebouncedSyncRef<T>(
  debounceMs: number | MaybeRefOrGetter<number>,
  outer: Ref<T>,
): UseDebouncedSyncRefResult<T> {
  const inner = shallowRef(outer.value)
  const result = computed({
    get: () => inner.value,
    set: setAndFlushLater,
  })

  // schedules flushing internal model value to the outer ref to run sometime in
  // the future
  let flushLater: ReturnType<typeof debounce> = null!

  if (typeof debounceMs === 'number') {
    flushLater = debounce(flushNow, debounceMs)
  } else {
    if (typeof debounceMs === 'function') {
      flushLater = debounce(flushNow, debounceMs())
    } else {
      flushLater = debounce(flushNow, debounceMs.value)
    }

    watch(debounceMs, updateFlushLaterFn, { flush: 'sync' })
  }

  // when outer model changes we must cancel flush if it's scheduled and
  // immediately update inner
  watch(outer, (val) => {
    flushLater.cancel()
    inner.value = val
  })

  const api = {
    setNow,
    flush: flushNow,
    cancel: flushLater.cancel,
  }

  return [result, api, outer]

  // flushes internal model value to the outer ref
  function flushNow() {
    flushLater.cancel()

    if (outer.value !== inner.value) {
      outer.value = inner.value
    }
  }

  // sets internal model and immediately flushes it
  function setNow(val: T) {
    inner.value = val
    flushNow()
  }

  // sets internal model and schedules flush to run sometime in the future
  function setAndFlushLater(val: T) {
    inner.value = val
    flushLater()
  }

  function updateFlushLaterFn(debounceMs: number) {
    flushNow()
    flushLater = debounce(flushNow, debounceMs)
  }
}

type Queryable = string | number

type Query = {
  (haystack: Queryable | undefined): boolean
  source: Queryable | undefined
  needle: string | undefined
}

function normalize(val: Queryable | undefined) {
  const valString = typeof val === 'number' ? '' + val : val

  return valString?.toLowerCase().trim()
}

export function Query(source: Queryable | undefined): Query {
  const needle = normalize(source)
  const queryFn = !needle
    ? (_value: Queryable | undefined) => true
    : (value: Queryable | undefined) => {
        const haystack = normalize(value)

        return haystack ? haystack.includes(needle) : false
      }

  return Object.assign(queryFn, {
    needle,
    source,
  })
}

export function shallowClone<T>(orig: T): T {
  return (Array.isArray(orig) ? [...orig] : { ...orig }) as T
}

export const nestedRef = <T, K extends keyof T>(
  parent: Ref<T>,
  key: K | MaybeRefOrGetter<K>,
  clone = shallowClone<T>,
): Ref<T[K]> => {
  let getKey: () => K

  if (typeof key === 'object') {
    getKey = () => key.value
  } else if (typeof key === 'function') {
    getKey = key
  } else {
    getKey = () => key
  }

  return computed({
    get: () => parent.value[getKey()],
    set(val) {
      const orig = parent.value
      const key = getKey()

      if (orig[key] !== val) {
        const copy = clone(orig)
        copy[key] = val
        parent.value = copy
      }
    },
  })
}

export const useIsLoading = () => {
  const isLoading = ref(false)
  let count = 0

  return [isLoading, wrap] as const

  function wrap<T, P extends unknown[], R>(
    fn: (this: T, ...args: P) => R | Promise<R>,
  ) {
    return async function loadingAware(this: T, ...args: P): Promise<R> {
      try {
        isLoading.value = ++count > 0

        return await fn.apply(this, args)
      } finally {
        isLoading.value = --count > 0
      }
    }
  }
}

export function firstOrEmpty<T>(arr: readonly T[]): T[] {
  return arr.length ? [arr[0]] : []
}

export function computedWithPrevious<T>(prev: T, compute: (prev: T) => T) {
  // eslint-disable-next-line no-param-reassign
  return computed(() => (prev = compute(prev)))
}

export const copyToClipboard = async (text: string, toastMessage: string) => {
  try {
    await navigator.clipboard.writeText(text)

    showToast({
      severity: 'success',
      summary: toastMessage,
    })
  } catch (err) {
    showToast({
      severity: 'error',
      summary: t('clipboard.failed'),
    })
  }
}

// (づ｡◕‿‿◕｡)づ https://github.com/tc39/proposal-promise-with-resolvers
export function PromiseWithResolvers<T = void>() {
  let resolve!: (val: T | Promise<T>) => void
  let reject!: (err: unknown) => void

  const promise = new Promise((res, rej) => {
    resolve = res
    reject = rej
  })

  return [promise, resolve, reject] as const
}

export function castToArray<T>(val: T[] | T | null | undefined): T[] {
  if (val == null) return []
  if (Array.isArray(val)) return val

  return [val]
}

export function isArrayEqual<A, B>(
  a: readonly A[],
  b: readonly B[],
  eq: (a: A, b: B) => boolean = Object.is,
) {
  if (Object.is(a, b)) return true

  if (a.length !== b.length) return false

  for (let i = a.length - 1; i >= 0; i--) {
    if (!eq(a[i], b[i])) return false
  }

  return true
}

/**
 * `callFunc(fn, a, b, c)` is like `fn.call(null, a, b, c)`. It's useful when
 * you have an array of functions and want array of results, then you can do
 * `array.map(callFunc)` or when you have some effect that you want to execute
 * immediately or postponed depending on some condition:
 *
 * ```
 * const execute = someCond ? callFunc : setTimeout
 * execute(launchMissiles)
 * ```
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function callFunc<F extends (...args: any[]) => any>(
  fn: F,
  ...args: Parameters<F>
): ReturnType<F> {
  return fn(...args)
}

/**
 * Like `array.some(someCheck)`, but when `someCheck` is asynchronous
 */
export function someAsync<T>(
  values: readonly T[],
  predicate: (value: T) => unknown,
) {
  return new Promise<boolean>((resolve, reject) => {
    const promises = values.map(async (value) => {
      if (await predicate(value)) {
        resolve(true)
      }
    })

    Promise.all(promises).then(() => resolve(false), reject)
  })
}
