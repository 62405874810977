import { API } from '@/api'
import { ScrollState } from '@/components'
import { FavoriteProduct } from '@/store/types'
import { MutationTree } from 'vuex'
import { SearchResultsState } from './types'

export const mutations: MutationTree<SearchResultsState> = {
  updateProductFavorite(state, payload: FavoriteProduct) {
    // FIXME: make ScrollState private
    const scrollState = state?.scrollState as
      | ScrollState<API.ProductRowResponseDto>
      | undefined
    const scrollStatePages = scrollState?.pages

    if (!scrollStatePages) return

    scrollStatePages?.forEach((page) => {
      if (page.status !== 'ok') return

      page.content.forEach((item) => {
        if (item.data?.productId === payload.id) {
          item.data.favorite = payload.isFavorite
        }
      })
    })
  },

  updateIsFavoriteDisabled(state, payload: boolean) {
    state.isFavoriteDisabled = payload
  },

  setScrollState(state, scrollState: unknown) {
    state.scrollState = scrollState
  },
}
