import { API } from '@/api'
import { AppState } from '@/store/types'

export const rejectedScpStatus: API.DisplayUserProfileResponseDto['scpStatus'] =
  'REJECTED'

export const pendingScpStatus: API.DisplayUserProfileResponseDto['scpStatus'] =
  'PENDING'

export const onboardingRequiredScpStatus: API.DisplayUserProfileResponseDto['scpStatus'] =
  'ONBOARDING_REQUIRED'

export const acceptedScpStatus: API.DisplayUserProfileResponseDto['scpStatus'] =
  'ACCEPTED'

export const allScpStatuses: API.DisplayUserProfileResponseDto['scpStatus'][] =
  [
    acceptedScpStatus,
    rejectedScpStatus,
    pendingScpStatus,
    onboardingRequiredScpStatus,
  ]

export const allScpStatusesExcept = (
  statuses: API.DisplayUserProfileResponseDto['scpStatus'][],
): API.DisplayUserProfileResponseDto['scpStatus'][] => {
  return allScpStatuses.filter((status) => !statuses.includes(status))
}

export const checkStatus = (
  state: AppState,
  scpStatuses: API.DisplayUserProfileResponseDto['scpStatus'][],
): boolean => {
  return scpStatuses.includes(state.user!.scpStatus)
}
