import { PersistedState } from './types'

const KEY = 'draeger:persistedState'

export function setPersisted(value: PersistedState) {
  if (value == null) {
    localStorage.removeItem(KEY)
  } else {
    localStorage.setItem(KEY, JSON.stringify(value))
  }
}

export function getPersisted(): PersistedState {
  const serialized = localStorage.getItem(KEY)

  return serialized ? JSON.parse(serialized) : undefined
}
