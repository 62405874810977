<template>
  <div class="triptych">
    <div class="side" />
    <div class="main">
      <slot />
    </div>
    <div class="side" />
  </div>
</template>

<style lang="scss" scoped>
.triptych {
  display: flex;
  flex-direction: row;

  .side {
    flex: 1 0;
    @include breakpoint-lte-md {
      display: none;
    }
  }
  .main {
    flex: 20 20;
    display: flex;
    max-width: $main-content-width;
    flex-direction: column;
    @include breakpoint-lte-md {
      margin: 0 1rem;
    }
  }
}
</style>
