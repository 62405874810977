import { API } from '@/api'
import {
  apiClient,
  errorHandler,
  handleSynchronizeResponse,
  handleUpdateCountriesIsAvailableForScpError,
} from '@/services/data.service'
import { AppState } from '@/store/types'
import { ActionTree } from 'vuex'
import { SystemState } from './types'

export const actions: ActionTree<SystemState, AppState> = {
  async fetchImportStatuses({ commit }) {
    commit('updateAreImportStatusesLoading', true)
    try {
      const { data } = await apiClient.tableImportStatuses.findLatestUsingGet()

      commit('updateImportStatuses', data)
    } catch (e) {
      errorHandler(e)
    }
    commit('updateAreImportStatusesLoading', false)
  },

  async checkSynchronizationStatus({ commit }) {
    try {
      const { data } =
        await apiClient.tableImportStatuses.findSynchronizationStatusUsingGet()

      commit('updateSynchronizationStatus', data)
    } catch (e) {
      errorHandler(e)
    }
  },

  async synchronizeSingle(_, tableName: string) {
    try {
      await apiClient.tableImportStatuses.runTableImporterUsingPost(tableName)
    } catch (e) {
      errorHandler(e)
    }
  },

  async synchronizeAll({ commit }) {
    commit('pendingSynchronize', true)
    try {
      await apiClient.tableImportStatuses.runAllTableImportersUsingPost()
      handleSynchronizeResponse(true)
    } catch (e) {
      errorHandler(e)
      handleSynchronizeResponse(false)
    }
    commit('pendingSynchronize', false)
  },

  async updateCountryIsAvailableForScp(
    { dispatch },
    payload: API.CountryScpAvailableRequestDto[],
  ) {
    try {
      await apiClient.countries.updateIsAvailableForScpUsingPatch(payload)

      await dispatch('staticData/fetchSubregionsList', null, { root: true })
      await dispatch('staticData/fetchCountriesList', null, { root: true })
    } catch (e) {
      await handleUpdateCountriesIsAvailableForScpError(e)

      return e
    }
  },

  async fetchImportsHistory({ commit }, tableName: string) {
    commit('updateIsImportsHistoryLoading', true)
    try {
      const { data } =
        await apiClient.tableImportStatuses.findTableHistoryUsingGet(tableName)

      commit('updateImportsHistory', data)
    } catch (e) {
      errorHandler(e)
    }
    commit('updateIsImportsHistoryLoading', false)
  },
}
